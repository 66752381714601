import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Loader as Spinner } from 'dl-web-components';
import { sortBy } from 'lodash';
import styled from 'styled-components';
import SidebarListItem from 'components/SidebarListItem';
import ContentContainer from 'components/ContentContainer';
import Heading from 'components/Heading';
import Sidebar from 'components/Sidebar';
import FlatList from 'components/FlatList';
import Menu from 'container/Menu';

const StyledContent = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
`;

const ListItem = styled(Link)``;
const Body = styled.div``;
const Label = styled.div``;
const Container = styled.div``;
const List = styled.ul``;
const Title = styled.h2``;
const Header = styled.div``;
const ScrollView = styled.div``;
const Item = styled.div``;

class ProductsPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { showSearch: true, inner: 0, outer: 0 };
    this.renderItem.bind(this);
    this.productsByType.bind(this);
  }

  productsByType(type: String): any {
    if (!this.props.products) {
      return [];
    }
    if (!type) {
      return this.props.products;
    }
    return this.props.products.filter(product => {
      if (product.bearingType && product.bearingType.name === type) {
        return product;
      }
      return false;
    });
  }

  renderItem(item) {
    return (
      <ListItem
        button
        key={item.id}
        style={{ marginLeft: 0 }}
        to={`/products/${item.id}`}
      >
        <Body>
          <p>
            {item.name}
          </p>
          <p note style={{ fontSize: 'smaller' }}>
            {item.bearingType && item.bearingType.name}
          </p>
        </Body>
      </ListItem>
    );
  }

  render() {
    const { productTypes, loading } = this.props;

    let products = sortBy(this.productsByType(this.state.categoryFilter), ['name']);

    const uniqueInnerWidths = ['Any', ...new Set(products.map(item => item.innerDiameter))].sort((a, b) => a - b);
    const uniqueOuterWidths = ['Any', ...new Set(products.map(item => item.outerDiameter))].sort((a, b) => a - b);

    products = products.filter(v =>
      (this.state.inner === 0 || v.innerDiameter === this.state.inner) &&
      (this.state.outer === 0 || v.outerDiameter === this.state.outer));

    return (
      <Container style={{ width: '1400px', margin: 'auto', marginTop: 0, maxWidth: '97%' }}>
        <Menu />
        <Header>
          <Title>Products</Title>
        </Header>
        <StyledContent>
          <Sidebar>
            <ScrollView style={{ height: '100%' }}>
              <List>
                <SidebarListItem itemDivider>
                  <p>Categories</p>
                </SidebarListItem>

                {productTypes.map((item, i) => (
                  <SidebarListItem
                    key={i}
                    onClick={() => {
                      this.setState({ categoryFilter: item.name, outer: 0, inner: 0 });
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                  >
                    <p style={{ cursor: 'pointer', fontWeight: this.state.categoryFilter === item.name ? 'bold' : 'normal' }}>
                      {item.name}
                    </p>
                  </SidebarListItem>
                ))}

                {productTypes.length === 0 &&
                  !loading && (
                    <SidebarListItem>
                      <p>Product types missing</p>
                    </SidebarListItem>
                  )}

                {productTypes.length > 0 && (
                  <div>
                    <SidebarListItem
                      style={{ cursor: 'pointer', marginBottom: 0 }}
                      onClick={() => {
                        this.setState({ categoryFilter: '', outer: 0, inner: 0 });
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                      }}
                    >
                      <p>All products</p>
                    </SidebarListItem>
                  </div>
                )}
              </List>
            </ScrollView>
          </Sidebar>

          <ContentContainer>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <div>
                <Heading>
                  {this.state.categoryFilter ? this.state.categoryFilter : 'All'} products ({products.length})
                </Heading>
              </div>
            </div>

            <div>
              {this.props.searchForm}
              <div>
                <Item inlineLabel>
                  <Label>Inner diameter</Label>
                  <select
                    onChange={event =>
                      this.setState({ inner: event.target.value === 'Any' ? 0 : parseFloat(event.target.value) })
                    }
                  >
                    {uniqueInnerWidths.map(v => (
                      <option key={v} value={v}>
                        {v}
                      </option>
                    ))}
                  </select>
                </Item>
              </div>

              <div>
                <Item inlineLabel>
                  <Label>Outer diameter</Label>
                  <select
                    onChange={event =>
                      this.setState({ outer: event.target.value === 'Any' ? 0 : parseFloat(event.target.value) })
                    }
                  >
                    {uniqueOuterWidths.map(v => (
                      <option key={v} value={v}>
                        {v}
                      </option>
                    ))}
                  </select>
                </Item>
              </div>
            </div>

            {loading ? (
              <div>
                <Spinner style={{ width: '100%', height: 20, alignItems: 'center' }} />
              </div>
            ) : (
                <p note>Product name</p>
              )}

            {products.length > 0 ? (
              <FlatList
                data={products}
                renderItem={item => this.renderItem(item)}
              />
            ) : (
                this.props.searchString !== '' && <Heading>No results</Heading>
              )}
          </ContentContainer>
        </StyledContent>
      </Container>
    );
  }
}

export default ProductsPage;
