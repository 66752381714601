import React from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';

const { REACT_APP_ENV, REACT_APP_TRACKER_ID } = process.env;

class GoogleAnalytics extends React.Component {
  UNSAFE_componentWillUpdate({ location, history }) {
    if (location.pathname === this.props.location.pathname) {
      // don't log identical link clicks (nav links likely)
      return;
    }

    if (REACT_APP_ENV !== 'production') {
      console.log({
        page_location: window.location.href,
        page_path: location.pathname,
      });
      return;
    }

    if (history.action === 'PUSH') {
      ReactGA.pageview(location.pathname);
    }
  }

  UNSAFE_componentWillMount() {
    if (REACT_APP_ENV !== 'production') {
      console.log({
        page_location: window.location.href,
        page_path: this.props.location.pathname,
      });
      return;
    }

    ReactGA.initialize(REACT_APP_TRACKER_ID);

  }

  render() {
    return null;
  }
}

export default withRouter(GoogleAnalytics);
