const kmInMiles = 0.621371192;

// Convert only if user specified unit is in miles.
export const kmToMiles = {
  km: km => km,
  miles: km => km * kmInMiles
};

// Convert only if user specified unit is in miles.
export const milesToKm = {
  km: miles => miles,
  miles: miles => miles / kmInMiles
};

export const speedUnit = {
  km: 'km/h',
  miles: 'mph'
};

export const accuracy = {
  km: -4,
  miles: -4
};

export const roundingLimit = 10000;

export const step = {
  km: 10,
  miles: 10
};
