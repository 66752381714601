import superagent from 'superagent';
import AccessToken from './AccessToken';
import IdToken from './IdToken';
import RefreshToken from './RefreshToken';
import UserSession from './UserSession';
import StorageHelper from './StorageHelper';

export default class User {
  constructor() {
    this.signInUserSession = null;

    this.storage = new StorageHelper().getStorage();

    this.getSession();
  }

  cacheTokens() {
    const keyPrefix = 'skf-sso-identity';
    const idTokenKey = `${keyPrefix}.idToken`;
    const accessTokenKey = `${keyPrefix}.accessToken`;
    const refreshTokenKey = `${keyPrefix}.refreshToken`;
    const clockDriftKey = `${keyPrefix}.clockDrift`;

    this.storage.setItem(idTokenKey, this.signInUserSession.getIdToken().getJwtToken());
    this.storage.setItem(accessTokenKey, this.signInUserSession.getAccessToken().getJwtToken());
    if (typeof this.signInUserSession.getRefreshToken() !== 'undefined') {
      this.storage.setItem(refreshTokenKey, this.signInUserSession.getRefreshToken().getToken());
    }
    this.storage.setItem(clockDriftKey, `${this.signInUserSession.getClockDrift()}`);
  }

  clearCachedTokens() {
    const keyPrefix = 'skf-sso-identity';
    const idTokenKey = `${keyPrefix}.idToken`;
    const accessTokenKey = `${keyPrefix}.accessToken`;
    const refreshTokenKey = `${keyPrefix}.refreshToken`;
    const clockDriftKey = `${keyPrefix}.clockDrift`;

    this.storage.removeItem(idTokenKey);
    this.storage.removeItem(accessTokenKey);
    this.storage.removeItem(refreshTokenKey);
    this.storage.removeItem(clockDriftKey);
  }


  getUserSession(authResult) {
    const idToken = new IdToken(authResult);
    const accessToken = new AccessToken(authResult);
    const refreshToken = new RefreshToken(authResult);

    const sessionData = {
      IdToken: idToken,
      AccessToken: accessToken,
      RefreshToken: refreshToken,
    };

    return new UserSession(sessionData);
  }

  setSignInUserSession(signInUserSession) {
    this.clearCachedTokens();
    this.signInUserSession = signInUserSession;
    this.cacheTokens();
  }

  getSignInUserSession() {
    return this.signInUserSession;
  }

  setTokens(tokens) {
    const idToken = new IdToken({
      IdToken: tokens.idToken || tokens.IdToken,
    });
    const accessToken = new AccessToken({
      AccessToken: tokens.accessToken || tokens.AccessToken,
    });

    const keyPrefix = 'skf-sso-identity';
    const clockDriftKey = `${keyPrefix}.clockDrift`;
    const clockDrift = parseInt(this.storage.getItem(clockDriftKey), 0) || 0;

    const sessionData = {
      IdToken: idToken,
      AccessToken: accessToken,
      ClockDrift: clockDrift,
    };

    if (tokens.refreshToken) {
      sessionData.RefreshToken = new RefreshToken({
        RefreshToken: tokens.refreshToken,
      });
    }

    const session = new UserSession(sessionData);
    if (session.isValid()) {
      this.setSignInUserSession(session);
      return this.signInUserSession;
    }
    return undefined;
  }

  async refreshSession(refreshToken) {
    if (!refreshToken) {
      const keyPrefix = 'skf-sso-identity';
      const refreshTokenKey = `${keyPrefix}.refreshToken`;

      refreshToken = this.storage.getItem(refreshTokenKey);
    }
    return superagent
      .post(`${process.env.REACT_APP_SSO_SERVICE_URL}/refresh`)
      .send({ refreshToken })
      .then(res => {
        this.setTokens(res.body.AuthenticationResult);
        return res.body.AuthenticationResult;
      });
  }

  async getSession() {
    if (this.signInUserSession != null && this.signInUserSession.isValid()) {
      return this.signInUserSession;
    }

    const keyPrefix = 'skf-sso-identity';
    const idTokenKey = `${keyPrefix}.idToken`;
    const accessTokenKey = `${keyPrefix}.accessToken`;
    const refreshTokenKey = `${keyPrefix}.refreshToken`;
    const clockDriftKey = `${keyPrefix}.clockDrift`;

    if (this.storage.getItem(idTokenKey)) {
      const idToken = new IdToken({
        IdToken: this.storage.getItem(idTokenKey),
      });
      const accessToken = new AccessToken({
        AccessToken: this.storage.getItem(accessTokenKey),
      });
      const refreshToken = new RefreshToken({
        RefreshToken: this.storage.getItem(refreshTokenKey),
      });
      const clockDrift = parseInt(this.storage.getItem(clockDriftKey), 0) || 0;

      const sessionData = {
        IdToken: idToken,
        AccessToken: accessToken,
        RefreshToken: refreshToken,
        ClockDrift: clockDrift,
      };
      const cachedSession = new UserSession(sessionData);
      if (cachedSession.isValid()) {
        this.signInUserSession = cachedSession;
        return this.signInUserSession;
      }

      if (refreshToken.getToken() == null) {
        return new Error('Cannot retrieve a new session. Please authenticate.');
      }

      await this.refreshSession(refreshToken.getToken());

      return this.signInUserSession;
    }
    return new Error('Local storage is missing an ID Token, Please authenticate');
  }
}
