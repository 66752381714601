import * as constants from './paths';
import * as errorActions from './error';

const LOAD = 'railway/train/LOAD';
const LOAD_SUCCESS = 'railway/train/LOAD_SUCCESS';
const LOAD_FAIL = 'railway/train/LOAD_FAIL';

const initialState = {
  loaded: false,
  train: {}
};

export default function train(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        train: {}
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        train: action.result
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    default:
      return state;
  }
}

export function fetchTrain(trainId) {
  console.log(`Fetching train id: ${trainId}`);

  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject();
      }

      return client.get(`${constants.RAILWAY_PATH}/train-with-bogies/${trainId}`).catch(e => {
        dispatch(errorActions.errorMessage('Error fetching train data.'));
        return Promise.reject(new Error(e));
      });
    }
  };
}
