import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const RootElement = styled.div`
  margin: auto;
  display: flex;
  flex: 1 1 0%;
  width: 1280px;
  max-width: 100%;
  padding-bottom: 1rem;

  @media only screen and (max-width: 784px) {
    display: block;
  }
`;

const Content = props => {
  return <RootElement {...props}>{props.children}</RootElement>;
};

Content.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default Content;
