import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const RootElement = styled.div`
  vertical-align: top;

  flex: ${props => props.cols || 1} ${props => props.cols || 1} 0%;
  background-color: ${props => {
    return props.white ? 'white' : 'transparent';
  }};
  padding-right: ${props => props.container && '2rem'};

  @media (max-width: 784px) {
    margin-bottom: 1rem;
    padding-right: 0;
  }
`;

const Box = ({ children, ...rest }) => (
  <RootElement {...rest}>{children}</RootElement>
);

export default Box;

Box.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  container: PropTypes.bool,
  cols: PropTypes.number,
};

Box.defaultProps = {
  cols: 1,
  container: false
};
