import * as constants from './paths';
import * as errorActions from './error';
import { fetchFleet } from './fleet';

const GET_IMAGE_UPLOAD_URL = 'railway/image/GET_IMAGE_UPLOAD_URL';
const GET_IMAGE_UPLOAD_URL_SUCCESS = 'railway/image/GET_IMAGE_UPLOAD_URL_SUCCESS';
const GET_IMAGE_UPLOAD_URL_FAIL = 'railway/image/GET_IMAGE_UPLOAD_URL_FAIL';
const UPLOAD_IMAGE = 'railway/image/UPLOAD_IMAGE';
const UPLOAD_IMAGE_SUCCESS = 'railway/image/UPLOAD_IMAGE_SUCCESS';
const UPLOAD_IMAGE_FAIL = 'railway/image/UPLOAD_IMAGE_FAIL';
const UPLOAD_IMAGE_WEBUI = 'railway/image/UPLOAD_IMAGE_WEBUI';
const UPLOAD_IMAGE_WEBUI_SUCCESS = 'railway/image/UPLOAD_IMAGE_WEBUI_SUCCESS';
const UPLOAD_IMAGE_WEBUI_FAIL = 'railway/image/UPLOAD_IMAGE_WEBUI_FAIL';
const INVALIDATE_CACHE = 'railway/image/INVALIDATE_CACHE';
const INVALIDATE_CACHE_SUCCESS = 'railway/image/INVALIDATE_CACHE_SUCCESS';
const INVALIDATE_CACHE_FAIL = 'railway/image/INVALIDATE_CACHE_FAIL';

const initialState = { loading: false, loaded: false };

export default function image(state = initialState, action = {}) {
  switch (action.type) {
    case UPLOAD_IMAGE:
    case UPLOAD_IMAGE_WEBUI:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case UPLOAD_IMAGE_SUCCESS:
    case UPLOAD_IMAGE_WEBUI_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case INVALIDATE_CACHE_SUCCESS:
      return {
        ...state,
        cacheInvalidation: action.result
      };
    case INVALIDATE_CACHE:
    case GET_IMAGE_UPLOAD_URL:
    case GET_IMAGE_UPLOAD_URL_SUCCESS:
    case GET_IMAGE_UPLOAD_URL_FAIL:
      return state;
    default:
      return initialState;
  }
}

export function uploadImage(fleetId, uri) {
  return {
    types: [GET_IMAGE_UPLOAD_URL, GET_IMAGE_UPLOAD_URL_SUCCESS, GET_IMAGE_UPLOAD_URL_FAIL],
    promise: (client, dispatch) => {
      return client
        .get(`${constants.RAILWAY_PATH}/image-upload/${fleetId}`)
        .then(res => dispatch(uploadToS3(uri, res.url)))
        .then(() => dispatch(generateCacheInvalidationToken()))
        .then(() => dispatch(fetchFleet(fleetId)))
        .catch(e => console.log(e));
    }
  };
}

function uploadToS3(uri, url) {
  return {
    types: [UPLOAD_IMAGE, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAIL],
    promise: (client, dispatch) =>
      fetch(uri, {
        mode: 'cors'
      })
        .then(response => response.blob())
        .then(body =>
          fetch(url, {
            method: 'PUT',
            headers: { 'Content-Type': 'image/jpeg' },
            body
          }))
        .then(response => {
          if (response.status !== 200) {
            dispatch(errorActions.errorMessage('Error uploading image.'));
            console.warn(response);
            return Promise.reject(new Error(response));
          }
          return Promise.resolve();
        })
        .catch(e => console.log(e))
  };
}

export function getSignedUploadURL(fleetId, callback) {
  return {
    types: [UPLOAD_IMAGE_WEBUI, UPLOAD_IMAGE_WEBUI_SUCCESS, UPLOAD_IMAGE_WEBUI_FAIL],
    promise: client => {
      return client
        .get(`${constants.RAILWAY_PATH}/image-upload/${fleetId}`)
        .then(res => callback({ signedUrl: res.url }))
        .catch(e => console.log(e));
    }
  };
}

export function refreshAfterUpload(fleetId) {
  return {
    types: [INVALIDATE_CACHE, INVALIDATE_CACHE_SUCCESS, INVALIDATE_CACHE_FAIL],
    promise: (_, dispatch) => dispatch(fetchFleet(fleetId)).then(() => dispatch(generateCacheInvalidationToken()))
  };
}

/**
 * Generate a new cache invalidation token for displaying the correct thumbnail for a freshly
 * uploaded image. Thumbnail generation takes about a second, so the delay here must be set
 * to be more than that -- better err on the safe side!
 */
function generateCacheInvalidationToken() {
  const delay = 3000;
  const token = Math.round(Math.random() * 1000000);
  return {
    types: [INVALIDATE_CACHE, INVALIDATE_CACHE_SUCCESS, INVALIDATE_CACHE_FAIL],
    promise: () =>
      new Promise(resolve => {
        setTimeout(resolve, delay, token);
      })
  };
}
