import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Loader as Spinner, Button } from 'dl-web-components';
import { capitalize } from 'lodash';
import styled from 'styled-components';
import ContentContainer from 'components/ContentContainer';
import Heading from 'components/Heading';
import Sidebar from 'components/Sidebar';
import SidebarListItem from 'components/SidebarListItem';
import AddComponentClassForm from 'container/AddComponentClassForm';
import FlatList from 'components/FlatList';
import Menu from 'container/Menu';
import styles from './styles';

const Body = styled.div``;
const Container = styled.div``;
const Header = styled.div``;
const Title = styled.h2``;
const ScrollView = styled.div``;
const List = styled.ul``;
const ListItem = styled(Link)``;

const StyledContent = styled.div`
flex - direction: row;
display: flex;
flex: 1;
`;

class OemBogiesPage extends PureComponent {
  constructor(props) {
    super(props);

    let type = props.selectedType;
    if (props.selectedType === undefined) {
      type = 'bogie';
    }

    this.state = { showSearch: true, selectedType: type, modalOpen: false, };
    this.renderItem.bind(this);
  }

  renderItem(item) {
    if (this.state.selectedType === 'vehicle') {
      return (
        <ListItem
          button
          key={item.family + new Date().getTime()}
          style={{ marginLeft: 0 }}
          to={`/oems/${this.props.oem.id}/vehicles/${item.id}/${encodeURIComponent(item.family)}`}
        // onClick={() => {
        //   go(navigator, 'VehiclePage', { vehicle: item, oem: this.props.oem });
        // }}
        >
          <p style={{ marginLeft: 0, paddingBottom: 10 }} >
            {item.family}
          </p>
          <p note>{item.fleets.length} fleets</p>
        </ListItem>
      );
    }

    // Note: axle is fetched directly
    // class, bogie, motor, generator and gearbox are fetched throught class
    // (bogieClass, motorClass, generatorClass and gearboxClass).
    const type = this.state.selectedType !== 'axle' ? `${this.state.selectedType}Class` : this.state.selectedType;
    return (
      <ListItem
        button
        key={item.id}
        style={{ marginLeft: 0 }}
        to={`/oems/${this.props.oem.id}/components/${type}/${item.id}`}
      >
        <p style={{ marginLeft: 0, paddingBottom: 10 }} >
          {item.designation}
        </p>
        {item.type && <p note>{capitalize(item.type)}</p>}

      </ListItem>
    );
  }

  render() {
    const { oem, vehicles } = this.props;
    const { modalOpen } = this.state;

    const selectedClasses = (this.state.selectedType === 'vehicle' ? vehicles : oem[`${this.state.selectedType}Classes`]) || [];
    return (
      <Container style={styles.container}>
        <Menu />
        <Header>


          <Body style={{ flex: 3, alignItems: 'center' }}>
            <Title>{oem.name || 'Loading...'}</Title>
          </Body>
        </Header>

        <StyledContent>
          <Sidebar>
            <ScrollView style={{ height: '100%' }}>
              <List>
                <SidebarListItem itemDivider>
                  <p>Categories</p>
                </SidebarListItem>
                <SidebarListItem onClick={() => this.setState({ selectedType: 'bogie' })}>
                  <p
                    style={{ cursor: 'pointer', fontWeight: this.state.selectedType === 'bogie' ? 'bold' : 'normal' }}
                  >
                    Bogies
                  </p>
                </SidebarListItem>
                <SidebarListItem onClick={() => this.setState({ selectedType: 'vehicle' })}>
                  <p
                    style={{ cursor: 'pointer', fontWeight: this.state.selectedType === 'vehicle' ? 'bold' : 'normal' }}
                  >
                    Vehicles
                  </p>
                </SidebarListItem>
                <SidebarListItem onClick={() => this.setState({ selectedType: 'motor' })}>
                  <p
                    style={{ cursor: 'pointer', fontWeight: this.state.selectedType === 'motor' ? 'bold' : 'normal' }}
                  >
                    Motors
                  </p>
                </SidebarListItem>
                <SidebarListItem onClick={() => this.setState({ selectedType: 'gearbox' })}>
                  <p
                    style={{ cursor: 'pointer', fontWeight: this.state.selectedType === 'gearbox' ? 'bold' : 'normal' }}
                  >
                    Gearboxes
                  </p>
                </SidebarListItem>
              </List>

              <List style={{ marginBottom: 0 }}>
                <SidebarListItem itemDivider />
                <SidebarListItem>
                  <Button icon="cog" variant="primary" onClick={() => this.setState({ modalOpen: !modalOpen })}>Add component</Button>
                  {modalOpen && (
                    <Modal style={{ maxHeight: '90%' }} linkColor="#007AFF" toggleModal={() => this.setState({ modalOpen: !modalOpen })}>
                      <AddComponentClassForm oemId={oem.id} oem={oem} toggleModal={() => this.setState({ modalOpen: !modalOpen })} />
                    </Modal>
                  )}
                </SidebarListItem>
              </List>
            </ScrollView>
          </Sidebar>

          <ContentContainer>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <div>
                <Heading>
                  {this.state.selectedType.charAt(0).toUpperCase() + this.state.selectedType.slice(1)} ({selectedClasses.length})
                </Heading>
              </div>
            </div>

            <div style={{ display: this.state.showSearch ? 'block' : 'none' }}>{this.props.searchForm}</div>

            {this.props.loading && (
              <div>
                <Spinner style={{ width: '100%', height: 20, alignItems: 'center' }} />
              </div>
            )}

            <FlatList
              data={selectedClasses}
              renderItem={item => this.renderItem(item)}
            />
          </ContentContainer>
        </StyledContent>
      </Container>
    );
  }
}

export default OemBogiesPage;
