import React, { useState } from 'react';
import { Modal, Button } from 'dl-web-components';
import moment from 'moment';
import styled from 'styled-components';
import TRow from 'components/TRow';
import TCell from 'components/TCell';
import AddMaintenanceForm from 'container/AddMaintenanceForm';
import AddReminderForm from 'container/AddReminderForm';

const Left = styled.div``;
const Body = styled.div``;
const Right = styled.div``;

const Container = styled.div`
  border: ${props => (props.predicted ? '1px solid #0f58d6' : '0')};
  background-color: ${props => (props.predicted ? 'transparent' : '#ebf0f0')};

  padding: 20px;
  margin-right: 10px;
  margin-bottom: 20px;
  align-items: center;
`;

const Line = styled.div`
  border-bottom-width: 1px;
  border-bottom-color: ${props => (props.predicted ? '#0f58d6' : '#979797')};
  align-self: flex-start;
  width: 100%;
  margin: 20px 0;
`;

const StyledText = styled.p`
  line-height: 30px;
`;

const TopContainer = styled.div`
  flex-direction: row;
`;

const FleetMaintenanceBox = ({ maintenance, predicted, userInput }) => {
  const [modalOpen, toggleModal] = useState(false);
  const [reminderModalOpen, toggleReminderModal] = useState(false);

  return (
    <Container predicted={predicted}>
      <TopContainer style={{ width: '100%', alignContent: 'space-between' }}>
        <Left style={{ flex: 1, alignSelf: 'flex-start' }}>
          {!predicted && (
            <React.Fragment>
              <Button icon="cog" variant="plain" onClick={() => toggleReminderModal(!reminderModalOpen)}>Reminder</Button>
              {reminderModalOpen && (
                <Modal style={{ maxHeight: '90%' }} toggleModal={() => toggleReminderModal(!reminderModalOpen)}>
                  <AddReminderForm data={maintenance} toggleModal={() => toggleReminderModal(!reminderModalOpen)} />
                </Modal>
              )}
              {/*             <Modal button="Reminder" linkColor="blue">
              <AddReminderForm data={maintenance} />
            </Modal>
 */}

            </React.Fragment>

          )}
        </Left>
        <Body style={{ flex: 3, width: '100%' }}>
          {predicted && <StyledText style={{ color: '#0f58d6' }}>Predicted maintenance</StyledText>}
          <StyledText>{moment(maintenance.date).format('MMMM Y')}</StyledText>

          <StyledText>{maintenance.notes}</StyledText>
          {userInput && <StyledText note>Added by {maintenance.user}</StyledText>}
          {userInput && (
            <StyledText note>
              Last updated on {maintenance.updatedAt.substring(0, maintenance.updatedAt.indexOf('T'))}
            </StyledText>
          )}
        </Body>

        <Right style={{ flex: 1, alignSelf: 'flex-start' }}>
          {!predicted && (
            <React.Fragment>
              <Button icon="cog" variant="plain" onClick={() => toggleModal(!modalOpen)}>Edit</Button>
              {modalOpen && (
                <Modal style={{ maxHeight: '90%' }} toggleModal={() => toggleModal(!modalOpen)}>
                  <AddMaintenanceForm data={maintenance} toggleModal={() => toggleModal(!modalOpen)} />
                </Modal>
              )}
              {/* <Modal button="Edit" linkColor="blue">
            <AddMaintenanceForm data={maintenance} />
          </Modal> */}

            </React.Fragment>
          )}
        </Right>
      </TopContainer>
      <Line predicted={predicted} />

      <TRow>
        <TCell multiline>
          <div>
            <p note>
              Maintenance target(s)
          </p>
          </div>
          <p>---</p>
        </TCell>
        <TCell multiline>
          <div>
            <p note>
              Last maintenance
          </p>
          </div>
          <p>---</p>
        </TCell>
      </TRow>
    </Container>
  );
};

export default FleetMaintenanceBox;
