import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import TrainBogie from 'components/TrainBogie';
import SidebarListItem from 'components/SidebarListItem';
import Sidebar from 'components/Sidebar';
import Heading from 'components/Heading';
import { exists } from 'helpers/validation';
import FavoriteIcon from 'components/FavoriteIcon';

import styles from './styles';


const List = styled.ul``;
const ListItem = styled(Link)``;
const Body = styled.div``;
const Right = styled.div``;
const Container = styled.div``;
const Header = styled.div``;
const Title = styled.h2``;

const { REACT_APP_IMAGE_DOMAIN } = process.env;

const StyledContent = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
`;

const ContentContainer = styled.div`
  flex: 1;
`;

export default class TrailerBogiePage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { favorite: props.favorite };
  }

  bogiesByType(type) {
    return this.props.train.bogies.filter(bogie => {
      if (bogie.bogieClass && bogie.bogieClass.type === type.toUpperCase()) {
        return true;
      }
      return false;
    });
  }

  itemsByType(type) {
    return this.props.train.bogies.filter(bogie => !!bogie[type].length);
  }

  render() {
    const { fleet, trains, train } = this.props;

    const [fleetTrain] = trains || [];

    if (!fleet.operator || !train.id || !fleetTrain) {
      return <p>Loading</p>;
    }
    const trailerBogies = this.bogiesByType('trailer');
    const trailerBogie = trailerBogies[0] || {};
    trailerBogie.amountPerVehicle = trailerBogies.length;

    // const bearings = this.itemsByType('Bearings');

    const isFavorite = exists(this.state.favorite) && !!this.state.favorite;

    return (
      <Container style={styles.container}>
        <Header>


          <Body style={{ flex: 3, alignItems: 'center' }}>
            <Title><Link to={`/operators/${fleet.operator.id}`}>{fleet.operator.name}</Link></Title>
          </Body>

          <Right />
        </Header>

        <StyledContent>
          <Sidebar>
            <List>
              <SidebarListItem onClick={() => this.props.switchPage('')}>
                <p >Overview</p>
              </SidebarListItem>
              <SidebarListItem onClick={() => this.props.switchPage('motor')}>
                <p >Motor Bogies</p>
              </SidebarListItem>
              <SidebarListItem onClick={() => this.props.switchPage('trailer')}>
                <p style={{ color: '#007AFF' }}>
                  Trailer Bogies
                </p>
              </SidebarListItem>
              <SidebarListItem onClick={() => this.props.switchPage('maintenance')}>
                <p >Maintenance</p>
              </SidebarListItem>
            </List>
          </Sidebar>
          <ContentContainer >
            <div style={{ flexDirection: 'row' }}>
              <div>
                <image
                  style={{ width: 150, height: 150, margin: 15, marginLeft: 0 }}
                  src={`${REACT_APP_IMAGE_DOMAIN}/${fleet.imageIdentifier}.jpg`}
                />
              </div>

              <div style={{ flexDirection: 'column', width: '65%' }}>
                <div style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div>
                    <Heading>
                      {fleet.name}
                      <FavoriteIcon
                        favorite={isFavorite}
                        onClick={() => {
                          this.setState({ favorite: !this.props.favorite });
                          this.props.toggleFavorite(fleet.id, this.props.favorite);
                        }}
                      />
                    </Heading>
                  </div>

                </div>

                <div>
                  <p note>{fleet.trainCount} vehicles</p>
                  <p note>
                    {train.trainClass.numberOfUnits}
                    -car {fleetTrain.trainClass.vehicleType.name}
                  </p>
                  <p note>
                    {train.trainClass.Oems.length && train.trainClass.Oems[0].name} {fleetTrain.trainClass.family}
                    {'\n'}
                  </p>
                </div>
              </div>
            </div>
            <List>
              <ListItem />
              <Heading>Trailer bogies</Heading>
              <TrainBogie name="Trailer bogies" bogie={trailerBogie} fleet={fleet} detailed />

              {/* <TrainBogieItem name="Wheelset" type="Bearings" bogies={bearings} navigation={navigation} detailed /> */}
            </List>
          </ContentContainer>
        </StyledContent>
      </Container>
    );
  }
}
