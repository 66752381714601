import * as React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import ModalForm from 'stories/screens/ModalForm';
import FormField from 'components/FormField';
import { exists, isNotEmpty, isPositiveInteger, isValidDate } from 'helpers/validation';
import { kmToMiles, accuracy, milesToKm, roundingLimit } from 'helpers/units';
import { stripSpaces, conditionalRound } from 'helpers/data-structures';
import * as fleetActions from 'redux/modules/fleet';

class EditFleetForm extends React.PureComponent {
  constructor(props) {
    super(props);
    const { franchiseStartDate, franchiseEndDate } = props.data;

    this.state = {
      franchiseStartDate,
      franchiseEndDate,
      validationErrors: {
        mileagePerYear: false,
        maintenanceIntervalKm: false,
        maintenanceIntervalYears: false
      }
    };
    this.validationErrorMessage = 'must be a positive whole number';
  }

  nullableConvert(value) {
    return value ? milesToKm[this.props.distanceUnit](Number(stripSpaces(value))) : null;
  }

  edit(values) {
    const distances = {
      mileagePerYear: this.nullableConvert(values.trainClass.mileagePerYear),
      maintenanceIntervalKm: this.nullableConvert(values.trainClass.maintenanceIntervalKm)
    };

    const { franchiseEndDate, franchiseStartDate } = this.state;
    const newValues = {
      ...values,
      franchiseStartDate,
      franchiseEndDate,
      id: this.props.data.id,
      trainClass: {
        ...values.trainClass,
        ...distances
      }
    };
    this.props.editFleet(newValues);
    this.props.toggleModal();
    this.props.data.isConverted = false;
  }

  handleStateChange(key, val) {
    this.setState({ [key]: val });
  }

  handleInputChange(field, value) {
    console.log(field, value);
    value = stripSpaces(value);
    this.setState({
      validationErrors: {
        ...this.state.validationErrors,
        [field]: isNotEmpty(value) && !isPositiveInteger(value)
      }
    });
  }

  handleDateChange(field, value) {
    this.setState({
      [field]: value,
      validationErrors: {
        ...this.state.validationErrors,
        [field]: isNotEmpty(value) && !isValidDate(value)
      }
    });
  }

  formIsValid() {
    const errors = Object.values(this.state.validationErrors);
    return errors.every(item => item === false);
  }

  render() {
    const { handleSubmit } = this.props;

    const form = (
      <div style={{ flex: 1 }}>
        <form style={{ flex: 1 }}>
          <FormField name="transportAuthority" label="Transport authority" />
          <FormField name="transportState" label="Transport state" />
          <FormField name="franchiseDescription" label="Franchise name" />
          <FormField
            name="franchiseStartDate"
            label="Franchise start date"
            errorMessage={this.state.validationErrors.franchiseStartDate ? 'must be in format YYYY-MM-DD' : ''}
            onChange={e => this.handleDateChange('franchiseStartDate', e.target.value)}
          />
          <FormField
            name="franchiseEndDate"
            label="Franchise end date"
            errorMessage={this.state.validationErrors.franchiseEndDate ? 'must be in format YYYY-MM-DD' : ''}
            onChange={e => this.handleDateChange('franchiseEndDate', e.target.value)}
          />
          <FormField name="maintenanceLocation" label="Maintenance location" />
          <FormField name="maintenanceCompany" label="Maintenance company" />
          <FormField
            name="trainClass.mileagePerYear"
            label={`Annual mileage (${this.props.distanceUnit})`}
            errorMessage={this.state.validationErrors.mileagePerYear ? this.validationErrorMessage : ''}
            onChange={e => this.handleInputChange('mileagePerYear', e.target.value)}
          />
          <FormField
            name="trainClass.maintenanceIntervalKm"
            label={`Maintenance interval (${this.props.distanceUnit})`}
            errorMessage={this.state.validationErrors.maintenanceIntervalKm ? this.validationErrorMessage : ''}
            onChange={e => this.handleInputChange('maintenanceIntervalKm', e.target.value)}
          />
          <FormField
            name="trainClass.maintenanceIntervalYears"
            label="Maximum maintenance interval in years"
            errorMessage={this.state.validationErrors.maintenanceIntervalYears ? this.validationErrorMessage : ''}
            onChange={e => this.handleInputChange('maintenanceIntervalYears', e.target.value)}
          />
          <FormField name="description" label="Description" />
        </form>
      </div>
    );

    return (
      <ModalForm
        title="Edit fleet"
        edit={this.props.data.id}
        loading={this.props.fleet.loading}
        navigation={this.props.navigation}
        form={form}
        disabled={!this.formIsValid()}
        onSave={handleSubmit(this.edit.bind(this))}
        onCancel={this.props.toggleModal}
      />
    );
  }
}

function mapState(state, ownProps) {
  const { distanceUnit } = state.userSettingsReducer;
  const data = ownProps.data || {};

  // Convert kilometers to user preferred units.
  if (!data.isConverted && data.trainClass) {
    if (exists(data.trainClass.mileagePerYear)) {
      data.trainClass.mileagePerYear = conditionalRound(
        kmToMiles[distanceUnit](data.trainClass.mileagePerYear),
        accuracy[distanceUnit],
        roundingLimit
      ).toString();
    }
    if (exists(data.trainClass.maintenanceIntervalKm)) {
      data.trainClass.maintenanceIntervalKm = conditionalRound(
        kmToMiles[distanceUnit](data.trainClass.maintenanceIntervalKm),
        accuracy[distanceUnit],
        roundingLimit
      ).toString();
    }

    const { franchiseStartDate, franchiseEndDate } = data;
    data.franchiseStartDate = franchiseStartDate ? moment(franchiseStartDate).format('Y-M-D') : null;
    data.franchiseEndDate = franchiseEndDate ? moment(franchiseEndDate).format('Y-M-D') : null;
    data.isConverted = true;
  }

  const { fleetReducer, maintenanceReducer, trainReducer } = state;
  return {
    fleet: fleetReducer,
    distanceUnit,
    maintenance: maintenanceReducer,
    train: trainReducer.train,
    initialValues: data
  };
}

function mapDispatch(dispatch) {
  return {
    ...bindActionCreators({ ...fleetActions }, dispatch),
    dispatch
  };
}

const EditFleetContainerForm = reduxForm({
  form: 'editFleetForm',
})(EditFleetForm);

EditFleetForm.propTypes = {
  data: PropTypes.any,
  distanceUnit: PropTypes.string,
  fleet: PropTypes.any,
  navigation: PropTypes.any,
  editFleet: PropTypes.func,
  toggleModal: PropTypes.func,
  handleSubmit: PropTypes.func
};

export default connect(
  mapState,
  mapDispatch
)(EditFleetContainerForm);
