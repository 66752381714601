import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';
import { Provider } from 'react-redux';
import App from 'App';
import 'style.css';
import registerServiceWorker from 'registerServiceWorker';
import createStore from 'redux/create';
import ApiClient from 'helpers/ApiClient';
// import { AzureAD } from 'react-aad-msal';
// import authProvider from './authProvider';

// Load Google fonts async
WebFont.load({
  google: {
    families: ['Lato:300,400,600', 'Roboto:300,400,600'],
    timeout: 2000
  }
});

const client = new ApiClient();
const store = createStore(client);

const render = Component => {
  ReactDOM.render(
    // <AzureAD provider={authProvider} forceLogin={true}>
    <Provider store={store}>
      <Component />
    </Provider>,
    // </AzureAD>,
    document.getElementById('root')
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}


registerServiceWorker();
