import * as constants from './paths';
import * as errorActions from './error';

const LOAD = 'railway/search/LOAD';
const LOAD_SUCCESS = 'railway/search/LOAD_SUCCESS';
const LOAD_FAIL = 'railway/search/LOAD_FAIL';

const initialState = {
  loaded: false,
  result: {}
};

export default function search(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        result: {}
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: action.result
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    default:
      return state;
  }
}

export function searchContent(term) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject();
      }

      if (term.length < 2) {
        return Promise.resolve({});
      }

      return client.post(`${constants.RAILWAY_PATH}/search`, { data: { term } }).catch(e => {
        dispatch(errorActions.errorMessage('Error while searching.'));
        return Promise.reject(new Error(e));
      });
    }
  };
}
