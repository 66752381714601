import * as React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import FormField from 'components/FormField';
import { isBetween, isPositiveInteger, isNotEmpty } from 'helpers/validation';
import AddMaintenance from 'stories/screens/AddMaintenance';
import * as maintenanceActions from 'redux/modules/maintenance';

const required = value => (value ? undefined : 'Required');
const number = value => (value && /[^0-9 ]/i.test(value) ? 'Numbers only' : undefined);

const validate = values => {
  const { month, year } = values;
  const errors = {};
  const validateField = (field, predicate, error) => {
    if (predicate) {
      // errors.delete(error);
      return true;
    }
    errors[field] = error;
    return false;
  };

  validateField('month', isNotEmpty(month) && isBetween(month, 1, 12), 'Month must be a whole number in range 1-12');
  validateField('year', isNotEmpty(year) && isPositiveInteger(year), 'Year must be a positive whole number');

  return errors;
};

class AddMaintenanceForm extends React.PureComponent {
  textInput: any;

  constructor(props) {
    super(props);

    let year = '';
    let month = '';
    if (props.data) {
      const date = new Date(props.data.date);
      year = date.getFullYear().toString();
      month = (date.getMonth() + 1).toString();
    }

    this.state = {
      userInputs: {
        month,
        year
      },
    };
  }


  create(values) {
    values.fleetId = this.props.train.fleetId;
    values.date = new Date(values.year, values.month - 1, 15).toISOString();
    this.props.createMaintenance(values);
    this.props.toggleModal();
  }

  edit(values) {
    values.fleetId = this.props.train.fleetId;
    values.date = new Date(values.year, values.month - 1, 15).toISOString();
    values.id = this.props.data.id;
    this.props.editMaintenance(values);
    this.props.toggleModal();
  }

  handleChange(key, value) {
    console.log(key, value);
    const nextState = this.state;
    nextState.userInputs[key] = value;
    this.validateForm(nextState);
  }

  render() {
    const onWebUI = true;
    const { handleSubmit, data } = this.props;
    const form = (
      <div style={{ flex: 1 }}>
        <form style={{ flex: 1 }}>
          <div style={{ flexDirection: 'row' }}>
            <FormField
              name="month"
              label={onWebUI ? 'Add month' : ''}
              validate={[number, required]}
            />
            <FormField
              name="year"
              label={onWebUI ? 'Add year' : ''}
              validate={[number, required]}
            />
          </div>
          <div style={{ flex: 1 }}>
            <FormField name="notes" label="Type notes here" />
          </div>
        </form>
      </div>
    );

    if (data) {
      return (
        <AddMaintenance
          edit={data.id}
          loading={this.props.fleet.loading}
          maintenanceForm={form}
          onSave={handleSubmit(this.edit.bind(this))}
          onCancel={this.props.toggleModal}
        />
      );
    }
    return (
      <AddMaintenance
        loading={this.props.fleet.loading}
        maintenanceForm={form}
        onSave={handleSubmit(this.create.bind(this))}
        onCancel={this.props.toggleModal}
      />
    );
  }
}

function mapState(state, ownProps) {
  const data = ownProps.data || {};
  const { fleetReducer, maintenanceReducer, trainReducer } = state;
  return {
    fleet: fleetReducer,
    maintenance: maintenanceReducer,
    train: trainReducer.train,
    initialValues: {
      month: !data.date ? '' : moment(data.date).format('M'),
      year: !data.date ? '' : moment(data.date).format('YYYY'),
      notes: data.notes
    }
  };
}

function mapDispatch(dispatch) {
  return {
    ...bindActionCreators({ ...maintenanceActions }, dispatch),
    dispatch
  };
}

const AddMaintenanceContainerForm = reduxForm({
  form: 'createMaintenanceForm',
  validate,
})(AddMaintenanceForm);

export default connect(
  mapState,
  mapDispatch
)(AddMaintenanceContainerForm);
