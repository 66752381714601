import React from 'react';
import styled from 'styled-components';
import uuid from 'uuid/v4';
import { FloatingLabelInput, TextInput } from 'dl-web-components';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

const Warning = styled.div`
  color: rgb(214,39,47);
`;

const renderInput = ({ input, label, value, meta: { touched, warning, error }, ...custom }) => {
  return (
    <React.Fragment>
      <TextInput
        label={label}
        placeholder={label}
        error={touched && warning}
        helperText={touched && error}
        {...input}
        {...custom}
      />
      {touched && ((error && <Warning>{error}</Warning>) || (warning && <span>{warning}</span>))}
    </React.Fragment>
  );
};

renderInput.propTypes = {
  input: PropTypes.any,
  label: PropTypes.string,
  value: PropTypes.string,
  meta: PropTypes.object,
};

export default props => <FloatingLabelInput labelText={`${props.label}`} id={uuid()}><Field {...props} component={renderInput} /></FloatingLabelInput>;
