import * as updatedActions from 'redux/modules/updated';
import * as errorActions from './error';
import * as constants from './paths';

const LOAD = 'railway/maintenance/LOAD';
const LOAD_SUCCESS = 'railway/maintenance/LOAD_SUCCESS';
const LOAD_FAIL = 'railway/maintenance/LOAD_FAIL';

const LOAD_FLEET_ESTIMATED_MAINTENANCE = 'railway/maintenance/LOAD_FLEET_ESTIMATED_MAINTENANCE';
const LOAD_FLEET_ESTIMATED_MAINTENANCE_SUCCESS = 'railway/maintenance/LOAD_FLEET_ESTIMATED_MAINTENANCE_SUCCESS';
const LOAD_FLEET_ESTIMATED_MAINTENANCE_FAIL = 'railway/maintenance/LOAD_FLEET_ESTIMATED_MAINTENANCE_FAIL';

const LOAD_FLEET_SCHEDULED_MAINTENANCE = 'railway/maintenance/LOAD_FLEET_SCHEDULED_MAINTENANCE';
const LOAD_FLEET_SCHEDULED_MAINTENANCE_SUCCESS = 'railway/maintenance/LOAD_FLEET_SCHEDULED_MAINTENANCE_SUCCESS';
const LOAD_FLEET_SCHEDULED_MAINTENANCE_FAIL = 'railway/maintenance/LOAD_FLEET_SCHEDULED_MAINTENANCE_FAIL';

const LOAD_OPERATOR_SCHEDULED_MAINTENANCE = 'railway/maintenance/LOAD_OPERATOR_SCHEDULED_MAINTENANCE';
const LOAD_OPERATOR_SCHEDULED_MAINTENANCE_SUCCESS = 'railway/maintenance/LOAD_OPERATOR_SCHEDULED_MAINTENANCE_SUCCESS';
const LOAD_OPERATOR_SCHEDULED_MAINTENANCE_FAIL = 'railway/maintenance/LOAD_OPERATOR_SCHEDULED_MAINTENANCE_FAIL';

const LOAD_OPERATOR_UPCOMING_MAINTENANCE = 'railway/maintenance/LOAD_OPERATOR_UPCOMING_MAINTENANCE';
const LOAD_OPERATOR_UPCOMING_MAINTENANCE_SUCCESS = 'railway/maintenance/LOAD_OPERATOR_UPCOMING_MAINTENANCE_SUCCESS';
const LOAD_OPERATOR_UPCOMING_MAINTENANCE_FAIL = 'railway/maintenance/LOAD_OPERATOR_UPCOMING_MAINTENANCE_FAIL';

const CREATE = 'railway/maintenance/CREATE';
const CREATE_SUCCESS = 'railway/maintenance/CREATE_SUCCESS';
const CREATE_FAIL = 'railway/maintenance/CREATE_FAIL';

const ADD_REMINDER = 'railway/maintenance/ADD_REMINDER';
const ADD_REMINDER_SUCCESS = 'railway/maintenance/ADD_REMINDER_SUCCESS';
const ADD_REMINDER_FAIL = 'railway/maintenance/ADD_REMINDER_FAIL';

const GET_REMINDER = 'railway/maintenance/GET_REMINDER';
const GET_REMINDER_SUCCESS = 'railway/maintenance/GET_REMINDER_SUCCESS';
const GET_REMINDER_FAIL = 'railway/maintenance/GET_REMINDER_FAIL';

const UPDATE_REMINDER = 'railway/maintenance/UPDATE_REMINDER';
const UPDATE_REMINDER_SUCCESS = 'railway/maintenance/UPDATE_REMINDER_SUCCESS';
const UPDATE_REMINDER_FAIL = 'railway/maintenance/UPDATE_REMINDER_FAIL';

const DELETE_REMINDER = 'railway/maintenance/DELETE_REMINDER';
const DELETE_REMINDER_SUCCESS = 'railway/maintenance/DELETE_REMINDER_SUCCESS';
const DELETE_REMINDER_FAIL = 'railway/maintenance/DELETE_REMINDER_FAIL';

const GET_REMINDERS = 'railway/maintenance/GET_REMINDERS';
const GET_REMINDERS_SUCCESS = 'railway/maintenance/GET_REMINDERS_SUCCESS';
const GET_REMINDERS_FAIL = 'railway/maintenance/GET_REMINDERS_FAIL';

const initialState = {
  loaded: false,
  loading: false,
  existingMaintenances: [],
  upcomingMaintenances: [],
  fleetExistingMaintenances: [],
  fleetEstimatedMaintenances: [],
  maintenances: [],
  monthsBefore: null
};

export default function maintenance(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        maintenances: [] // reset previous maintenances data
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        maintenances: action.result || []
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        maintenances: []
      };
    case LOAD_FLEET_ESTIMATED_MAINTENANCE:
      return {
        ...state,
        fleetEstimatedMaintenances: [] // reset previous maintenances data
      };
    case LOAD_FLEET_ESTIMATED_MAINTENANCE_SUCCESS:
      return {
        ...state,
        fleetEstimatedMaintenances: action.result || []
      };
    case LOAD_FLEET_ESTIMATED_MAINTENANCE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        fleetEstimatedMaintenances: []
      };
    case LOAD_FLEET_SCHEDULED_MAINTENANCE:
      return {
        ...state,
        fleetExistingMaintenances: [] // reset previous maintenances data
      };
    case LOAD_FLEET_SCHEDULED_MAINTENANCE_SUCCESS:
      return {
        ...state,
        fleetExistingMaintenances: action.result || []
      };
    case LOAD_FLEET_SCHEDULED_MAINTENANCE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        fleetExistingMaintenances: []
      };

    case LOAD_OPERATOR_SCHEDULED_MAINTENANCE:
      return {
        ...state,
        loading: true,
        existingMaintenances: [] // reset previous maintenances data
      };
    case LOAD_OPERATOR_SCHEDULED_MAINTENANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        existingMaintenances: action.result || []
      };
    case LOAD_OPERATOR_SCHEDULED_MAINTENANCE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        existingMaintenances: []
      };

    case LOAD_OPERATOR_UPCOMING_MAINTENANCE:
      return {
        ...state,
        loading: true,
        upcomingMaintenances: [] // reset previous maintenances data
      };
    case LOAD_OPERATOR_UPCOMING_MAINTENANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        upcomingMaintenances: action.result || []
      };
    case LOAD_OPERATOR_UPCOMING_MAINTENANCE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        upcomingMaintenances: []
      };

    case GET_REMINDER:
      return {
        ...state,
        loading: true,
        monthsBefore: null
      };
    case GET_REMINDER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        monthsBefore: action.result.monthsBefore
      };
    case GET_REMINDER_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        monthsBefore: null
      };

    case DELETE_REMINDER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        monthsBefore: null
      };

    default:
      return state;
  }
}

/**
 * Fetch all projected maintenances for current user's favorite fleets
 */
export function fetchProjectedMaintenances(fleetId = false) {
  let url = 'maintenances-next';
  if (fleetId) {
    url = `maintenances-for-fleet/${fleetId}`;
  }

  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject();
      }
      return client.get(`${constants.MAINTENANCE_PATH}/${url}`).catch(e => {
        dispatch(errorActions.errorMessage('Error fetching projected maintenances.'));
        return Promise.reject(new Error(e));
      });
    }
  };
}

/**
 * Fetch existing maintenances for a single fleet.
 *
 * @param {Number}  fleetId     Fleet id
 * @param {Boolean} futureOnly  If set to true, only get future maintenances, otherwise get also past maintenances.
 */
export function fetchMaintenancesForFleet(fleetId, futureOnly) {
  let url = `maintenances-for-fleet/${fleetId}`;
  if (futureOnly) {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    url = `${url}/?year=${year}&month=${month}`;
  }

  return {
    types: [
      LOAD_FLEET_SCHEDULED_MAINTENANCE,
      LOAD_FLEET_SCHEDULED_MAINTENANCE_SUCCESS,
      LOAD_FLEET_SCHEDULED_MAINTENANCE_FAIL
    ],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject();
      }
      return client.get(`${constants.MAINTENANCE_PATH}/${url}`).catch(e => {
        dispatch(errorActions.errorMessage('Error fetching maintenances for fleet.'));
        return Promise.reject(new Error(e));
      });
    }
  };
}

/**
 * Fetch the next projected maintenance date for fleet.
 *
 * @param {*} fleetId   Fleet id
 */
export function fetchEstimatedMaintenancesForFleet(fleetId) {
  const url = `maintenances-for-fleet/${fleetId}/next`;

  return {
    types: [
      LOAD_FLEET_ESTIMATED_MAINTENANCE,
      LOAD_FLEET_ESTIMATED_MAINTENANCE_SUCCESS,
      LOAD_FLEET_ESTIMATED_MAINTENANCE_FAIL
    ],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject();
      }
      return client.get(`${constants.MAINTENANCE_PATH}/${url}`).catch(e => {
        dispatch(errorActions.errorMessage('Error fetching estimated maintenances for fleet.'));
        return Promise.reject(new Error(e));
      });
    }
  };
}

/**
 * Fetch any maintenances through fleet's operator.
 *
 * @param {integer}   operatorId    Operator id
 * @param {number}    year          Year to get maintenances after
 * @param {number}    month         Month to get maintenances after
 */
export function fetchMaintenancesForOperator(operatorId, year, month) {
  const datePart = `/?year=${year}&month=${month}`;

  return {
    types: [
      LOAD_OPERATOR_SCHEDULED_MAINTENANCE,
      LOAD_OPERATOR_SCHEDULED_MAINTENANCE_SUCCESS,
      LOAD_OPERATOR_SCHEDULED_MAINTENANCE_FAIL
    ],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject();
      }
      return client.get(`${constants.MAINTENANCE_PATH}/maintenances-for-operator/${operatorId}${datePart}`).catch(e => {
        dispatch(errorActions.errorMessage('Error fetching maintenances for operator.'));
        return Promise.reject(new Error(e));
      });
    }
  };
}

/**
 * Fetch upcoming scheduled and estimated maintenances for operator.
 *
 * @param {integer}   operatorId    Operator id
 */
export function fetchUpcomingMaintenancesForOperator(operatorId) {
  return {
    types: [
      LOAD_OPERATOR_UPCOMING_MAINTENANCE,
      LOAD_OPERATOR_UPCOMING_MAINTENANCE_SUCCESS,
      LOAD_OPERATOR_UPCOMING_MAINTENANCE_FAIL
    ],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject();
      }
      return client.get(`${constants.MAINTENANCE_PATH}/maintenances-for-operator/${operatorId}/next`).catch(e => {
        dispatch(errorActions.errorMessage('Error fetching estimated maintenances for operator.'));
        return Promise.reject(new Error(e));
      });
    }
  };
}

export function createMaintenance(data) {
  return {
    types: [CREATE, CREATE_SUCCESS, CREATE_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject();
      }
      return client
        .put(`${constants.MAINTENANCE_PATH}/maintenances`, { token: getState().authReducer.jwt, data })
        .then(() => {
          dispatch(fetchMaintenancesForFleet(data.fleetId, false));
        })
        .then(() => {
          dispatch(fetchEstimatedMaintenancesForFleet(data.fleetId));
        })
        .then(() => {
          dispatch(fetchProjectedMaintenances());
        })
        .then(() => {
          dispatch(updatedActions.fetchUpdatedForFleet(data.fleetId));
        })
        .catch(e => {
          dispatch(errorActions.errorMessage('Error creating maintenance.'));
          return Promise.reject(new Error(e));
        });
    }
  };
}

export function editMaintenance(data) {
  console.log('EDIT MAINTENANCE', data);
  return {
    types: [CREATE, CREATE_SUCCESS, CREATE_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject();
      }
      return client
        .patch(`${constants.MAINTENANCE_PATH}/maintenances/${data.id}`, { token: getState().authReducer.jwt, data })
        .then(() => {
          dispatch(fetchMaintenancesForFleet(data.fleetId, false));
        })
        .then(() => {
          dispatch(fetchEstimatedMaintenancesForFleet(data.fleetId));
        })
        .then(() => {
          dispatch(fetchProjectedMaintenances());
        })
        .then(() => {
          dispatch(updatedActions.fetchUpdatedForFleet(data.fleetId));
        })
        .catch(e => {
          dispatch(errorActions.errorMessage('Error editing maintenance.'));
          return Promise.reject(new Error(e));
        });
    }
  };
}

/**
 * Add a maintenance reminder to specified maintenance.
 *
 * @param {integer} monthsBefore
 * @param {string}  maintenanceId
 */
export function addReminder(monthsBefore, maintenanceId) {
  const data = { monthsBefore, maintenanceId };
  console.log('Adding reminder', data);
  return {
    types: [ADD_REMINDER, ADD_REMINDER_SUCCESS, ADD_REMINDER_FAIL],
    promise: (client, dispatch) => {
      return client
        .put(`${constants.MAINTENANCE_PATH}/reminder`, { data })
        .then(() => {
          dispatch(getReminder(maintenanceId));
        })
        .catch(e => {
          dispatch(errorActions.errorMessage('Error adding reminder.'));
          return Promise.reject(new Error(e));
        });
    }
  };
}

/**
 * Update the maintenance reminder of the specified maintenance.
 *
 * @param {integer} monthsBefore
 * @param {string}  maintenanceId
 */
export function updateReminder(monthsBefore, maintenanceId) {
  const data = { monthsBefore };
  console.log('Updating reminder', data);
  return {
    types: [UPDATE_REMINDER, UPDATE_REMINDER_SUCCESS, UPDATE_REMINDER_FAIL],
    promise: (client, dispatch) => {
      return client
        .patch(`${constants.MAINTENANCE_PATH}/reminder/${maintenanceId}`, { data })
        .then(() => {
          dispatch(getReminder(maintenanceId));
        })
        .catch(e => {
          dispatch(errorActions.errorMessage('Error updating reminder.'));
          return Promise.reject(new Error(e));
        });
    }
  };
}

/**
 * Get the maintenance reminder for specified maintenance.
 *
 * @param {string} maintenanceId
 */
export function getReminder(maintenanceId) {
  return {
    types: [GET_REMINDER, GET_REMINDER_SUCCESS, GET_REMINDER_FAIL],
    promise: (client, dispatch) => {
      return client
        .get(`${constants.MAINTENANCE_PATH}/reminder/${maintenanceId}`)
        .then(values => {
          console.log('Got reminder for single maintenance', values);
          return Promise.resolve(values || { monthsBefore: null });
        })
        .catch(e => {
          dispatch(errorActions.errorMessage('Error fetching reminder.'));
          return Promise.reject(new Error(e));
        });
    }
  };
}

/**
 * Delete the maintenance reminder for specified maintenance.
 *
 * @param {string} maintenanceId
 */
export function deleteReminder(maintenanceId) {
  return {
    types: [DELETE_REMINDER, DELETE_REMINDER_SUCCESS, DELETE_REMINDER_FAIL],
    promise: (client, dispatch) => {
      return client
        .del(`${constants.MAINTENANCE_PATH}/reminder/${maintenanceId}`)
        .then(() => {
          dispatch(getReminder(maintenanceId));
        })
        .catch(e => {
          dispatch(errorActions.errorMessage('Error deleting reminder.'));
          return Promise.reject(new Error(e));
        });
    }
  };
}

/**
 * Get all maintenance reminders added by the user.
 */
export function getReminders() {
  return {
    types: [GET_REMINDERS, GET_REMINDERS_SUCCESS, GET_REMINDERS_FAIL],
    promise: (client, dispatch) => {
      return client
        .get(`${constants.MAINTENANCE_PATH}/reminder`)
        .then(values => {
          console.log('Got all reminders', values);
          return Promise.resolve(values);
        })
        .catch(e => {
          dispatch(errorActions.errorMessage('Error fetching all reminders.'));
          return Promise.reject(new Error(e));
        });
    }
  };
}
