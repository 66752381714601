import React from 'react';
import styled from 'styled-components';

const Sidebar = styled.div`
  width: 300px;
  margin-right: 1.5rem;
    background-color: rgb(72, 90, 100);
  padding: .5rem;
`;

export default ({ children }) => (
  <Sidebar>{children}</Sidebar>
)
