import { fetchTrain } from 'redux/modules/train';
import { fetchEstimatedMaintenancesForFleet, fetchProjectedMaintenances } from 'redux/modules/maintenance';
import * as updatedActions from 'redux/modules/updated';
import * as errorActions from './error';
import * as constants from './paths';

const LOAD = 'railway/fleet/LOAD';
const LOAD_SUCCESS = 'railway/fleet/LOAD_SUCCESS';
const LOAD_FAIL = 'railway/fleet/LOAD_FAIL';

const LOAD_FLEET = 'railway/fleet/LOAD_FLEET';
const LOAD_FLEET_SUCCESS = 'railway/fleet/LOAD_SUCCESS_FLEET';
const LOAD_FLEET_FAIL = 'railway/fleet/LOAD_FAIL_FLEET';

const LOAD_FLEET_TRAINS = 'railway/fleet/LOAD_FLEET_TRAINS';
const LOAD_FLEET_TRAINS_SUCCESS = 'railway/fleet/LOAD_SUCCESS_FLEET_TRAINS';
const LOAD_FLEET_TRAINS_FAIL = 'railway/fleet/LOAD_FAIL_FLEET_TRAINS';

const SAVE_FLEET = 'railway/fleet/SAVE_FLEET';
const SAVE_FLEET_SUCCESS = 'railway/fleet/SAVE_FLEET_SUCCESS';
const SAVE_FLEET_FAIL = 'railway/fleet/SAVE_FLEET_FAIL';

const initialState = {
  loaded: false,
  fleets: [],
  fleet: []
};

export default function fleet(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
        fleets: []
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        fleets: action.result
      };
    case LOAD_FAIL:
    case LOAD_FLEET_FAIL:
    case LOAD_FLEET_TRAINS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        fleet: [],
        fleets: []
      };
    case LOAD_FLEET:
      return {
        ...state,
        loading: true,
        loaded: false,
        fleets: []
      };
    case LOAD_FLEET_TRAINS:
      return {
        ...state,
        loading: true,
        loaded: false,
        fleet: []
      };
    case LOAD_FLEET_TRAINS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        fleet: action.result
      };
    case LOAD_FLEET_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        fleets: [...state.fleets.filter(f => f.id !== action.result.id), action.result]
      };

    default:
      return state;
  }
}

export function fetchFleets(operatorId) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject();
      }
      return client.get(`${constants.RAILWAY_PATH}/operators/${operatorId}/fleets`).catch(e => {
        dispatch(errorActions.errorMessage('Error fetching fleets.'));
        return Promise.reject(new Error(e));
      });
    }
  };
}

export function fetchFleet(fleetId) {
  return {
    types: [LOAD_FLEET, LOAD_FLEET_SUCCESS, LOAD_FLEET_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject();
      }
      return client.get(`${constants.RAILWAY_PATH}/fleets/${fleetId}`).catch(e => {
        dispatch(errorActions.errorMessage('Error fetching fleet.'));
        return Promise.reject(new Error(e));
      });
    }
  };
}

export function fetchFleetTrains(fleetId) {
  return {
    types: [LOAD_FLEET_TRAINS, LOAD_FLEET_TRAINS_SUCCESS, LOAD_FLEET_TRAINS_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject();
      }

      return client
        .get(`${constants.RAILWAY_PATH}/fleets/${fleetId}/trains?withBogies=true`)
        .then(res => {
          if (res[0]) {
            dispatch(fetchTrain(res[0].id));
          }
          return res;
        })
        .catch(e => {
          dispatch(errorActions.errorMessage('Error fetching fleet trains.'));
          return Promise.reject(new Error(e));
        });
    }
  };
}

export function editFleet(data) {
  const fleetId = data.id;
  return {
    types: [SAVE_FLEET, SAVE_FLEET_SUCCESS, SAVE_FLEET_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject();
      }
      return client
        .patch(`${constants.MUTATE_PATH}/fleet/${fleetId}`, { data })
        .then(res => {
          dispatch(fetchFleet(fleetId));
          return res;
        })
        .then(() => {
          dispatch(fetchEstimatedMaintenancesForFleet(fleetId));
        })
        .then(() => {
          dispatch(fetchProjectedMaintenances());
        })
        .then(() => {
          dispatch(updatedActions.fetchUpdatedForFleet(fleetId));
        })
        .catch(e => {
          dispatch(errorActions.errorMessage('Error editing fleet.'));
          return Promise.reject(new Error(e));
        });
    }
  };
}
