import React from 'react';
import styled from 'styled-components';

const TCell = styled.p`
  flex: 1;
`;

const TCellView = styled.div`
  flex: 1;
`;

export default ({ children, style, multiline }) => {
  if (multiline) {
    return <TCellView style={style}>{children}</TCellView>;
  }
  return <TCell style={style}>{children}</TCell>;
};
