import React from 'react';
import styled from 'styled-components';

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  height: 100%;
  flex: 1;
`;

const StyledContentContainerWithPadding = styled(StyledContentContainer)`
  padding-left: 20px;
`;

export interface Props {
  leftPadding: boolean;
}

const ContentContainer = (props: Props) => (props.leftPadding
  ? <StyledContentContainerWithPadding {...props} />
  : <StyledContentContainer {...props} />
);

export default ContentContainer;
