const REDIRECT = 'catalog/redirect/REDIRECT';
const REDIRECT_SUCCESS = 'catalog/redirect/REDIRECT_SUCCESS';

const initialState = {
  redirectTo: '',
};

export default function redirect(state = initialState, action = {}) {
  switch (action.type) {
    case REDIRECT:
      return {
        ...state,
        redirectTo: action.payload.address,
      };
    case REDIRECT_SUCCESS:
      return {
        ...state,
        redirectTo: '',
      };
    default:
      return state;
  }
}

export function redirectTo(address) {
  return {
    type: REDIRECT,
    payload: {
      address,
    },
  };
}

export function completeRedirect() {
  return {
    type: REDIRECT_SUCCESS,
    payload: {
    },
  };
}
