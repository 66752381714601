import React from 'react';
import styled from 'styled-components';
import { Button, TextInput } from 'dl-web-components';
import PropTypes from 'prop-types';
import DocumentUploaderContainer from 'container/DocumentUploaderContainer';
import colors from 'theme/variables/platform';

const { REACT_APP_DOCUMENT_DOMAIN } = process.env;

const Item = styled.div``;
const Label = styled.label``;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.altBackgroundColor};
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
`;

const Preview = styled.div`
  width: 128px;
  height: 128px;
  border: 1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
`;

const StyledText = styled.p`
  margin-bottom: 10px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

class Document extends React.Component {
  static propTypes = {
    fleetId: PropTypes.string.isRequired,
    id: PropTypes.string,
    filename: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    addedBy: PropTypes.string,
    newDocument: PropTypes.bool,
    dismiss: PropTypes.func,
    addDocument: PropTypes.func,
    deleteDocument: PropTypes.func.isRequired,
    saveDocument: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    const { title, description, newDocument } = props;
    this.state = newDocument
      ? {
        newDocument,
        editing: true,
        title: '',
        description: '',
        uploadDocument: null,
        confirmDialogVisible: false,
      }
      : {
        editing: false,
        confirmDialogVisible: false,
        title,
        description
      };

    this.handleChange = this.handleChange.bind(this);
    this.cancel = this.cancel.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.uploadComplete = this.uploadComplete.bind(this);
    this.openLink = this.openLink.bind(this);
  }

  openLink() {
    const file = `${REACT_APP_DOCUMENT_DOMAIN}/${this.props.filename}`;
    window.open(file, '_blank');
  }

  uploadComplete() {
    this.props.dismiss();
  }

  handleChange(key, value) {
    this.setState({ [key]: value });
  }

  cancel() {
    if (this.state.newDocument) {
      this.props.dismiss();
    } else {
      const { title, description } = this.props;
      this.setState({ title, description, editing: false });
    }
  }

  handleDelete() {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you wish to delete this document?')) {
      this.delete();
    }
  }

  delete() {
    const { id, fleetId } = this.props;
    this.setState(state => ({ ...state, deleting: true }));
    this.props.deleteDocument(id, fleetId);
  }

  save() {
    this.setState(state => ({ ...state, saving: true }));
    if (this.state.newDocument) {
      const { title, description, uploadDocument } = this.state;
      const { fleetId, addDocument } = this.props;
      addDocument(fleetId, { title, description }, uploadDocument, true); // true == web
    } else {
      const { fleetId, id } = this.props;
      const { title, description } = this.state;
      this.props.saveDocument(fleetId, id, { title, description });
      this.setState({ editing: false });
    }
  }

  render() {
    const { filename, addedBy, fleetId } = this.props;
    const {
      editing,
      newDocument,
      title,
      description,
      uploadDocument,
      saving,
      deleting,
    } = this.state;
    const web = true;
    return (
      <Container>
        <Preview>
          <p>{filename ? filename.slice(filename.lastIndexOf('.') + 1).toUpperCase() : '?'}</p>
        </Preview>
        <Right>
          {editing ? (
            <form style={{ marginBottom: 10 }}>
              <Item stackedLabel={!web} fixedLabel={web} style={{ marginLeft: 0 }}>
                <Label>Title</Label>
                <TextInput
                  value={title}
                  placeholder="Enter title here"
                  onChange={e => this.handleChange('title', e.target.value)}
                />
              </Item>

              <Item stackedLabel={!web} fixedLabel={web} style={{ minHeight: 75, marginLeft: 0 }}>
                <Label>Description</Label>

                <TextInput
                  placeholder="Enter description here"
                  multiline
                  value={description}
                  onChange={e => this.handleChange('description', e.target.value)}
                />
              </Item>

              {newDocument && (
                <Item stackedLabel={!web} fixedLabel={web} style={{ minHeight: web ? 75 : 0, marginLeft: 0 }}>
                  {web && <Label>File</Label>}
                  <DocumentUploaderContainer
                    fleetId={fleetId}
                    uploadDocument={callback => this.handleChange('uploadDocument', callback)}
                    uploadComplete={this.uploadComplete}
                  />
                </Item>
              )}
            </form>
          )
            :
            (
              <div>
                <h2>{title}</h2>
                <StyledText note>{addedBy}</StyledText>
                {description ? <StyledText>{description}</StyledText> : null}
              </div>
            )}
          {!editing && (
            <Buttons>
              <Button block style={{ flex: 1, marginRight: 20 }} onClick={() => this.openLink()}>
                <p>Open</p>
              </Button>
              <Button
                block
                style={{ flex: 1, backgroundColor: colors.btnInfoBg }}
                onClick={() => this.setState({ editing: true })}
              >
                <p>Edit</p>
              </Button>
              <Button
                block
                style={{ flex: 1, marginLeft: 20, backgroundColor: colors.btnDangerBg }}
                onClick={this.handleDelete}
                disabled={deleting}
              >
                <p>{deleting ? 'Deleting...' : 'Delete'}</p>
              </Button>
            </Buttons>
          )}
          {editing && (
            <Buttons>
              <Button
                block
                style={{ flex: 1, marginRight: 20, backgroundColor: colors.btnCancelBg }}
                onClick={this.cancel}
              >
                <p>Cancel</p>
              </Button>
              <Button
                block
                style={{ flex: 1, backgroundColor: colors.btnPrimaryBg }}
                disabled={(newDocument && !uploadDocument) || title === '' || saving}
                onClick={this.save}
              >
                <p>{saving ? 'Saving...' : 'Save'}</p>
              </Button>
            </Buttons>
          )}
        </Right>
      </Container>
    );
  }
}

export default Document;
