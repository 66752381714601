import React from 'react';
import S3Uploader from 'react-s3-uploader';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSignedUploadURL, refreshAfterUpload } from 'redux/modules/image';

/**
 * A wrapper component for the S3Uploader used in the WebUI for uploading images to S3.
 */
const UploaderContainer = ({ fleetId, getURL, refresh, loading, loaded }) => {
  if (loading) {
    return (
      <div>
        <p>Uploading image...</p>
      </div>
    );
  }
  if (loaded) {
    return (
      <div>
        <p>Processing upload...</p>
      </div>
    );
  }

  return (
    <S3Uploader
      getSignedUrl={(data, callback) => getURL(fleetId, callback)}
      onSignedUrl={console.log}
      onProgress={console.log}
      onError={console.log}
      onFinish={() => refresh(fleetId)}
      contentDisposition="auto"
      uploadRequestHeaders={{}}
      accept="image/jpeg"
    />
  );
};

const mapStateToProps = state => {
  const { loading, loaded } = state.imageReducer;
  return { loading, loaded };
};

const mapDispatchToProps = dispatch => ({
  getURL: (fleetId, callback) => dispatch(getSignedUploadURL(fleetId, callback)),
  refresh: fleetId => dispatch(refreshAfterUpload(fleetId))
});

UploaderContainer.propTypes = {
  fleetId: PropTypes.string,
  refresh: PropTypes.func,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  getURL: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploaderContainer);
