import * as React from 'react';
import { connect } from 'react-redux';
import { sortBy } from 'lodash';
import ProductsPage from 'stories/screens/ProductsPage';
import SearchBar from 'components/SearchBar';
import * as productActions from 'redux/modules/product';

export interface Props {
  navigation: any,
  fetchProducts: Function,
  productReducer: Object,
}

export interface State { }

class ProductsContainer extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = { productSearch: '' };
    this.searchRef = {};
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    if (this.props.authenticated && !this.props.productReducer.loading) {
      this.props.fetchProducts();
      this.props.fetchProductTypes();
    }
  }

  render() {
    const searchForm = (
      <SearchBar
        ref={instance => {
          this.searchRef = instance;
        }}
        setSearch={(val => this.setState({ productSearch: val }))}
      />
    );

    if (this.props.productReducer.loadedType !== '' && this.props.productReducer.loadedType !== 'bearing') {
      return (<ProductsPage loading={true} products={[]} productTypes={[]} />);
    }

    let products = this.props.productReducer.products.filter(o => {
      return o.name.toUpperCase().indexOf(this.state.productSearch.toUpperCase()) !== -1;
    });

    products = sortBy(products, ['description']);

    const { productTypes } = this.props.productReducer;

    return (
      <ProductsPage
        loading={this.props.productReducer.loading}
        products={products}
        productTypes={productTypes}
        searchForm={searchForm}
        focusSearch={this.searchRef.clearAndFocusSearch}
        searchString={this.state.productSearch}
      />
    );
  }
}

function bindAction(dispatch) {
  return {
    fetchProducts: () => dispatch(productActions.fetchProducts()),
    fetchProductTypes: () => dispatch(productActions.fetchProductTypes()),
  };
}

const mapStateToProps = state => {
  return {
    productReducer: state.productReducer || {},
    authenticated: state.authReducer.authenticated,
    products: state.productReducer.products,
  };
};

export default connect(mapStateToProps, bindAction)(ProductsContainer);
