import React, { PureComponent } from 'react';
import {
  Loader as Spinner,
} from 'dl-web-components';
import { sprintf } from 'sprintf-js';
import styled from 'styled-components';
import Heading from 'components/Heading';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import SectionList from 'react-sectionlist';
import Menu from 'container/Menu';

const ListItem = styled(Link)``;
const Body = styled.div``;
const Left = styled.div``;
const Right = styled.div``;
const Container = styled.div``;
const Header = styled.div``;
const Title = styled.h2``;

const StyledContent = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
`;

const ContentContainer = styled.div`
  flex: 1;
  padding-left: 20px;
`;

const SectionHeading = styled.div`
  width: 100%;
  background-color: #FBFAFA;
  border-bottom-width: 1px;
  border-bottom-color: #0f58d6;
`;

const resultKeyMap = {
  Operator: {
    link: '/operators/%(id)s',
    title: 'Operators',
    propsKey: 'operator',
  },
  Fleet: {
    title: 'Fleets',
    link: '/operators/%(operator.id)s/fleets/%(id)s',
    propsKey: 'fleet',
    notes: '%(trainClass.Oems[0].name)s %(trainClass.family)s operated by %(operator.name)s',
  },
  FleetsByOwner: {
    title: 'Fleets by Owner',
    link: '/operators/%(operator.id)s/fleets/%(id)s',
    propsKey: 'fleet',
    notes: '%(trainClass.Oems[0].name)s %(trainClass.family)s operated by %(operator.name)s',
  },
  TrainClass: {
    link: '/operators/%(operator.id)s/fleets/%(id)s',
    propsKey: 'fleet',
    title: 'Fleets by OEM',
    notes: '%(trainClass.Oems[0].name)s %(trainClass.family)s operated by %(operator.name)s',
  },
  TrainClassByOem: {
    link: '/operators/%(fleet.operator.id)s/fleets/%(id)s',
    propsKey: 'fleet',
    title: 'OEM Vehicle Platform',
    notes: '%(Oems[0].name)s %(family)s operated by %(fleet.operator.name)s',
  },
  Oem: {
    link: '/oems/%(id)s',
    title: 'OEMs',
    propsKey: 'oem',
  },
  BogieClass: {
    link: '/oems/%(oem.id)s/components/bogieClass/%(id)s',
    propsKey: 'component',
    title: 'Bogies',
    extraProps: {
      type: 'bogieClass',
    },
    notes: '%(type)s bogie'
  },
  BogieClassByOem: {
    link: '/oems/%(oem.id)s/components/bogieClass/%(id)s',
    propsKey: 'component',
    title: 'Bogies',
    extraProps: {
      type: 'bogieClass',
    },
    notes: '%(type)s bogie',
  },
  BearingClass: {
    link: '/products/%(id)s',
    title: 'Products',
    propsKey: 'product',
  },
  MotorClass: {
    link: '/oems/%(oem.id)s/components/motorClass/%(id)s',
    propsKey: 'component',
    title: 'Motors',
    extraProps: {
      type: 'motorClass',
    },
    notes: '%(oem.name)s',
  },
  MotorClassByOem: {
    link: '/oems/%(oem.id)s/components/motorClass/%(id)s',
    propsKey: 'component',
    title: 'Traction Motors',
    extraProps: {
      type: 'motorClass',
    },
    notes: '%(oem.name)s',
  },
  GearboxClass: {
    link: '/oems/%(oem.id)s/components/gearboxClass/%(id)s',
    propsKey: 'component',
    title: 'Gearboxes',
    extraProps: {
      type: 'gearboxClass',
    },
    notes: '%(oem.name)s',
  },
  GearboxClassByOem: {
    link: '/oems/%(oem.id)s/components/gearboxClass/%(id)s',
    propsKey: 'component',
    title: 'Gearboxes by OEM',
    extraProps: {
      type: 'gearboxClass',
    },
    notes: '%(oem.name)s',
  },
};

class SearchPage extends PureComponent {
  constructor(props) {
    super(props);

    this.renderItem.bind(this);
    this.state = {
      expanded: '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!Object.is(this.props.searchResult, nextProps.searchResult)) {
      this.setState({ expanded: '' });
    }
  }

  renderItem(item) {
    console.log(item);
    const title = item.sectionTitle;
    if (item.showMore) { // Special "Show more" list item
      return (
        <ListItem
          onClick={() => {
            this.setState({ expanded: item.prop });
          }}
        >
          <p>{item.name}</p>
        </ListItem>
      );
    }

    let props = item;

    if (title === 'TrainClassByOem') {
      props = item.fleet;
    }

    return !props ? null : (
      <ListItem
        to={`${sprintf(resultKeyMap[title].link, item)}`}
      // onClick={() => {
      //   go(navigator, resultKeyMap[title].page, {
      //     [resultKeyMap[title].propsKey]: props,
      //     ...resultKeyMap[title].extraProps
      //   });
      // }}
      >
        <p >{item.name || item.designation}
        </p>
        {resultKeyMap[title] && !!resultKeyMap[title].notes && (
          <p note>{sprintf(resultKeyMap[title].notes, item)}</p>
        )}
      </ListItem>
    );
  }


  render() {
    const sectionData = Object.keys(this.props.searchResult).map(prop => {
      let data = this.props.searchResult[prop].sort((a, b) => {
        [a, b] = [a, b].map(item => item[item.name ? 'name' : 'designation'].toLowerCase());
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });

      if (this.state.expanded !== prop && data.length > 7) {
        data = data.slice(0, 6);
        const sectionName = resultKeyMap[prop] ? resultKeyMap[prop].title : prop;
        data.push({
          name: `Show all ${sectionName.toLowerCase()} (${this.props.searchResult[prop].length})`,
          prop,
          showMore: true,
        });
      }

      // Fill in necessary fields so that sprintf won't crash on non-existing data
      data = data.map(val => {
        if (val.Oems && !val.Oems.length) {
          val.Oems = [{ name: '' }];
        }
        if (val.trainClass && !val.trainClass.Oems.length) {
          val.trainClass.Oems = [{ name: '(missing OEM)' }];
        }
        if (!val.oem) {
          val.oem = { name: '---' };
        }
        if (val.trainClass && val.trainClass.family === null) {
          val.trainClass.family = '(missing vehicle family)';
        }

        val.sectionTitle = prop;
        return val;
      });


      return {
        data,
        title: prop, // TODO: This can probably be removed & refactored to sectionTitle
      };
    }).filter(n => n.data.length);

    const hasResults =
      Object
        .keys(this.props.searchResult)
        .reduce((v, e) => !!this.props.searchResult[e].length || v, false);

    return (
      <Container style={{ width: '1400px', margin: 'auto', marginTop: 0, maxWidth: '97%' }}>
        <Menu />

        <Header>
          <Left />
          <Body>
            <Title>Search</Title>
          </Body>
          <Right />
        </Header>
        <StyledContent>
          <ContentContainer>
            {this.props.searchForm}

            {this.props.loading && (
              <div><Spinner style={{ width: '100%', height: 20, alignItems: 'center' }} /></div>
            )}

            {!this.props.loading && !!Object.keys(this.props.searchResult).length && !hasResults && (
              <Heading>No results</Heading>
            )}

            <SectionList
              sections={sectionData}
              renderSectionHeader={section => (
                <SectionHeading>
                  <Heading>{resultKeyMap[section.title] ? resultKeyMap[section.title].title : section.title}</Heading>
                </SectionHeading>)}
              renderItem={item => this.renderItem(item)}
              keyExtractor={item => item.id}
            />
          </ContentContainer>
        </StyledContent>
      </Container>
    );
  }
}

SearchPage.propTypes = {
  navigation: PropTypes.any,
  searchResult: PropTypes.object,
  searchForm: PropTypes.any,
  loading: PropTypes.any,
};

export default SearchPage;
