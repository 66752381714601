import React from 'react';
import styled from 'styled-components';

const TRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: nowrap;
`;

export default ({ children, width }) => (
  <TRow>{children}</TRow>
);
