import * as constants from './paths';
import * as errorActions from './error';

const LOAD = 'railway/operator/LOAD';
const LOAD_SUCCESS = 'railway/operator/LOAD_SUCCESS';
const LOAD_FAIL = 'railway/operator/LOAD_FAIL';

const initialState = {
  loaded: false,
  loading: false,
  operators: []
};

export default function operator(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        operators: action.result || []
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    default:
      return state;
  }
}

export function fetchOperators() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject(new Error('Not authenticated'));
      }

      return client.get(`${constants.RAILWAY_PATH}/operators`).catch(e => {
        dispatch(errorActions.errorMessage('Error fetching operators.'));
        return Promise.reject(new Error(e));
      });
    }
  };
}
