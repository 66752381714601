import React, { PureComponent } from 'react';
import { Modal, Loader as Spinner, FlexGrid, FlexCell, Button } from 'dl-web-components';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { round } from 'lodash';
import SidebarListItem from 'components/SidebarListItem';
import TrainBogie from 'components/TrainBogie';
import TrainBogieItem from 'components/TrainBogieItem';
import TrainBogieWheelsetItem from 'components/TrainBogieWheelsetItem';
import Sidebar from 'components/Sidebar';
import Heading from 'components/Heading';
import TRow from 'components/TRow';
import TCell from 'components/TCell';
import EditFleetForm from 'container/EditFleetForm';
import UploaderContainer from 'container/UploaderContainer';
import CHANGE_TYPES from 'helpers/updated';
import { kmToMiles, speedUnit } from 'helpers/units';
import { exists } from 'helpers/validation';
import FavoriteIcon from 'components/FavoriteIcon';
import Menu from 'container/Menu';

import styles from './styles';

const { REACT_APP_IMAGE_DOMAIN } = process.env;

const StyledContent = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
`;

const Content = styled.div``;
const List = styled.ul``;
const ListItem = styled.a``;
const Right = styled.div``;
const Container = styled.div``;
const Header = styled.div``;

const openImage = (hiresURI: string) => {
  if (!hiresURI) {
    return;
  }

  window.open(hiresURI, '_blank');
};

const TrainImage = ({ thumbnailURI, hiresURI, fleetId }) => {
  const imageStyle = { width: 150, height: 150, margin: 15, marginLeft: 0 };
  return (
    <div>
      <img style={imageStyle} alt="" src={thumbnailURI} onClick={() => openImage(hiresURI)} />
      <br />
      <UploaderContainer fleetId={fleetId} />
    </div>
  );

};

TrainImage.propTypes = {
  thumbnailURI: PropTypes.string,
  hiresURI: PropTypes.string,
  fleetId: PropTypes.string
};

class FleetOverviewPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { showInfo: false, favorite: props.favorite, modalOpen: false, };
    this.renderMaintenanceRow.bind(this);
  }

  bogiesByType(type) {
    if (!this.props.train.bogies) {
      return [{}];
    }
    return this.props.train.bogies.filter(bogie => {
      if (!bogie.bogieClass && type === 'unknown') {
        return true;
      }
      if (bogie.bogieClass && bogie.bogieClass.type === type.toUpperCase()) {
        return true;
      }
      return false;
    });
  }

  itemsByType(type) {
    if (!this.props.train.bogies) {
      return false;
    }
    return this.props.train.bogies.filter(bogie => {
      return !!bogie[type].length;
    });
  }

  scrollTo(element) {
    this.scroll.props.scrollIntoView(element, {});
  }

  renderMaintenanceRow(item, index) {
    const isPlural = item.count > 1;
    return (
      <ListItem button key={index} style={{ marginLeft: 0 }} onClick={() => this.props.switchPage('maintenance')}>
        <p style={{ marginLeft: 0, paddingBottom: 10 }} >
          <p style={{ color: '#007AFF' }}>{item.year}</p>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <p>
            {item.count} maintenance
            {isPlural ? 's' : ''}
            {/* for {item.vehicles} vehicles */}
          </p>
        </p>
      </ListItem>
    );
  }

  renderUpdateRow(change) {
    return (
      <TRow key={change.type}>
        <TCell>{CHANGE_TYPES[change.type]}</TCell>
        <TCell>{change.date}</TCell>
        <TCell style={{ flexGrow: 2 }}>{change.user}</TCell>
      </TRow>
    );
  }

  render() {
    const { fleet, trains, train, loaded, updated, distanceUnit } = this.props;
    const { modalOpen } = this.state;

    const cacheInvalidation = Math.floor(Math.random() * 16777215).toString(16);
    const [thumbnailURI, hiresURI] = fleet.imageIdentifier
      ? [
        `${REACT_APP_IMAGE_DOMAIN}/${fleet.imageIdentifier}.jpg?cacheInvalidation=${cacheInvalidation}`,
        `${REACT_APP_IMAGE_DOMAIN}/hires/${fleet.imageIdentifier}.jpg?cacheInvalidation=${cacheInvalidation}`
      ]
      : [null, null];

    const [fleetTrain] = trains || [];

    const motorBogies = this.bogiesByType('motor');
    const motorBogie = motorBogies[0] || {};
    motorBogie.amountPerVehicle = motorBogies.length;

    const trailerBogies = this.bogiesByType('trailer');
    const trailerBogie = trailerBogies[0] || {};
    trailerBogie.amountPerVehicle = trailerBogies.length;

    const unknownBogies = this.bogiesByType('unknown');
    const unknownBogie = unknownBogies[0] || {};
    unknownBogie.amountPerVehicle = unknownBogies.length;

    const motors = this.itemsByType('motors');
    // const generators = this.itemsByType('generators');
    const gearboxes = this.itemsByType('gearboxes');
    // const bearings = this.itemsByType('Bearings');
    const wheelsetBearings = this.itemsByType('axles');

    const isFavorite = exists(this.state.favorite) && !!this.state.favorite;

    const ViewContent = !fleet.operator || !train.id || !loaded || !fleetTrain ? (
      <Content>
        <Spinner style={{ width: '100%', height: 200, justifyContent: 'flex-end', alignItems: 'center' }} />
      </Content>
    ) :
      (
        <div>
          <div>
            <FlexGrid>
              <FlexCell span={{ l: 25, m: 25 }}>
                <TrainImage
                  thumbnailURI={thumbnailURI}
                  hiresURI={hiresURI}
                  fleetId={fleet.id}
                  getUploadURL={this.props.getSignedUploadURL}
                />
              </FlexCell>

              <FlexCell span={{ l: 60, m: 60 }}>
                <div>
                  <Heading>
                    {fleet.name}
                    <FavoriteIcon
                      favorite={isFavorite}
                      onClick={() => {
                        this.setState({ favorite: !this.props.favorite });
                        this.props.toggleFavorite(fleet.id, this.props.favorite);
                      }}
                    />
                  </Heading>


                  Operated by
                  <Link to={`/operators/${fleet.operator && fleet.operator.id}`}>
                    {fleet.operator && fleet.operator.name}
                  </Link>
                  <p>
                    {trains[0].fleet.trainCount} vehicles
                  </p>
                  <p>
                    {train.trainClass.numberOfUnits}
                    -car {fleetTrain.trainClass.vehicleType.name}
                  </p>
                  <p>
                    {train.trainClass.Oems.length > 0 ? train.trainClass.Oems[0].name : '(missing OEM)'}{' '}
                    {fleetTrain.trainClass.family ? fleetTrain.trainClass.family : '(missing vehicle family)'}
                    {'\n'}
                  </p>
                  <p>
                    {fleet.description}
                    {'\n'}
                  </p>

                  <span style={{ color: '#007AFF' }} onClick={() => this.setState({ showInfo: !this.state.showInfo })}>
                    {this.state.showInfo ? 'Hide vehicle information' : 'Show vehicle information'}
                  </span>
                </div>
              </FlexCell>
            </FlexGrid>
          </div>

          <List>
            <ListItem />
            {this.state.showInfo && (
              <div>
                <Heading>Vehicle information</Heading>

                <TRow>
                  <TCell multiline>
                    <div>
                      <p>
                        Country of operation
                      </p>
                    </div>
                    <p>{train.fleet && train.fleet.country ? train.fleet.country.name : ''}</p>
                  </TCell>
                  <TCell multiline>
                    <div>
                      <p>
                        Owner
                      </p>
                    </div>
                    <p>{train.fleet && train.fleet.owner ? train.fleet.owner.name : ''}</p>
                  </TCell>
                  <TCell multiline>
                    <div>
                      <p>
                        Service started
                      </p>
                    </div>
                    <p>
                      {train.fleet && train.fleet.serviceStartedYear ? train.fleet.serviceStartedYear : '---'}
                    </p>
                  </TCell>
                </TRow>

                <TRow>
                  <TCell multiline>
                    <div>
                      <p>
                        Max speed
                      </p>
                    </div>
                    <p>
                      {round(kmToMiles[distanceUnit](fleet.trainClass.maxSpeed)) || '---'} {speedUnit[distanceUnit]}
                    </p>
                  </TCell>
                  <TCell multiline>
                    <div>
                      <p>
                        Transport authority
                      </p>
                    </div>
                    <p>{fleet.transportAuthority || '---'}</p>
                  </TCell>
                  <TCell multiline>
                    <div>
                      <p>
                        Transport state
                      </p>
                    </div>
                    <p>{fleet.transportState || '---'}</p>
                  </TCell>
                </TRow>

                <TRow>
                  <TCell multiline>
                    <div>
                      <p>
                        Franchise name
                      </p>
                    </div>
                    <p>{fleet.franchiseDescription || '---'}</p>
                  </TCell>
                  <TCell multiline>
                    <div>
                      <p>
                        Franchise start
                      </p>
                    </div>
                    <p>
                      {fleet.franchiseStartDate
                        ? new Date(fleet.franchiseStartDate).toLocaleDateString('de-de')
                        : '---'}
                    </p>
                  </TCell>
                  <TCell multiline>
                    <div>
                      <p>
                        Franchise end
                      </p>
                    </div>

                    <p>
                      {fleet.franchiseEndDate ? new Date(fleet.franchiseEndDate).toLocaleDateString('de-de') : '---'}
                    </p>
                  </TCell>
                </TRow>

                <ListItem />
              </div>
            )}

            <Heading>Bogies ({train.bogies.length})</Heading>

            <div>
              <Heading>Trailer bogies</Heading>
              <TrainBogie name="Trailer bogies" bogie={trailerBogie} type="TRAILER" fleet={fleet} />
              <TrainBogieWheelsetItem
                name="Wheelset"
                type="axles"
                bogieType="Trailer"
                bogies={wheelsetBearings}
                fleet={fleet}
              />
            </div>

            <div>
              <Heading>Motor bogies</Heading>
              <TrainBogie name="Motor bogies" bogie={motorBogie} type="MOTOR" fleet={fleet} />
              <TrainBogieWheelsetItem
                name="Wheelset"
                type="axles"
                bogieType="Motor"
                bogies={wheelsetBearings}
                fleet={fleet}
              />
            </div>

            {!!unknownBogies.length && (
              <TrainBogie name="Unknown bogies" bogie={unknownBogie} fleet={fleet} />
            )}
            <TrainBogieItem
              name="Gearbox"
              type="gearboxes"
              unknown={unknownBogie}
              bogies={gearboxes}
              fleet={fleet}
            />
            <TrainBogieItem
              name="Electric motor"
              type="motors"
              unknown={unknownBogie}
              bogies={motors}
              fleet={fleet}
            />
          </List>
          <div>
            <Heading>Last updated</Heading>
            <TRow>
              <TCell>Information</TCell>
              <TCell>Date</TCell>
              <TCell style={{ flexGrow: 2 }}>User</TCell>
            </TRow>
            {updated.map(this.renderUpdateRow)}
          </div>
        </div>
      );

    return (
      <Container style={styles.container}>
        <Menu />

        <Header>
          <Right>
            <Button icon="cog" variant="plain" onClick={() => this.setState({ modalOpen: !modalOpen })}>Edit</Button>
            {modalOpen && (
              <Modal style={{ maxHeight: '90%' }} toggleModal={() => this.setState({ modalOpen: !modalOpen })}>
                <EditFleetForm data={fleet} toggleModal={() => this.setState({ modalOpen: !modalOpen })} />
              </Modal>
            )}
          </Right>
        </Header>

        <StyledContent>
          <Sidebar>
            <List>
              <SidebarListItem onClick={() => this.props.switchPage('')}>
                <p style={{ color: '#007AFF' }}>
                  Overview
                </p>
              </SidebarListItem>
              <SidebarListItem onClick={() => this.props.switchPage('maintenance')}>
                <p >Maintenance</p>
              </SidebarListItem>
              <SidebarListItem onClick={() => this.props.switchPage('documents')}>
                <p >Documents</p>
              </SidebarListItem>
            </List>
          </Sidebar>
          {ViewContent}
        </StyledContent>
      </Container>
    );
  }
}
FleetOverviewPage.propTypes = {
  distanceUnit: PropTypes.string,
  favorite: PropTypes.any,
  train: PropTypes.object,
  switchPage: PropTypes.func,
  fleet: PropTypes.object,
  trains: PropTypes.array,
  loaded: PropTypes.bool,
  toggleFavorite: PropTypes.func,
  updated: PropTypes.array,
  cacheInvalidation: PropTypes.number,
  getSignedUploadURL: PropTypes.func
};

export default FleetOverviewPage;
