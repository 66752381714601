import * as React from 'react';
import {
  Icon,
  Loader as Spinner
} from 'dl-web-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import TRow from 'components/TRow';
import TCell from 'components/TCell';
import DGBB from 'assets/DGBB.png';
import TMBU from 'assets/TMBU.png';
import CRU from 'assets/CRU.png';
import ACBB from 'assets/ACBB.png';
import SRB from 'assets/SRB.png';
import CRB from 'assets/CRB.png';
import TRB from 'assets/TRB.png';
import Insocoat from 'assets/Insocoat.png';
import TBU from 'assets/TBU.png';
import Menu from 'container/Menu';

import styles from './styles';

const Body = styled.div``;
const Right = styled.div``;
const Container = styled.div``;
const Header = styled.div``;
const Title = styled.h2``;

const StyledContent = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
`;

const Heading = styled.p`
  font-size: 20;
  font-weight: bold;
  padding: 15px;
  color: #485a64;
`;

const GCell = styled(TCell)`
  color: gray;
`;

const ContentContainer = styled.div`
  flex: 1;
`;

const Br = styled.hr`
  background-color: transparent;
  border-bottom-width: 1px;
  border-bottom-color: lightgray;
`;

const StyledList = styled.ul`
  background-color: #f1f1f1;
  margin: 10px;
`;

const StyledListItem = styled(Link)`
  border-color: gray;
  padding: 10px;
`;

const ListText = styled.p`
  padding-bottom: 8px;
`;

const productImage = item => {
  const type = item.bearingType ? item.bearingType.name : item.bearingTypeId;
  switch (type) {
    case 'DGBB':
      return DGBB;
    case 'TMBU':
      return TMBU;
    case 'CRU':
      return CRU;
    case 'ACBB':
      return ACBB;
    case 'SRB':
      return SRB;
    case 'CRB':
      return CRB;
    case 'TRB':
      return TRB;
    case 'TBU':
      return TBU;
    case 'Insocoat':
      return Insocoat;
    default:
      return null;
  }
};

class ProductPage extends React.PureComponent {
  render() {
    const { loading, loadedVehicles, product, vehicles } = this.props;

    const byDesignation = (a, b) => {
      if (a.designation.toLowerCase() < b.designation.toLowerCase()) {
        return -1;
      }
      if (a.designation.toLowerCase() > b.designation.toLowerCase()) {
        return 1;
      }
      return 0;
    };

    console.log(product);

    let ViewContent = {};

    if (loading || !product[0]) {
      ViewContent = (
        <div>
          <Spinner style={{ width: '100%', height: 200, justifyContent: 'flex-end', alignItems: 'center' }} />
        </div>
      );
    } else {
      ViewContent = (
        <div>
          {product.map((item, i) => (
            <div key={i}>
              <div style={{ flexDirection: 'row' }}>
                <Heading>{item ? item.name : ''}</Heading>
              </div>
              <div style={{ flexDirection: 'row', padding: 20 }}>
                <div style={{ flexDirection: 'column', paddingRight: 15 }}>
                  <img
                    src={productImage(item)}
                    style={{ flex: 1, height: undefined, width: undefined, margin: 15, marginLeft: 0 }}
                    resizeMode="contain"
                    alt=""
                  />
                  {productImage(item) !== null ? (
                    <p style={{ fontSize: 10, textAlign: 'center' }}>
                      Generic picture to represent bearing type and not final designation.
                    </p>
                  ) : (
                      undefined
                    )}
                </div>
                <div style={{ flexGrow: 1 }}>
                  <TRow>
                    <GCell>Type</GCell>
                    <TCell>{item.bearingType ? item.bearingType.name : item.bearingTypeId}</TCell>
                  </TRow>
                  <TRow>
                    <GCell>Width</GCell>
                    <TCell>{item.width}</TCell>
                  </TRow>
                  <TRow>
                    <GCell>Inner diameter</GCell>
                    <TCell>{item.innerDiameter}</TCell>
                  </TRow>
                  <TRow>
                    <GCell>Outer diameter</GCell>
                    <TCell>{item.outerDiameter}</TCell>
                  </TRow>
                  <TRow>
                    <GCell>Weight</GCell>
                    <TCell>{item.weight}</TCell>
                  </TRow>
                  <TRow>
                    <GCell>OEM</GCell>
                    <TCell>{item.oem ? item.oem.name : ''}</TCell>
                  </TRow>
                </div>
              </div>
            </div>
          ))}

          <Br />

          <div style={{ flexDirection: 'row' }}>
            <div style={{ flex: 1 }}>
              <Heading>Used in vehicles</Heading>
              {!loadedVehicles && (
                <div>
                  <Spinner style={{ width: '100%', height: 20, justifyContent: 'flex-end', alignItems: 'center' }} />
                </div>
              )}

              {loadedVehicles && vehicles.length === 0 && (
                <div>None</div>
              )}

              <StyledList>
                {vehicles.sort(byDesignation).map((item, i) =>
                  item.fleet && (
                    <StyledListItem
                      key={i}
                      to={`/operators/${item.fleet.operator.id}/fleets/${item.fleet.id}`}
                    >
                      <Body>
                        <ListText >
                          {item.designation}
                        </ListText>
                        <ListText note>
                          {item.fleet.operator.name}
                        </ListText>
                      </Body>
                      <Right>
                        <Icon name="arrow-forward" />
                      </Right>
                    </StyledListItem>
                  ))}
              </StyledList>
            </div>
          </div>
        </div>
      );
    }

    return (
      <Container style={styles.container}>
        <Menu />
        <Header>

          <Body style={{ flex: 3, alignItems: 'center' }}>
            <Title>
              {product[0] && product[0].oem ? product[0].oem.name : ''}
            </Title>
          </Body>

          <Right />
        </Header>
        <StyledContent>
          <ContentContainer>{ViewContent}</ContentContainer>
        </StyledContent>
      </Container>
    );
  }
}

export default ProductPage;
