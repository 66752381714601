import React, { Component } from 'react';
import { Modal, Button, Loader as Spinner } from 'dl-web-components';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TRow from 'components/TRow';
import TCell from 'components/TCell';
import SidebarListItem from 'components/SidebarListItem';
import Sidebar from 'components/Sidebar';
import Heading from 'components/Heading';
import AddMaintenanceForm from 'container/AddMaintenanceForm';
import EditFleetForm from 'container/EditFleetForm';
import FleetMaintenanceBox from 'components/FleetMaintenanceBox';
import { kmToMiles, accuracy, roundingLimit } from 'helpers/units';
import { conditionalRound } from 'helpers/data-structures';
import { exists } from 'helpers/validation';
import FavoriteIcon from 'components/FavoriteIcon';
import Menu from 'container/Menu';
import styles from './styles';

const List = styled.ul``;
const ListItem = styled.a``;
const Right = styled.div``;
const Container = styled.div``;
const Header = styled.div``;

const StyledContent = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
`;

const ContentContainer = styled.div`
  flex: 1;
  height: 100%;
`;

class FleetMaintenancePage extends Component {
  static propTypes = {
    favorite: PropTypes.object,
    fleet: PropTypes.object,
    trains: PropTypes.array,
    train: PropTypes.object,
    existingMaintenances: PropTypes.array,
    upcomingMaintenances: PropTypes.array,
    distanceUnit: PropTypes.string,
    navigation: PropTypes.object,
    switchPage: PropTypes.func,
    toggleFavorite: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      favorite: props.favorite,
      modalOpen: false,
      maintenanceModalOpen: false,
    };
  }

  render() {
    const { fleet, trains, train, existingMaintenances, upcomingMaintenances, distanceUnit } = this.props;
    const { modalOpen, maintenanceModalOpen } = this.state;

    const [fleetTrain] = trains || [{}];

    if (!fleet.operator || !train.id || !fleetTrain) {
      // Is this error?
      return (
        <div>
          <Spinner style={{ width: '100%', height: 20, alignItems: 'center' }} />
        </div>
      );
    }
    /* console.log('past:');
    console.log(existingMaintenances);
    console.log('future:');
    console.log(upcomingMaintenances); */

    const isFavorite = exists(this.state.favorite) && !!this.state.favorite;

    return (
      <Container style={styles.container}>
        <Menu />

        <Header>

          {/* <Body style={{ flex: 3, alignItems: 'center' }}>
            <Title onClick={() => this.props.navigation.goBack()}>{fleet.operator.name}</Title>
          </Body> */}

          <Right>
            <Button icon="cog" variant="plain" onClick={() => this.setState({ modalOpen: !modalOpen })}>Edit</Button>
            {modalOpen && (
              <Modal style={{ maxHeight: '90%' }} toggleModal={() => this.setState({ modalOpen: !modalOpen })}>
                <EditFleetForm data={fleet} toggleModal={() => this.setState({ modalOpen: !modalOpen })} />
              </Modal>
            )}

            {/* <Modal button="Edit" linkColor="white">
              <EditFleetForm data={fleet} />
            </Modal> */}
          </Right>
        </Header>

        <StyledContent>
          <Sidebar>
            <List>
              <SidebarListItem onClick={() => this.props.switchPage('')}>
                <p>Overview</p>
              </SidebarListItem>
              <SidebarListItem onClick={() => this.props.switchPage('maintenance')}>
                <p style={{ color: '#007AFF' }}>
                  Maintenance
                </p>
              </SidebarListItem>
              <SidebarListItem onClick={() => this.props.switchPage('documents')}>
                <p>Documents</p>
              </SidebarListItem>
            </List>
          </Sidebar>
          <ContentContainer>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <div>
                <Heading>
                  {fleet.name}
                  <FavoriteIcon
                    favorite={isFavorite}
                    onClick={() => {
                      this.setState({ favorite: !this.props.favorite });
                      this.props.toggleFavorite(fleet.id, this.props.favorite);
                    }}
                  />
                </Heading>
              </div>
            </div>

            {!fleetTrain ? (
              <div>
                <Spinner style={{ width: '100%', height: 20, alignItems: 'center' }} />
              </div>
            )
              :
              (
                <div>
                  <p>{fleet.trainCount} vehicles</p>
                  <p>{fleetTrain.trainClass.vehicleType.name}</p>
                </div>
              )}

            <List>
              <ListItem />
              <Heading>Maintenance information</Heading>
              <TRow>
                <TCell multiline>
                  <div>
                    <p>
                      Maintenance interval
                    </p>
                  </div>
                  <p>
                    {conditionalRound(
                      kmToMiles[distanceUnit](fleet.trainClass.maintenanceIntervalKm),
                      accuracy[distanceUnit],
                      roundingLimit
                    ) || '--'}
                    {' '}
                    {distanceUnit}
                  </p>
                </TCell>
                <TCell multiline>
                  <div>
                    <p>
                      Max. period between maintenances
                    </p>
                  </div>
                  <p>
                    {fleet.trainClass.maintenanceIntervalYears || '--'} years
                  </p>
                </TCell>
              </TRow>
              <TRow>
                <TCell multiline>
                  <div>
                    <p>
                      Annual mileage
                    </p>
                  </div>
                  <p>
                    {conditionalRound(
                      kmToMiles[distanceUnit](fleet.trainClass.mileagePerYear),
                      accuracy[distanceUnit],
                      roundingLimit
                    ) || '--'}
                    {' '}
                    {distanceUnit}
                  </p>
                </TCell>
                {/* <TCell multiline>
                  <div>
                    <p note>Estimated maintenance interval (years)</p>
                  </div>
                  <p>{fleet.trainClass.maintenanceIntervalKm / fleet.trainClass.mileagePerYear || '--'}</p>
                </TCell> */}
              </TRow>
              <TRow>
                <TCell multiline>
                  <div>
                    <p>
                      Maintenance company
                    </p>
                  </div>
                  <p>{fleet.maintenanceCompany || '--'}</p>
                </TCell>
                <TCell multiline>
                  <div>
                    <p>
                      Maintenance location
                    </p>
                  </div>
                  <p>{fleet.maintenanceLocation || '--'}</p>
                </TCell>
              </TRow>
              <ListItem />
            </List>

            <div style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <div>
                <Heading>Upcoming maintenances</Heading>
              </div>
              <div>
                <Button icon="cog" variant="plain" onClick={() => this.setState({ maintenanceModalOpen: !maintenanceModalOpen })}>Add maintenance</Button>
                {maintenanceModalOpen && (
                  <Modal style={{ maxHeight: '90%' }} toggleModal={() => this.setState({ maintenanceModalOpen: !maintenanceModalOpen })}>
                    <AddMaintenanceForm toggleModal={() => this.setState({ maintenanceModalOpen: !maintenanceModalOpen })} />
                  </Modal>
                )}
              </div>
            </div>

            {!upcomingMaintenances.length && !existingMaintenances.length && <p>No upcoming maintenances</p>}

            {upcomingMaintenances
              .map((item, index) => <FleetMaintenanceBox key={index} maintenance={item} predicted />)
              .reverse()}

            {existingMaintenances.map(item => (
              <FleetMaintenanceBox maintenance={item} key={item.id} userInput />
            ))}
          </ContentContainer>
        </StyledContent>
      </Container>
    );
  }
}

export default FleetMaintenancePage;
