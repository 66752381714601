import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ProductPage from 'stories/screens/ProductPage';
import * as productActions from 'redux/modules/product';

export interface Props {
  navigation: any;
  fetchProduct: Function;
  fetchProductTypes: Function;
  fetchRelatedVehicles: Function;
  fetchRelatedOperators: Function;
  product: Object;
  productTypes: Object;
  vehicles: Object;
  operators: Object;
}

export interface State { }
class ProductPageContainer extends PureComponent<Props, State> {
  componentDidMount() {
    console.log(this.props);
    if (this.props.match.params.id) {
      const productId = this.props.match.params.id;
      this.props.fetchProduct(productId);
      this.props.fetchRelatedVehicles(productId);
      this.props.fetchRelatedOperators(productId);
    }

    // this.props.fetchProductTypes();
  }

  render() {
    return (
      <ProductPage
        product={this.props.product}
        productTypes={this.props.productTypes}
        vehicles={this.props.vehicles}
        operators={this.props.operators}
        loading={this.props.loading}
        loadedVehicles={this.props.loadedVehicles}
      />
    );
  }
}

function bindAction(dispatch) {
  return {
    fetchProduct: productId => dispatch(productActions.fetchProduct(productId)),
    fetchProductTypes: () => dispatch(productActions.fetchProductTypes()),
    fetchRelatedVehicles: productId => dispatch(productActions.fetchRelatedVehicles(productId)),
    fetchRelatedOperators: productId => dispatch(productActions.fetchRelatedOperators(productId))
  };
}

const mapStateToProps = state => ({
  product: state.productReducer.product,
  productTypes: state.productReducer.productTypes,
  vehicles: state.productReducer.vehicles,
  operators: state.productReducer.operators,
  loading: state.productReducer.loading,
  loadedVehicles: state.productReducer.loadedVehicles
});

export default connect(
  mapStateToProps,
  bindAction
)(ProductPageContainer);
