import * as React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DropdownSelect, FloatingLabelInput } from 'dl-web-components';
import ModalForm from 'stories/screens/ModalForm';
import * as userActions from 'redux/modules/userSettings';

class UserSettingsForm extends React.PureComponent {
  constructor(props) {
    super(props);
    const { distanceUnit, monthsBeforeMaintenanceDueMessage } = props;
    this.state = { distanceUnit, monthsBeforeMaintenanceDueMessage };
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { distanceUnit, monthsBeforeMaintenanceDueMessage } = props;
    this.setState({ distanceUnit, monthsBeforeMaintenanceDueMessage });
  }

  save() {
    this.props.save(this.state);
    this.props.toggleModal();
  }

  render() {
    const { handleSubmit } = this.props;
    const form = (
      <form>
        <FloatingLabelInput labelText="Display distances in">
          <DropdownSelect
            style={{ flex: 1 }}
            name="distanceUnit"
            placeholder="Select unit"
            mode="dropdown"
            selectedValue={this.state.distanceUnit}
            onChange={e => this.setState({ distanceUnit: e.target.value })}
            values={[
              {
                value: 'km',
                label: 'kilometers (km)',
              },
              {
                value: 'miles',
                label: 'miles',
              },
            ]}
          />
        </FloatingLabelInput>
        <FloatingLabelInput labelText="Months before maintenance reminder">
          <DropdownSelect
            style={{ flex: 1 }}
            name="monthsBefore"
            placeholder="Select amount of months"
            mode="dropdown"
            selectedValue={this.state.monthsBeforeMaintenanceDueMessage}
            onChange={e => this.setState({ monthsBeforeMaintenanceDueMessage: e.target.value })}
            values={[...Array(37).keys()].slice(1).map(i => ({ label: i, value: i }))}
          />
        </FloatingLabelInput>

      </form>
    );

    return (
      <ModalForm
        nonScrolling
        title="User settings"
        form={form}
        onSave={handleSubmit(this.save.bind(this))}
        onCancel={this.props.toggleModal}
      />
    );
  }
}

UserSettingsForm.propTypes = {
  distanceUnit: PropTypes.string,
  monthsBeforeMaintenanceDueMessage: PropTypes.number,
  save: PropTypes.func,
  dismiss: PropTypes.func,
  handleSubmit: PropTypes.func
};

const UserSettingsFormContainer = reduxForm({
  form: 'UserSettingsForm'
})(UserSettingsForm);

function mapState(state) {
  return {
    distanceUnit: state.userSettingsReducer.distanceUnit,
    monthsBeforeMaintenanceDueMessage: state.userSettingsReducer.monthsBeforeMaintenanceDueMessage
  };
}

function mapDispatch(dispatch) {
  return {
    save: values => dispatch(userActions.saveSettings(values))
  };
}

export default connect(
  mapState,
  mapDispatch
)(UserSettingsFormContainer);
