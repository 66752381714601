import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import OperatorPage from 'stories/screens/OperatorPage';
import OperatorOverviewPage from 'stories/screens/OperatorOverviewPage';
import * as fleetActions from 'redux/modules/fleet';
import * as favoriteActions from 'redux/modules/favorite';
import * as maintenanceActions from 'redux/modules/maintenance';
import * as operatorActions from 'redux/modules/operator';

class OperatorPageContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { fleetSearch: '' };
  }

  componentDidMount() {
    const operatorId = this.props.match.params.id;
    this.props.fetchFleets(operatorId);
    this.props.fetchMaintenances(operatorId);

    const { operatorReducer } = this.props;
    const { operators, loading, loaded } = operatorReducer;
    console.log(operatorReducer);

    if (loading || loaded || operators.length) {
      return;
    }
    this.fetchOperators();
  }

  fetchOperators() {
    const { operatorReducer, fetchOperators } = this.props;
    const { loading, loaded } = operatorReducer;

    if (loading || loaded) {
      return;
    }

    fetchOperators();
  }

  handleSearch(val) {
    this.setState({ fleetSearch: val });
  }

  toggleFavorite(id, favorite) {
    if (favorite) {
      // Existing favorite, delete it
      this.props.toggleFavorite(favorite.id, 'operators', true);
    } else {
      this.props.toggleFavorite(id, 'operators');
    }
  }

  switchPage(page) {
    console.log(`switching to sub page ${page}`);
    this.setState({ subPage: page });
  }

  render() {
    const fleets = this.props.fleetReducer.fleets.filter(o => {
      return o.name.toUpperCase().indexOf(this.state.fleetSearch.toUpperCase()) !== -1;
    });
    const { id } = this.props.match.params;
    const favoriteFleets = this.props.favoriteFleets.filter(f => f.fleet.operatorId === id);

    let ScreenComponent;
    switch (this.state.subPage) {
      case 'fleets':
        ScreenComponent = OperatorPage;
        break;
      case 'overview':
        ScreenComponent = OperatorOverviewPage;
        break;
      default:
        ScreenComponent = OperatorPage;
        break;
    }
    console.log('OPERATOR', this.props.operators, id, find(this.props.operators, { id }));

    return (
      <ScreenComponent
        {...this.props}
        fleets={fleets}
        onSearch={this.handleSearch.bind(this)}
        operatorId={id}
        toggleFavorite={this.toggleFavorite.bind(this)}
        favorite={find(this.props.favorites, { id })}
        operator={find(this.props.operators, { id })}
        loaded={this.props.fleetReducer.loaded}
        scheduledMaintenances={this.props.upcomingMaintenances.scheduled}
        predictedMaintenances={this.props.upcomingMaintenances.predicted}
        favoriteFleets={favoriteFleets}
        switchPage={this.switchPage.bind(this)}
        distanceUnit={this.props.distanceUnit}
      />
    );
  }
}

function bindAction(dispatch) {
  return {
    fetchFleets: operatorId => dispatch(fleetActions.fetchFleets(operatorId)),
    toggleFavorite: (targetId, type, favorite) => dispatch(favoriteActions.toggleFavorite(targetId, type, favorite)),
    fetchMaintenances: operatorId => dispatch(maintenanceActions.fetchUpcomingMaintenancesForOperator(operatorId)),
    fetchOperators: () => dispatch(operatorActions.fetchOperators()),
  };
}

const mapStateToProps = state => ({
  operatorReducer: state.operatorReducer,
  fleetReducer: state.fleetReducer,
  maintenanceReducer: state.maintenanceReducer,
  favorites: state.favoriteReducer.operators,
  operators: state.operatorReducer.operators,
  favoriteFleets: state.favoriteReducer.fleets,
  upcomingMaintenances: state.maintenanceReducer.upcomingMaintenances,
  distanceUnit: state.userSettingsReducer.distanceUnit
});

export default connect(
  mapStateToProps,
  bindAction
)(OperatorPageContainer);
