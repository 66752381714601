import * as fleetActions from 'redux/modules/fleet';
import * as updatedActions from 'redux/modules/updated';
import * as errorActions from './error';
import * as constants from './paths';

const LOAD = 'railway/product/LOAD';
const LOAD_SUCCESS = 'railway/product/LOAD_SUCCESS';
const LOAD_FAIL = 'railway/product/LOAD_FAIL';

const LOAD_PRODUCT = 'railway/product/LOAD_PRODUCT';
const LOAD_PRODUCT_SUCCESS = 'railway/product/LOAD_PRODUCT_SUCCESS';
const LOAD_PRODUCT_FAIL = 'railway/product/LOAD_PRODUCT_FAIL';

const LOAD_PRODUCTS = 'railway/product/LOAD_PRODUCTS';
const LOAD_PRODUCTS_SUCCESS = 'railway/product/LOAD_PRODUCTS_SUCCESS';
const LOAD_PRODUCTS_FAIL = 'railway/product/LOAD_PRODUCTS_FAIL';

const LOAD_BEARINGS = 'railway/product/LOAD_BEARINGS';
const LOAD_BEARINGS_SUCCESS = 'railway/product/LOAD_BEARINGS_SUCCESS';
const LOAD_BEARINGS_FAIL = 'railway/product/LOAD_BEARINGS_FAIL';

const LOAD_PRODUCT_VEHICLES = 'railway/product/LOAD_PRODUCT_VEHICLES';
const LOAD_PRODUCT_VEHICLES_SUCCESS = 'railway/product/LOAD_PRODUCT_VEHICLES_SUCCESS';
const LOAD_PRODUCT_VEHICLES_FAIL = 'railway/product/LOAD_PRODUCT_VEHICLES_FAIL';

const LOAD_PRODUCT_OPERATORS = 'railway/product/LOAD_PRODUCT_OPERATORS';
const LOAD_PRODUCT_OPERATORS_SUCCESS = 'railway/product/LOAD_PRODUCT_OPERATORS_SUCCESS';
const LOAD_PRODUCT_OPERATORS_FAIL = 'railway/product/LOAD_PRODUCT_OPERATORS_FAIL';

const initialState = {
  loaded: false,
  loading: false,
  product: [],
  products: [],
  productTypes: [],
  vehicles: [],
  operators: [],
  loadedType: ''
};

export default function product(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        productTypes: action.result || []
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        productTypes: []
      };

    case LOAD_PRODUCT:
      return {
        ...state,
        loading: true,
        product: []
      };
    case LOAD_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        product: [action.result] || []
      };
    case LOAD_BEARINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        bearings: action.result || []
      };
    case LOAD_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };

    case LOAD_PRODUCTS:
      return {
        ...state,
        loading: true,
        products: []
      };
    case LOAD_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        products: action.result || []
      };
    case LOAD_PRODUCTS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };

    case LOAD_PRODUCT_VEHICLES:
      return {
        ...state,
        loading: true,
        loadedVehicles: false,
        vehicles: []
      };
    case LOAD_PRODUCT_VEHICLES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        loadedVehicles: true,
        vehicles: action.result || []
      };
    case LOAD_PRODUCT_VEHICLES_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        loadedVehicles: false,
        error: action.error
      };

    case LOAD_PRODUCT_OPERATORS:
      return {
        ...state,
        loading: true,
        operators: []
      };
    case LOAD_PRODUCT_OPERATORS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        operators: action.result || []
      };
    case LOAD_PRODUCT_OPERATORS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case 'LOADED_PRODUCT_TYPE':
      return {
        ...state,
        loadedType: action.value
      };

    default:
      return state;
  }
}

export function fetchProducts(type = 'bearing') {
  return {
    types: [LOAD_PRODUCTS, LOAD_PRODUCTS_SUCCESS, LOAD_PRODUCTS_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject();
      }
      dispatch({ type: 'LOADED_PRODUCT_TYPE', value: type });
      return client.get(`${constants.RAILWAY_PATH}/${type}-classes`).catch(e => {
        dispatch(errorActions.errorMessage('Error fetching products.'));
        return Promise.reject(new Error(e));
      });
    }
  };
}

export function fetchBearings(type = 'bearing') {
  return {
    types: [LOAD_BEARINGS, LOAD_BEARINGS_SUCCESS, LOAD_BEARINGS_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject();
      }
      return client.get(`${constants.RAILWAY_PATH}/${type}-classes`).catch(e => {
        dispatch(errorActions.errorMessage('Error fetching products.'));
        return Promise.reject(new Error(e));
      });
    }
  };
}

export function fetchProduct(bearingClassId) {
  console.log(`fetching bearing class ${bearingClassId}`);
  return {
    types: [LOAD_PRODUCT, LOAD_PRODUCT_SUCCESS, LOAD_PRODUCT_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject();
      }
      return client.get(`${constants.RAILWAY_PATH}/bearing-classes/${bearingClassId}`).catch(e => {
        dispatch(errorActions.errorMessage('Error fetching product.'));
        return Promise.reject(new Error(e));
      });
    }
  };
}

export function fetchProductTypes() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject();
      }
      return client.get(`${constants.RAILWAY_PATH}/bearing-types`).catch(e => {
        dispatch(errorActions.errorMessage('Error fetching product types.'));
        return Promise.reject(new Error(e));
      });
    }
  };
}

export function fetchRelatedVehicles(bearingClassId) {
  console.log(`fetching related train classes for bearing class ${bearingClassId}`);
  return {
    types: [LOAD_PRODUCT_VEHICLES, LOAD_PRODUCT_VEHICLES_SUCCESS, LOAD_PRODUCT_VEHICLES_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject();
      }
      return client.get(`${constants.RAILWAY_PATH}/train-classes-by-bearing-class/${bearingClassId}`).catch(e => {
        dispatch(errorActions.errorMessage('Error fetching related vehicles.'));
        return Promise.reject(new Error(e));
      });
    }
  };
}

export function fetchRelatedOperators(bearingClassId) {
  console.log(`fetching related operators for bearing class ${bearingClassId}`);
  return {
    types: [LOAD_PRODUCT_OPERATORS, LOAD_PRODUCT_OPERATORS_SUCCESS, LOAD_PRODUCT_OPERATORS_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject();
      }
      return client.get(`${constants.RAILWAY_PATH}/train-operators-by-bearing-class/${bearingClassId}`).catch(e => {
        dispatch(errorActions.errorMessage('Error fetching related operators.'));
        return Promise.reject(new Error(e));
      });
    }
  };
}

export function addBearingToFleetComponent(type, prod, fleetId, position, target, amount, component) {
  const data = {
    amount,
    position,
    fleetId,
    type,
    target,
    component,
    bearingClassId: prod.id
  };
  console.log(data);

  return {
    types: ['TODO', 'TODO', 'TODO'],
    promise: (client, dispatch) => {
      return client
        .put(`${constants.MUTATE_PATH}/bearings`, { data })
        .then(() => {
          dispatch(fleetActions.fetchFleetTrains(fleetId));
        })
        .then(() => {
          dispatch(updatedActions.fetchUpdatedForFleet(fleetId));
        })
        .catch(e => {
          dispatch(errorActions.errorMessage('Error adding bearing to fleet component.'));
          return Promise.reject(new Error(e));
        });
    }
  };
}

export function addNewBearingToFleetComponent(type, bearingClassName, fleetId, position, target, amount, component) {
  const data = {
    amount,
    position,
    fleetId,
    type,
    target,
    component,
    bearingClassName
  };
  console.log(data);

  return {
    types: ['TODO', 'TODO', 'TODO'],
    promise: (client, dispatch) => {
      return client
        .put(`${constants.MUTATE_PATH}/bearings/new`, { data })
        .then(() => {
          dispatch(fleetActions.fetchFleetTrains(fleetId));
        })
        .then(() => {
          dispatch(updatedActions.fetchUpdatedForFleet(fleetId));
        })
        .catch(e => {
          dispatch(errorActions.errorMessage('Error adding a new bearing to fleet component.'));
          return Promise.reject(new Error(e));
        });
    }
  };
}

export function removeBearingByIds(bearingIds, fleetId, components) {
  const data = {
    bearingIds,
    components
  };
  console.log(data);

  return {
    types: ['TODO', 'TODO', 'TODO'],
    promise: (client, dispatch) => {
      return client
        .del(`${constants.MUTATE_PATH}/bearings/`, { data })
        .then(() => {
          dispatch(fleetActions.fetchFleetTrains(fleetId));
        })
        .then(() => {
          dispatch(updatedActions.fetchUpdatedForFleet(fleetId));
        })
        .catch(e => {
          dispatch(errorActions.errorMessage('Error removing bearing.'));
          return Promise.reject(new Error(e));
        });
    }
  };
}

export function removeBearing(bearingIds, fleetId, type, position, bogieClass) {
  const data = {
    bearingIds,
    fleetId,
    type,
    position,
    bogieClass
  };
  console.log(data);

  return {
    types: ['TODO', 'TODO', 'TODO'],
    promise: (client, dispatch) => {
      return client
        .del(`${constants.MUTATE_PATH}/bearings/fleet`, { data })
        .then(() => {
          dispatch(fleetActions.fetchFleetTrains(fleetId));
        })
        .then(() => {
          dispatch(updatedActions.fetchUpdatedForFleet(fleetId));
        })
        .catch(e => {
          dispatch(errorActions.errorMessage('Error removing bearing.'));
          return Promise.reject(new Error(e));
        });
    }
  };
}
