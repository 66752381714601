import * as React from 'react';
import styled from 'styled-components';
import { Button, Loader as Spinner } from 'dl-web-components';

const Title = styled.h2``;
const Body = styled.div``;
const Footer = styled.div``;
const Container = styled.div``;
const Header = styled.div``;

class AddMaintenance extends React.PureComponent {
  render() {
    return (
      <Container>
        <Header>
          <Body style={{ alignItems: 'center' }}>
            <Title>{this.props.edit ? 'Edit' : 'Add'} maintenance</Title>
          </Body>
        </Header>
        <div style={{ flex: 1 }}>{this.props.maintenanceForm}</div>
        <Footer>
          <div style={{ width: '100%', padding: 5 }}>
            {this.props.loading ? (
              <div>
                <Spinner style={{ width: '100%', height: 20, alignItems: 'center' }} />
              </div>
            )
              :
              (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Button
                    style={{
                      flex: 1,
                      backgroundColor: 'rgb(134,141,150)',
                      justifyContent: 'center',
                      marginLeft: 20,
                      marginRight: 10
                    }}
                    onClick={() => this.props.onCancel()}
                  >
                    <p>Cancel</p>
                  </Button>
                  <Button
                    style={{ flex: 1, justifyContent: 'center', marginLeft: 10, marginRight: 20 }}
                    onClick={() => this.props.onSave()}
                    disabled={this.props.disabled}
                  >
                    <p>Save</p>
                  </Button>
                </div>
              )}
          </div>
        </Footer>
      </Container>
    );
  }
}

export default AddMaintenance;
