import * as React from 'react';
import { connect } from 'react-redux';
import VehiclePage from 'stories/screens/VehiclePage';
import SearchBar from 'components/SearchBar';
import * as oemsActions from 'redux/modules/oems';
import { PropTypes } from 'prop-types';


class VehiclePageContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { oemsSearch: '' };
    this.searchRef = {};
  }

  componentDidMount() {
    // const { navigation: { state: { params } } } = this.props;
    const { match: { params }, oemsReducer, fetchOemVehicles, fetchClassesByFamily, fetchOem } = this.props;

    if (!oemsReducer.loading) {
      fetchOemVehicles(params.id);
      fetchOem(params.id);
    }


    fetchClassesByFamily(decodeURIComponent(params.vehicleFamily), params.id);
  }

  render() {
    const { oem, oemsReducer, match: { params } } = this.props;
    const searchForm = (
      <SearchBar
        ref={instance => {
          this.searchRef = instance;
        }}
        setSearch={(val => this.setState({ oemsSearch: val }))}
      />
    );

    console.log(this.props.oemsReducer.family);

    const fleets = this.props.oemsReducer.family.filter(o => {
      return o.fleet.name.toUpperCase().indexOf(this.state.oemsSearch.toUpperCase()) !== -1;
    });

    // fleets = sortBy(fleets, ['fleet.name']);

    return (
      <VehiclePage
        fleets={fleets}
        family={decodeURIComponent(params.vehicleFamily)}
        oem={oem}
        loading={oemsReducer.loading}
        searchForm={searchForm}
        focusSearch={this.searchRef.clearAndFocusSearch}
      />
    );
  }
}

function bindAction(dispatch) {
  return {
    fetchClassesByFamily: (family, oemId) => dispatch(oemsActions.fetchClassesByFamily(family, oemId)),
    fetchOemVehicles: oemId => dispatch(oemsActions.fetchOemVehicles(oemId)),
    fetchOem: oemId => dispatch(oemsActions.fetchOem(oemId)),
  };
}

const mapStateToProps = state => {
  return {
    oemsReducer: state.oemsReducer || {},
    authenticated: state.authReducer.authenticated,
    vehicles: state.oemsReducer.vehicles,
    oem: state.oemsReducer.oem || {},
  };
};

VehiclePageContainer.propTypes = {
  navigation: PropTypes.any,
  oemsReducer: PropTypes.object,
  fetchClassesByFamily: PropTypes.func,
};

export default connect(mapStateToProps, bindAction)(VehiclePageContainer);
