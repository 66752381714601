import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Loader as Spinner } from 'dl-web-components';
import styled from 'styled-components';
import ContentContainer from 'components/ContentContainer';
import Heading from 'components/Heading';
import Sidebar from 'components/Sidebar';
import SidebarListItem from 'components/SidebarListItem';
import Menu from 'container/Menu';
import FlatList from 'components/FlatList';

const StyledContent = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
`;

const List = styled.ul``;
const ListItem = styled(Link)``;
const Body = styled.div``;
const Right = styled.div``;
const Container = styled.div``;
const Header = styled.div``;
const Left = styled.div``;
const ScrollView = styled.div``;


const Title = styled.h2``;

class OemsListingPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { showSearch: true };
    this.renderItem.bind(this);
    this.oemsByComponents.bind(this);
  }

  oemsByComponents(type) {
    if (!this.props.oems) {
      return [];
    }
    if (!type) {
      return this.props.oems;
    }

    const countField = `${type}Count`;
    return this.props.oems.filter(oem => {
      if (oem[countField] > 0) {
        return oem;
      }
      return false;
    });
  }

  renderItem(item) {
    return (
      <ListItem
        button
        key={item.id}
        style={{ marginLeft: 0 }}
        to={`/oems/${item.id}`}
      // onClick={() => {
      //   go(navigator, 'OemBogiesPage', {
      //     oem: item,
      //     selectedType: this.state.selectedType
      //   });
      // }}
      >
        <Body>
          <p style={{ marginLeft: 0, paddingBottom: 10 }} >
            {item.name}
          </p>
        </Body>
        <Right>
          <Icon name="arrow-forward" />
        </Right>
      </ListItem>
    );
  }

  render() {
    const oems = this.oemsByComponents(this.state.selectedType);

    return (
      <Container style={{ width: '1400px', margin: 'auto', marginTop: 0, maxWidth: '97%', height: '100%', flex: 1 }}>
        <Menu />
        <Header>
          <Left />
          <Body>
            <Title>OEMs</Title>
          </Body>
          <Right />
        </Header>
        <StyledContent>
          <Sidebar>
            <ScrollView style={{ height: '100%' }}>
              <List>
                <SidebarListItem itemDivider>
                  <p>Categories</p>
                </SidebarListItem>
                <SidebarListItem onClick={() => this.setState({ selectedType: 'bogie' })}>
                  <p
                    style={{ cursor: 'pointer', fontWeight: this.state.selectedType === 'bogie' ? 'bold' : 'normal' }}
                  >
                    Bogies
                  </p>
                </SidebarListItem>
                <SidebarListItem onClick={() => this.setState({ selectedType: 'vehicle' })}>
                  <p
                    style={{ cursor: 'pointer', fontWeight: this.state.selectedType === 'vehicle' ? 'bold' : 'normal' }}
                  >
                    Vehicles
                  </p>
                </SidebarListItem>
                <SidebarListItem onClick={() => this.setState({ selectedType: 'motor' })}>
                  <p
                    style={{ cursor: 'pointer', fontWeight: this.state.selectedType === 'motor' ? 'bold' : 'normal' }}
                  >
                    Motors
                  </p>
                </SidebarListItem>
                <SidebarListItem onClick={() => this.setState({ selectedType: 'gearbox' })}>
                  <p
                    style={{ cursor: 'pointer', fontWeight: this.state.selectedType === 'gearbox' ? 'bold' : 'normal' }}
                  >
                    Gearboxes
                  </p>
                </SidebarListItem>
              </List>
              <List style={{ marginBottom: 0 }}>
                <SidebarListItem itemDivider />
                <SidebarListItem onClick={() => this.setState({ selectedType: '' })}>
                  <p style={{ cursor: 'pointer' }}>All categories</p>
                </SidebarListItem>
              </List>
            </ScrollView>
          </Sidebar>

          <ContentContainer>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <div>
                <Heading>
                  {this.state.selectedType
                    ? this.state.selectedType.charAt(0).toUpperCase() + this.state.selectedType.slice(1)
                    : 'All'}{' '}
                  OEMs ({oems.length})
                </Heading>
              </div>
            </div>

            <div>{this.props.searchForm}</div>

            {this.props.loading && (
              <div>
                <Spinner style={{ width: '100%', height: 20, alignItems: 'center' }} />
              </div>
            )}

            {oems.length > 0 ? (
              <FlatList
                data={oems}
                renderItem={item => this.renderItem(item)}
              />
            ) : (
                !this.props.loading && <Heading>No results</Heading>
              )}
          </ContentContainer>
        </StyledContent>
      </Container>
    );
  }
}

export default OemsListingPage;
