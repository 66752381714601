import React, { Component } from 'react';
import styled from 'styled-components';
import { colors } from '../../constants';

const FooterDiv = styled.div`
  background-color: ${colors.lighterGray};
  text-align: center;
  text-transform: uppercase;

  .footerBar {
    height: 20px;
    background-color: ${colors.blue};
  }
  .redSquare {
    width: 20px;
    height: 20px;
    background-color: ${colors.red};
    border-right: 1px solid white;
  }
  nav {
    padding: 1rem;

    a {
      padding: 0 0.5rem;
    }
  }
`;

export default class Footer extends Component {
  render() {
    return (
      <FooterDiv>
        <div className="footerBar">
          <div className="redSquare" />
        </div>
        <nav>
          <a
            className="brand stone"
            href="http://www.skf.com/group/footer/privacy-policy.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          <a
            className="brand stone"
            href="http://www.skf.com/group/footer/terms-and-conditions.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms & Conditions
          </a>
          <a
            className="brand stone"
            href="http://www.skf.com/group/footer/cookies.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cookies
          </a>
          {process.env.REACT_APP_ENV === 'development' && (
            <div style={{ fontSize: '.6rem', paddingTop: '.5rem', textTransform: 'lowercase' }}>
              build {process.env.REACT_APP_BUILD_DATE} | {process.env.REACT_APP_COMMIT_HASH}
            </div>
          )}
        </nav>
      </FooterDiv>
    );
  }
}
