import * as constants from './paths';
import * as errorActions from './error';
import { sortBy } from 'lodash';

const LOAD = 'railway/updated/LOAD';
const LOAD_SUCCESS = 'railway/updated/LOAD_SUCCESS';
const LOAD_FAIL = 'railway/updated/LOAD_FAIL';

const initialState = {
  loaded: false,
  loading: false,
  updated: []
};

export default function updated(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        updated: (action.result ? sortBy(action.result, 'updatedAt').reverse() : [])
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        updated: []
      };
    default:
      return state;
  }
}

export function fetchUpdatedForFleet(fleetId) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject(new Error('Not authenticated'));
      }

      return client.get(`${constants.RAILWAY_PATH}/updated/${fleetId}`).catch(e => {
        dispatch(errorActions.errorMessage('Error fetching update information for fleet.'));
        return Promise.reject(new Error(e));
      });
    }
  };
}

export function fetchUpdatedForComponent(componentId) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject(new Error('Not authenticated'));
      }

      return client.get(`${constants.RAILWAY_PATH}/updated/${componentId}`).catch(e => {
        dispatch(errorActions.errorMessage('Error fetching update information for component.'));
        return Promise.reject(new Error(e));
      });
    }
  };
}
