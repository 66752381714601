import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Loader as Spinner } from 'dl-web-components';
import styled from 'styled-components';
import ContentContainer from 'components/ContentContainer';
import Heading from 'components/Heading';
import FlatList from 'components/FlatList';
import Menu from 'container/Menu';

const Container = styled.div``;
const Header = styled.div``;
const Body = styled.div``;
const Right = styled.div``;
const ListItem = styled(Link)``;

const StyledContent = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
`;

class VehiclePage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { showSearch: true };
    this.renderItem.bind(this);
  }

  renderItem(item) {
    return (
      <ListItem
        button
        key={item.id}
        style={{ marginLeft: 0 }}
        to={`/operators/${item.fleet.operatorId}/fleets/${item.fleet.id}`}
      >
        <Body>
          <p style={{ marginLeft: 0, paddingBottom: 10 }} >
            {item.fleet.name}
          </p>
          <p note>{item.fleet.operator ? item.fleet.operator.name : ''}</p>
        </Body>
      </ListItem>
    );
  }

  render() {
    const { fleets } = this.props;
    console.log(this.props);

    const byName = (a, b) => {
      if (a.fleet.name < b.fleet.name) {
        return -1;
      }
      if (a.fleet.name > b.fleet.name) {
        return 1;
      }
      return 0;
    };

    return (
      <Container style={{ width: '1400px', margin: 'auto', marginTop: 0, maxWidth: '97%', height: '100%', flex: 1 }}>
        <Menu />
        <Header>
          <Heading>
            {this.props.oem.name} - {this.props.family}
          </Heading>
          <Right />
        </Header>
        <StyledContent>
          <ContentContainer leftPadding={true}>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <div>
                <Heading>Fleets ({fleets.length})</Heading>
              </div>
            </div>

            <div style={{ display: this.state.showSearch ? 'block' : 'none' }}>{this.props.searchForm}</div>

            <FlatList
              data={fleets.sort(byName)}
              renderItem={item => this.renderItem(item)}
            />

            {this.props.loading && (
              <div>
                <Spinner style={{ width: '100%', height: 20, alignItems: 'center' }} />
              </div>
            )}
          </ContentContainer>
        </StyledContent>
      </Container>
    );
  }
}

export default VehiclePage;
