import * as React from 'react';
import { connect } from 'react-redux';
import OemBogiesPage from 'stories/screens/OemBogiesPage';
import SearchBar from 'components/SearchBar';
import * as oemsActions from 'redux/modules/oems';

class OemBogiesPageContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      componentSearch: '',
      // selectedType: props.navigation.state.params.selectedType,
    };

    this.searchRef = {};
  }

  componentDidMount() {
    const { authenticated, oemsReducer, fetchOem, fetchOemVehicles } = this.props;

    if (authenticated && !oemsReducer.loading) {
      fetchOem(this.props.match.params.id);
      fetchOemVehicles(this.props.match.params.id);
    }
  }

  getVehicles(searchWord) {
    const { vehicles } = this.props;

    const unknownFamily = { family: OemBogiesPageContainer.UNKNOWN_VEHICLE_FAMILY };

    const contains = (value, _searchWord) => value
      .toLowerCase()
      .indexOf(_searchWord) >= 0;

    return vehicles
      .map(item => (item.family ? item : { ...item, ...unknownFamily }))
      .filter(item => contains(item.family, searchWord)
        || item.fleets.filter(fleet => contains(fleet.name, searchWord)).length > 0);
  }

  static UNKNOWN_VEHICLE_FAMILY: string = 'Unknown vehicle family';

  render() {
    const { oem, oemsReducer } = this.props;
    const { selectedType, componentSearch } = this.state;

    const searchWord = componentSearch.toLowerCase();

    const searchForm = (
      <SearchBar
        ref={instance => {
          this.searchRef = instance;
        }}
        setSearch={(value => this.setState({ componentSearch: value }))}
      />
    );

    const oemData = { ...oem };

    Object.keys(oemData)
      .forEach(value => {
        if (Array.isArray(oemData[value])) {
          oemData[value] = oemData[value].filter(o =>
            o.designation && o.designation.toLowerCase()
              .indexOf(searchWord) >= 0);
        }
      });

    return (
      <OemBogiesPage
        oem={oemData}
        vehicles={this.getVehicles(searchWord)}
        selectedType={selectedType}
        loading={oemsReducer.loading}
        searchForm={searchForm}
      />
    );
  }
}

function bindAction(dispatch) {
  return {
    fetchOem: oemId => dispatch(oemsActions.fetchOem(oemId)),
    fetchOemVehicles: oemId => dispatch(oemsActions.fetchOemVehicles(oemId)),
  };
}

const mapStateToProps = state => {
  return {
    oemsReducer: state.oemsReducer || {},
    authenticated: state.authReducer.authenticated,
    oem: state.oemsReducer.oem,
    vehicles: state.oemsReducer.vehicles,
  };
};


export default connect(mapStateToProps, bindAction)(OemBogiesPageContainer);
