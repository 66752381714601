const CHANGE_TYPES = [
  null, // 0
  'Fleet Information', // 1
  'Fleet Maintenances', // 2
  'Trailer Bogie Type', // 3
  'Motor Bogie Type', // 4
  'Trailer Bogie Bearings', // 5
  'Motor Bogie Bearings', // 6
  'Gearbox Type', // 7
  'Gearbox Bearings', // 8
  'Motor Type', // 9
  'Motor Bearings', // 10
  'Motor Type Information', // 11
  'Gearbox Type Information', // 12
  'Bogie Type Information', // 13
  'Bearing Type Information', // 14
  'Fleet Image', // 15
  'Document Uploaded', // 16
  'Document Info', // 17
  'Document Removed', // 18
];

export default CHANGE_TYPES;
