import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import FleetOverviewPage from 'stories/screens/FleetOverviewPage';
import MotorPage from 'stories/screens/MotorBogiePage';
import TrailerPage from 'stories/screens/TrailerBogiePage';
import FleetMaintenancePage from 'stories/screens/FleetMaintenancePage';
import FleetDocumentsPage from 'stories/screens/FleetDocumentsPage';
import * as fleetActions from 'redux/modules/fleet';
import * as favoriteActions from 'redux/modules/favorite';
import * as maintenanceActions from 'redux/modules/maintenance';
import * as updatedActions from 'redux/modules/updated';
import * as documentActions from 'redux/modules/document';
import { PropTypes } from 'prop-types';

class FleetOverviewPageContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { subPage: '' };
  }

  componentDidMount() {
    const { fleetId } = this.props.match.params;

    this.props.fetchFleet(fleetId);
    this.props.fetchFleetTrains(fleetId);
    this.props.fetchExistingMaintenances(fleetId);
    this.props.fetchProjectedMaintenances(fleetId);
    this.props.fetchUpdatedForFleet(fleetId);
    this.props.fetchDocuments(fleetId);
    this.props.fetchFavorites('fleets');
    this.props.fetchFavorites('operators');
  }

  toggleFavorite(id, favorite) {
    if (favorite) {
      // Existing favorite, delete it
      this.props.toggleFavorite(favorite.id, true);
    } else {
      this.props.toggleFavorite(id);
    }
  }

  switchPage(page) {
    this.setState({ subPage: page });
  }

  render() {
    const { fleetId } = this.props.match.params;
    let fleet = {};
    if (this.props.fleetsReducer.fleets.length) {
      fleet = this.props.fleetsReducer.fleets.find(f => f.id === fleetId) || {};
    }

    let ScreenComponent;
    let DocumentActions;
    switch (this.state.subPage) {
      case 'motor':
        ScreenComponent = MotorPage;
        break;
      case 'trailer':
        ScreenComponent = TrailerPage;
        break;
      case 'maintenance':
        ScreenComponent = FleetMaintenancePage;
        break;
      case 'documents':
        ScreenComponent = FleetDocumentsPage;
        DocumentActions = {
          deleteDocument: this.props.deleteDocument,
          saveDocument: this.props.saveDocument,
          addDocument: this.props.addDocument,
          gotUploadURL: this.props.documentReducer.gotUploadURL,
          uploadCallback: this.props.documentReducer.uploadCallback
        };
        break;
      default:
        ScreenComponent = FleetOverviewPage;
    }

    return (
      <ScreenComponent
        fleet={fleet}
        loaded={this.props.fleetsReducer.loaded}
        trains={this.props.fleetsReducer.fleet}
        distanceUnit={this.props.userSettingsReducer.distanceUnit}
        train={this.props.train}
        toggleFavorite={this.toggleFavorite.bind(this)}
        favorite={find(this.props.favorites, { fleetId: fleet.id })}
        switchPage={this.switchPage.bind(this)}
        existingMaintenances={this.props.existingMaintenances}
        upcomingMaintenances={this.props.upcomingMaintenances}
        updated={this.props.updated}
        cacheInvalidation={this.props.cacheInvalidation}
        documents={this.props.documentReducer.documents}
        documentActions={DocumentActions}
      />
    );
  }
}

FleetOverviewPageContainer.propTypes = {
  addDocument: PropTypes.func,
  deleteDocument: PropTypes.func,
  saveDocument: PropTypes.func,
  fetchDocuments: PropTypes.func,
  fetchExistingMaintenances: PropTypes.func,
  fetchFleet: PropTypes.func,
  fetchFleetTrains: PropTypes.func,
  fetchProjectedMaintenances: PropTypes.func,
  fetchUpdatedForFleet: PropTypes.func,
  toggleFavorite: PropTypes.func,
  documentReducer: PropTypes.object,
  fleetsReducer: PropTypes.object,
  userSettingsReducer: PropTypes.object,
  train: PropTypes.any,
  favorites: PropTypes.any,
  existingMaintenances: PropTypes.any,
  upcomingMaintenances: PropTypes.any,
  updated: PropTypes.any,
  cacheInvalidation: PropTypes.any
};

function bindAction(dispatch) {
  return {
    addDocument: (fleetId, data, callback, webui) =>
      dispatch(documentActions.addDocument(fleetId, data, callback, webui)),
    saveDocument: (fleetId, id, data) => dispatch(documentActions.saveDocument(fleetId, id, data)),
    deleteDocument: (id, fleetId) => dispatch(documentActions.deleteDocument(id, fleetId)),
    fetchDocuments: fleetId => dispatch(documentActions.fetchDocuments(fleetId)),
    fetchFleetTrains: fleetId => dispatch(fleetActions.fetchFleetTrains(fleetId)),
    fetchFleet: fleetId => dispatch(fleetActions.fetchFleet(fleetId)),
    toggleFavorite: (targetId, favorite) => dispatch(favoriteActions.toggleFavorite(targetId, 'fleets', favorite)),
    fetchExistingMaintenances: fleetId => dispatch(maintenanceActions.fetchMaintenancesForFleet(fleetId, false)),
    fetchProjectedMaintenances: fleetId => dispatch(maintenanceActions.fetchEstimatedMaintenancesForFleet(fleetId)),
    fetchUpdatedForFleet: fleetId => dispatch(updatedActions.fetchUpdatedForFleet(fleetId)),
    fetchFavorites: type => dispatch(favoriteActions.fetchFavorites(type)),
  };
}

const mapStateToProps = state => ({
  documentReducer: state.documentReducer,
  userSettingsReducer: state.userSettingsReducer,
  fleetsReducer: state.fleetReducer,
  train: state.trainReducer.train,
  favorites: state.favoriteReducer.fleets,
  updated: state.updatedReducer.updated,
  existingMaintenances: state.maintenanceReducer.fleetExistingMaintenances,
  upcomingMaintenances: state.maintenanceReducer.fleetEstimatedMaintenances,
  cacheInvalidation: state.imageReducer.cacheInvalidation
});
export default connect(
  mapStateToProps,
  bindAction
)(FleetOverviewPageContainer);
