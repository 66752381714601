import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import {
  Modal,
  Icon,
  Loader as Spinner,
  Button
} from 'dl-web-components';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AddComponentClassForm from 'container/AddComponentClassForm';
import TCell from 'components/TCell';
import TRow from 'components/TRow';
import motorImage from 'assets/motor.png';
import gearboxImage from 'assets/gearboxes.png';
import Menu from 'container/Menu';

import styles from './styles';

const Title = styled.h2``;
const StyledListItem = styled(Link)`
  border-color: gray;
  padding: 10px;
`;
const Body = styled.div``;
const Right = styled.div``;
const Container = styled.div``;
const Header = styled.div``;

const StyledContent = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
`;

const ContentContainer = styled.div`
  flex: 1;
  padding: 20px;
`;

const Heading = styled.p`
  font-size: 20;
  font-weight: bold;
  padding: 15px;
  color: #485a64;
`;

const ListText = styled.p`
  padding-bottom: 8px;
`;

const StyledList = styled.ul`
  background-color: #f1f1f1;
  margin: 10px;
`;

class ComponentPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { modalOpen: false, };
  }

  renderRow(type, value) {
    return (
      <TRow>
        <TCell>
          <p note>{type}</p>
        </TCell>
        <TCell>
          <p>{value || '---'}</p>
        </TCell>
      </TRow>
    );
  }

  render() {
    const { operators, fleets, type, oem, updated } = this.props;
    const { modalOpen } = this.state;
    console.log(fleets);

    // This is a workaround for an issue which prevented updated information from showing.
    const component = this.state.values ? this.state.values : this.props.component;

    let image;

    if (type === 'gearboxClass' || type === 'gearbox') {
      image = gearboxImage;
    } else if (type === 'motorClass' || type === 'motor') {
      image = motorImage;
    }
    console.log('COMPONENT', component);
    if (!component.id) {
      return <div />;
    }
    if (!component.type) {
      component.type = '---';
    }

    component.componentType = type.replace('Class', '');

    const byName = (a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    };

    return (
      <Container style={styles.container}>
        <Menu />
        <Header>
          <Body style={{ flex: 3, alignItems: 'center' }}>
            <Title>
              <Link to={`/oems/${oem.id}`}>{oem.name}</Link>
            </Title>
          </Body>

          <Right>
            <Button icon="cog" variant="plain" onClick={() => this.setState({ modalOpen: !modalOpen })}>Edit</Button>
            {modalOpen && (
              <Modal style={{ maxHeight: '90%' }} toggleModal={() => this.setState({ modalOpen: !modalOpen })}>
                <AddComponentClassForm
                  edit
                  oem={oem}
                  oemId={oem.id}
                  data={component}
                  toggleModal={() => this.setState({ modalOpen: !modalOpen })}
                  pullBack={values => this.setState({ values })}
                />
              </Modal>
            )}

            {/* <Modal button="Edit" linkColor="white">
              <AddComponentClassForm
                edit
                oem={oem}
                oemId={oem.id}
                data={component}
                pullBack={values => this.setState({ values })}
              />
            </Modal> */}
          </Right>
        </Header>

        <StyledContent>
          <ContentContainer>

            {this.props.loading ? (
              <div><Spinner style={{ width: '100%', height: 20, alignItems: 'center' }} /></div>
            )
              :
              (
                <React.Fragment>
                  <div>
                    <Heading>{component.designation}</Heading>
                    {!!updated[0] && (<p>Last updated by {updated[0].user} on {updated[0].date}</p>)}
                  </div>

                  <div style={{ flexDirection: 'row', display: 'flex' }}>
                    {image && (
                      <div>
                        <img
                          style={{ margin: 15, marginLeft: 0 }}
                          src={image}
                          alt=""
                        />
                        <p style={{ fontSize: 10, textAlign: 'center' }}>
                          This illustration is for reference purpose only and not the final application.
                      </p>
                      </div>
                    )}

                    <div style={{ flexDirection: 'column' }}>
                      {type === 'motorClass' && (
                        <div>
                          {this.renderRow('Description', component.description)}
                        </div>
                      )}
                      {type === 'gearboxClass' && (
                        <div>
                          {this.renderRow('Description', component.description)}
                          {this.renderRow('Stages', component.numStages)}
                        </div>
                      )}
                      {type.toLowerCase() === 'bogieclass' && (
                        <div>
                          {this.renderRow('Description', component.description)}
                          {this.renderRow('Type', component.type.toLowerCase())}
                          {this.renderRow('Axles', component.numAxles)}
                          {this.renderRow('Motors', component.numMotors)}
                          {this.renderRow('Gearboxes', component.numGearboxes)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div style={{ flexDirection: 'row' }}>
                    <div style={{ flex: 1 }}>
                      <Heading>Used by operators</Heading>
                      <StyledList>
                        {operators
                          .sort(byName)
                          .map((item, i) => (
                            <StyledListItem
                              key={i}
                              to={`/operators/${item.id}`}
                            >
                              <Body>
                                <ListText >{item.name}</ListText>
                                <ListText note>
                                  {item.fleetCount} fleets, {item.trainCount} vehicles
                                </ListText>
                              </Body>
                              <Right>
                                <Icon name="arrow-forward" />
                              </Right>

                            </StyledListItem>
                          ))}
                      </StyledList>
                    </div>
                    <div style={{ flex: 1 }}>
                      <Heading>Used in fleets</Heading>
                      <StyledList>
                        {fleets
                          .sort(byName)
                          .map((item, i) => (
                            <StyledListItem
                              key={i}
                              to={`/operators/${item.operatorId}/fleets/${item.id}`} // TODO: Check if operatorId is coming from backend
                            >
                              <Body>
                                <ListText >{item.name}</ListText>
                                <ListText note>
                                  {item.trainCount} vehicles, {item.family}
                                </ListText>
                              </Body>
                              <Right>
                                <Icon name="arrow-forward" />
                              </Right>

                            </StyledListItem>
                          ))}
                      </StyledList>
                    </div>
                  </div>
                </React.Fragment>
              )}
          </ContentContainer>
        </StyledContent>
      </Container>
    );
  }
}

ComponentPage.propTypes = {
  component: PropTypes.any,
  operators: PropTypes.any,
  fleets: PropTypes.any,
  type: PropTypes.any,
  oem: PropTypes.any,
  navigation: PropTypes.any,
  loading: PropTypes.any,
  updated: PropTypes.array,
};

export default ComponentPage;
