import * as oemsActions from 'redux/modules/oems';
import * as fleetActions from 'redux/modules/fleet';
import * as updatedActions from 'redux/modules/updated';
import * as errorActions from './error';
import * as constants from './paths';

const LOAD_COMPONENT = 'railway/component/LOAD';
const LOAD_COMPONENT_SUCCESS = 'railway/component/LOAD_SUCCESS';
const LOAD_COMPONENT_FLEETS_SUCCESS = 'railway/component/LOAD_FLEETS_SUCCESS';
const LOAD_COMPONENT_FAIL = 'railway/component/LOAD_FAIL';

const ADD_COMPONENT = 'railway/component/ADD';
const ADD_COMPONENT_SUCCESS = 'railway/component/ADD_SUCCESS';
const ADD_COMPONENT_FAIL = 'railway/component/ADD_FAIL';

const REMOVE_COMPONENT = 'railway/component/REMOVE';
const REMOVE_COMPONENT_SUCCESS = 'railway/component/REMOVE_SUCCESS';
const REMOVE_COMPONENT_FAIL = 'railway/component/REMOVE_FAIL';

const LOAD_COMPONENT_CLASS = 'railway/component/LOAD_CLASS';
const LOAD_COMPONENT_CLASS_SUCCESS = 'railway/component/LOAD_CLASS_SUCCESS';
const LOAD_COMPONENT_CLASS_FAIL = 'railway/component/LOAD_CLASS_FAIL';

const initialState = {
  loaded: false,
  loading: false,
  operators: [],
  fleets: [],
  component: {},
};

export default function component(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_COMPONENT:
    case ADD_COMPONENT:
    case REMOVE_COMPONENT:
      return {
        ...state,
        loading: true,
      };
    case LOAD_COMPONENT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        operators: action.result || [],
      };
    case LOAD_COMPONENT_FLEETS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        fleets: action.result || [],
      };
    case ADD_COMPONENT_SUCCESS:
    case REMOVE_COMPONENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case LOAD_COMPONENT_CLASS:
      return {
        ...state,
        loading: true,
      };
    case LOAD_COMPONENT_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        component: action.result || {},
      };
    case LOAD_COMPONENT_FAIL:
    case LOAD_COMPONENT_CLASS_FAIL:
    case ADD_COMPONENT_FAIL:
    case REMOVE_COMPONENT_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function fetchComponentData(comp, type) {
  const componentType = type.slice(0, -5);
  return {
    types: [LOAD_COMPONENT_CLASS, LOAD_COMPONENT_CLASS_SUCCESS, LOAD_COMPONENT_CLASS_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject();
      }
      return client.get(`${constants.RAILWAY_PATH}/${componentType}-classes/${comp}`)
        .catch(e => {
          dispatch(errorActions.errorMessage('Error fetching component data.'));
          return Promise.reject(new Error(e));
        });
    },
  };
}

export function fetchComponent(comp, type) {
  const componentId = typeof comp === 'object' ? comp.id : comp;
  return dispatch => {
    dispatch(fetchComponentData(componentId, type));
    dispatch(fetchComponentUsageByOperator(componentId, type));
    dispatch(fetchComponentUsageByFleet(componentId, type));
  };
}

export function fetchComponentUsageByOperator(comp, type) {
  console.log('get COMPONENT by operator!', comp);
  return {
    types: [LOAD_COMPONENT, LOAD_COMPONENT_SUCCESS, LOAD_COMPONENT_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject(new Error('Not authenticated'));
      }

      return client.get(`${constants.RAILWAY_PATH}/operators-for-component/${type}/${comp}`)
        .catch(e => {
          dispatch(errorActions.errorMessage('Error fetching component usage by operator.'));
          return Promise.reject(new Error(e));
        });
    },
  };
}

export function fetchComponentUsageByFleet(comp, type) {
  console.log('get COMPONENT by fleet!', comp);
  return {
    types: [LOAD_COMPONENT, LOAD_COMPONENT_FLEETS_SUCCESS, LOAD_COMPONENT_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject(new Error('Not authenticated'));
      }

      return client.get(`${constants.RAILWAY_PATH}/fleets-for-component/${type}/${comp}`)
        .catch(e => {
          dispatch(errorActions.errorMessage('Error fetching component usage by fleet.'));
          return Promise.reject(new Error(e));
        });
    },
  };
}

export function createComponent(type, data) {
  console.log('Adding', data);
  const url = `${type}-class`;

  return {
    types: [ADD_COMPONENT, ADD_COMPONENT_SUCCESS, ADD_COMPONENT_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject(new Error('Not authenticated'));
      }

      return client
        .put(`${constants.MUTATE_PATH}/${url}`, {
          data,
          token: getState().authReducer.jwt,
        })
        .then(() => {
          dispatch(oemsActions.fetchOem(data.oemId));
        })
        .catch(e => {
          dispatch(errorActions.errorMessage('Error creating component.'));
          return Promise.reject(new Error(e));
        });
    },
  };
}

export function editComponent(type, data) {
  console.log('Editing', data);
  const url = `${type}-class`;

  return {
    types: [ADD_COMPONENT, ADD_COMPONENT_SUCCESS, ADD_COMPONENT_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject(new Error('Not authenticated'));
      }

      return client
        .patch(`${constants.MUTATE_PATH}/${url}/${data.id}`, {
          data,
          token: getState().authReducer.jwt,
        })
        .then(() => {
          dispatch(oemsActions.fetchOem(data.oemId));
          dispatch(updatedActions.fetchUpdatedForComponent(data.id));
        })
        .catch(e => {
          dispatch(errorActions.errorMessage('Error editing component.'));
          return Promise.reject(new Error(e));
        });
    },
  };
}

export function addComponent(type, typeClass, fleetId) {
  const data = {
    fleetId,
    classId: typeClass.id,
  };

  console.log('posting to ', type, data);
  return {
    types: [ADD_COMPONENT, ADD_COMPONENT_SUCCESS, ADD_COMPONENT_FAIL],
    promise: (client, dispatch) => {
      return client
        .put(`${constants.MUTATE_PATH}/${type}`, { data })
        .then(() => {
          dispatch(fleetActions.fetchFleetTrains(fleetId));
        })
        .catch(e => {
          dispatch(errorActions.errorMessage('Error adding component.'));
          return Promise.reject(new Error(e));
        });
    },
  };
}

export function switchComponentClass(type, components, typeClass, fleetId, amount) {
  let data;
  if (type === 'bogie') {
    data = {
      fleetId,
      id: typeClass.id,
      type: typeClass.type, // of bogie
    };
  } else {
    data = {
      amount,
      fleetId,
      id: typeClass.id,
      type: typeClass.type, // of bogie
      componentIds: components.map(item => item.id),
    };
  }
  console.log('posting to ', type, data);
  return {
    types: [ADD_COMPONENT, ADD_COMPONENT_SUCCESS, ADD_COMPONENT_FAIL],
    promise: (client, dispatch) => {
      return client
        .patch(`${constants.MUTATE_PATH}/${type}`, { data })
        .then(() => {
          dispatch(fleetActions.fetchFleetTrains(fleetId));
          dispatch(updatedActions.fetchUpdatedForFleet(fleetId));
        })
        .catch(e => {
          dispatch(errorActions.errorMessage('Error switching component class.'));
          return Promise.reject(new Error(e));
        });
    },
  };
}

export function removeComponent(components, fleetId, type) {
  let data;
  if (type === 'bogie') {
    console.log(components);
    data = {
      fleetId,
      componentClassId: components,
    };
  } else {
    data = {
      componentIds: components.map(item => item.id),
      fleetId,
      type,
    };
  }

  return {
    types: [REMOVE_COMPONENT, REMOVE_COMPONENT_SUCCESS, REMOVE_COMPONENT_FAIL],
    promise: (client, dispatch) => {
      return client
        .del(`${constants.MUTATE_PATH}/${type}`, { data })
        .then(() => {
          dispatch(fleetActions.fetchFleetTrains(fleetId));
          dispatch(updatedActions.fetchUpdatedForFleet(fleetId));
        })
        .catch(e => {
          dispatch(errorActions.errorMessage('Error removing component.'));
          return Promise.reject(new Error(e));
        });
    },
  };
}
