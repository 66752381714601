import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Button, Modal } from 'dl-web-components';
import TCell from 'components/TCell';
import TRow from 'components/TRow';
import AddMaintenanceForm from 'container/AddMaintenanceForm';
import styled from 'styled-components';

const { REACT_APP_IMAGE_DOMAIN } = process.env;
const TCellHead = styled(TCell)`
  color: rgb(132, 141, 151);
`;

class MaintenanceSmallBox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { more: props.more, modalOpen: false };
  }

  render() {
    const { maintenance, predicted } = this.props;
    const { modalOpen } = this.state;
    const fullDate = new Date(maintenance.maintenanceDate);
    const month = fullDate.toLocaleString('en-US', { month: 'long' });
    const year = fullDate.getFullYear();
    const date = `${month} ${year}`;

    return (
      <div style={{ backgroundColor: 'rgb(235,240,240)', marginRight: 20, marginBottom: 10, borderRadius: 10 }}>
        <div style={{ paddingLeft: 10, paddingTop: 10 }}>
          <TRow style={{ flexDirection: 'row' }}>
            <TCell style={{ flexGrow: 1, width: 100, marginTop: 10, marginRight: 50 }}>
              <img
                style={{ width: 100, height: 95 }}
                src={`${REACT_APP_IMAGE_DOMAIN}/${maintenance.fleet.imageIdentifier}.jpg`}
                alt=""
              />
            </TCell>
            <TCell multiline style={{ flexGrow: 4 }}>
              <Link
                to={`/operators/${maintenance.fleet.operatorId}/fleets/${maintenance.fleet.id}`}
                style={{ color: '#007AFF' }}
              >
                {maintenance.fleet.name}
              </Link>
              <p>
                {date} at {maintenance.fleet.maintenanceLocation}
              </p>
              <p>by {maintenance.fleet.maintenanceCompany}</p>
            </TCell>
            <TCell multiline style={{ flexGrow: 2, display: 'flex', flexDirection: 'row-reverse' }}>
              {predicted ? (
                <React.Fragment>
                  <Button icon="cog" variant="plain" onClick={() => this.setState({ modalOpen: !modalOpen })} />
                  {modalOpen && (
                    <Modal style={{ maxHeight: '90%' }} linkColor="#007AFF" toggleModal={() => this.setState({ modalOpen: !modalOpen })}>
                      <AddMaintenanceForm train={{ fleetId: maintenance.fleet.id }} toggleModal={() => this.setState({ modalOpen: !modalOpen })} />
                    </Modal>
                  )}
                </React.Fragment>
              )
                :
                (
                  <Button
                    transparent
                    onClick={() => {
                      this.setState({ more: !this.state.more });
                    }}
                  >
                    <Icon name="information-circle" style={{ color: '#007AFF', fontSize: 30 }} />
                  </Button>
                )}
            </TCell>
          </TRow>
        </div>
        {this.state.more && (
          <div style={{ padding: 20 }}>
            <TRow>
              <TCellHead>Mileage / year</TCellHead>
              <TCellHead />
            </TRow>
            <TRow>
              <TCell>{maintenance.fleet.trainClass.mileagePerYear}</TCell>
              <TCell />
            </TRow>
            <TRow>
              <TCellHead>Maintenance interval (km)</TCellHead>
              <TCellHead>Max. period between maintenances (years)</TCellHead>
            </TRow>
            <TRow>
              <TCell>{maintenance.fleet.trainClass.maintenanceIntervalKm}</TCell>
              <TCell>{maintenance.fleet.trainClass.maintenanceIntervalYears}</TCell>
            </TRow>
            <TRow>
              <TCellHead>Maint. workshop location</TCellHead>
              <TCellHead>Maint. company</TCellHead>
            </TRow>
            <TRow>
              <TCell>{maintenance.fleet.maintenanceLocation}</TCell>
              <TCell>{maintenance.fleet.maintenanceCompany}</TCell>
            </TRow>
          </div>
        )}
      </div>
    );
  }
}

export default MaintenanceSmallBox;
