import React from 'react';
import styled from 'styled-components';

const Heading = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  padding: 20px;
  padding-left: 0;
  color: #485a64;
`;

export default ({ children }) => (
  <Heading>{children}</Heading>
);
