import * as React from 'react';
import styled from 'styled-components';
import { DropdownSelect } from 'dl-web-components';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isNotEmpty, isPositiveInteger } from 'helpers/validation';
import ModalForm from 'stories/screens/ModalForm';
import TRow from 'components/TRow';
import TCell from 'components/TCell';
import FormField from 'components/FormField';
import * as componentActions from 'redux/modules/component';


const Label = styled.div``;
const Item = styled.div``;

const validatorErrors = {
  componentTypeNotSelected: 'Component type must be selected.',
  bogieTypeNotSelected: 'Bogie type must be selected.',
  numStagesNotSelected: 'Number of gearbox stages must be selected.',
  designationEmpty: "Designation can't be empty.",
  designationInvalid: 'Designation is already in use.',
  numAxlesEmpty: "Number of axles can't be empty.",
  numAxlesInvalid: 'Number of axles is invalid.',
  numGearboxesEmpty: "Number of gearboxes can't be empty.",
  numGearboxesInvalid: 'Number of gearboxes is invalid.',
  numMotorsEmpty: "Numbers of motors can't be empty.",
  numMotorsInvalid: 'Number of motors is invalid.'
};

const styles = {
  errorText: {
    color: 'rgba(235,32,42,1)',
    fontSize: 17,
    marginBottom: 5
  },
  errorContainer: {
    margin: 15,
    marginTop: 50
  }
};

class AddComponentClassForm extends React.PureComponent {
  textInput: any;

  constructor(props) {
    super(props);

    const type = props.data && props.data.componentType ? props.data.componentType : undefined;
    const designations = ['gearbox', 'motor'].reduce(
      (previous, current) => ({
        ...previous,
        [current]: new Set((props.oem[`${current}Classes`] || []).map(item => item.designation.toLowerCase()))
      }),
      {}
    );
    if (type && type !== 'bogie') {
      // Allow keeping existing designation while editing.
      designations[type].delete(props.data.designation.toLowerCase());
    }

    this.state = {
      type,
      numStages: props.data && props.data.numStages,
      bogieType: props.data && props.data.type,
      designations,
      // errors: new Set(props.edit ? '' : [validatorErrors.componentTypeNotSelected]),
      errors: new Set(''),
      userInputs: {
        designation: props.data ? props.data.designation : '',
        description: props.data ? props.data.description : '',
        numAxles: props.data ? props.data.numAxles : '',
        numGearboxes: props.data ? props.data.numGearboxes : '',
        numMotors: props.data ? props.data.numMotors : ''
      }
    };

    // this.renderInput = this.renderInput.bind(this);
    // this.validateForm = this.validateForm.bind(this);
  }

  create(values) {
    values.oemId = this.props.oemId;
    const { type } = this.state;
    if (type === 'bogie') {
      values.type = this.state.bogieType;
    } else if (type === 'gearbox') {
      values.numStages = this.state.numStages || 'One';
    }

    this.props.createComponent(type, values);
    this.props.toggleModal();
  }

  edit(values) {
    this.props.pullBack(values);
    console.log('VALUES', values);
    values.oemId = this.props.oemId;
    const { type } = this.state;
    if (type === 'bogie') {
      values.type = this.state.bogieType;
      if (values.type === 'TRAILER') {
        values.numGearboxes = 0;
        values.numMotors = 0;
      }
    } else if (type === 'gearbox') {
      values.numStages = this.state.numStages || 'One';
    }

    this.props.editComponent(type, values);
    this.props.toggleModal();
  }

  storeInput(key, value) {
    const nextState = this.state;
    nextState.userInputs[key] = value.toLowerCase();
    this.validateForm(nextState);
  }

  validateForm(nextState) {
    const { designations } = nextState;
    const { designation, numAxles, numMotors, numGearboxes } = nextState.userInputs;
    const errors = new Set();
    const validate = (predicate, error) => {
      if (predicate) {
        errors.delete(error);
      } else {
        errors.add(error);
      }
      return predicate;
    };

    if (nextState.type !== '') {
      validate(isNotEmpty(designation), validatorErrors.designationEmpty);
    }

    if (nextState.type === 'bogie') {
      validate(true, validatorErrors.componentTypeNotSelected);
      validate(isNotEmpty(numAxles), validatorErrors.numAxlesEmpty) && // eslint-disable-line
        validate(isPositiveInteger(numAxles), validatorErrors.numAxlesInvalid);
      if (nextState.bogieType === 'MOTOR') {
        validate(true, validatorErrors.bogieTypeNotSelected);
        validate(isNotEmpty(numMotors), validatorErrors.numMotorsEmpty) && // eslint-disable-line
          validate(isPositiveInteger(numMotors), validatorErrors.numMotorsInvalid);
        validate(isNotEmpty(numGearboxes), validatorErrors.numGearboxesEmpty) && // eslint-disable-line
          validate(isPositiveInteger(numGearboxes), validatorErrors.numGearboxesInvalid);
      } else if (nextState.bogieType === 'TRAILER') {
        validate(true, validatorErrors.bogieTypeNotSelected);
      } else {
        validate(false, validatorErrors.bogieTypeNotSelected);
      }
    } else if (nextState.type === 'gearbox') {
      validate(!designations.gearbox.has(designation), validatorErrors.designationInvalid);
      validate(true, validatorErrors.componentTypeNotSelected);
      if (nextState.numStages) {
        validate(true, validatorErrors.numStagesNotSelected);
      } else {
        validate(false, validatorErrors.numStagesNotSelected);
      }
    } else if (nextState.type === 'motor') {
      validate(!designations.motor.has(designation), validatorErrors.designationInvalid);
      validate(true, validatorErrors.componentTypeNotSelected);
    } else {
      validate(false, validatorErrors.componentTypeNotSelected);
    }

    this.setState({ ...nextState, errors });
    this.forceUpdate();
  }

  renderErrors() {
    const data = Array.from(this.state.errors).map(item => ({ key: item }));
    return (
      <div style={styles.errorContainer}>
        {data.map(i => (
          <p style={styles.errorText} key={i.key}>{i.key}</p>
        ))}
      </div>
    );
  }

  render() {
    const { handleSubmit } = this.props;
    const form = (
      <div style={{ flex: 1, padding: 16 }}>
        <form>
          <div style={{ flexDirection: 'column' }}>
            {(!this.props.data || !this.props.data.componentType) && (
              <Item inlineLabel>
                <Label>Component type</Label>
                <DropdownSelect
                  placeholder="Select type"
                  mode="dropdown"
                  selectedValue={this.state.type}
                  onChange={e => this.validateForm({ ...this.state, type: e.target.value })}
                  values={[
                    { value: 'bogie', label: 'Bogie', },
                    { value: 'gearbox', label: 'Gearbox', },
                    { value: 'motor', label: 'Motor', },
                  ]}
                />
              </Item>
            )}

            {!!this.state.type && (
              <React.Fragment>
                <FormField name="designation" label="Designation" />

                {this.state.type === 'gearbox' && (
                  <Item inlineLabel>
                    <Label>Stages</Label>

                    <DropdownSelect
                      placeholder="Stages"
                      mode="dropdown"
                      selectedValue={this.state.numStages}
                      onChange={e => this.validateForm({ ...this.state, numStages: e.target.value })}
                      values={[
                        { value: 'One', label: 'One', },
                        { value: 'Two', label: 'Two', },
                        { value: 'Other', label: 'Other', },
                        { value: 'Unknown', label: 'Unknown', },
                      ]}
                    />
                  </Item>
                )}

                {this.state.type === 'bogie' && (
                  <React.Fragment>
                    <Item inlineLabel>
                      <Label>Bogie type</Label>

                      <DropdownSelect
                        placeholder="Select bogie type"
                        mode="dropdown"
                        selectedValue={this.state.bogieType}
                        onChange={e => this.validateForm({ ...this.state, bogieType: e.target.value })}
                        values={[
                          { value: 'TRAILER', label: 'Trailer', },
                          { value: 'MOTOR', label: 'Motor', },
                        ]}
                      />
                    </Item>

                    <FormField name="numAxles" label="Nr. of axles" />

                    {this.state.bogieType === 'MOTOR' && (
                      <React.Fragment>
                        <FormField name="numMotors" label="Nr. of motors" />
                        <FormField name="numGearboxes" label="Nr. of gearboxes" />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}

                {/* MotorClass doesn't have bearings, only Motor instance. */}
                {false && this.state.type === 'motor' && (
                  <React.Fragment>
                    <TRow>
                      <TCell>
                        <FormField
                          name="bearing[]"
                          label="Bearing #1"
                        />
                      </TCell>
                      <TCell>
                        <FormField
                          name="bearingPosition[]"
                          label="Bearing #1 position (DE / NDE)"

                        />
                      </TCell>
                    </TRow>
                    <TRow>
                      <TCell>
                        <FormField
                          name="bearing[]"
                          label="Bearing #2"
                        />
                      </TCell>
                      <TCell>
                        <FormField
                          name="bearingPosition[]"
                          label="Bearing #2 position (DE / NDE)"
                        />
                      </TCell>
                    </TRow>
                    <TRow>
                      <TCell>
                        <FormField
                          name="bearing[]"
                          label="Bearing #3"
                        />
                      </TCell>
                      <TCell>
                        <FormField
                          name="bearingPosition[]"
                          label="Bearing #3 position (DE / NDE)"
                        />
                      </TCell>
                    </TRow>
                  </React.Fragment>
                )}

                <FormField name="description" label="Description" />
              </React.Fragment>
            )}
            {/* <div>{this.state.errors.size > 0 && this.renderErrors()}</div> */}
          </div>
        </form>
      </div>
    );

    if (this.props.data) {
      return (
        <ModalForm
          title="Edit component"
          edit={this.props.data.id}
          navigation={this.props.navigation}
          form={form}
          // disabled={this.state.errors.size > 0}
          onSave={handleSubmit(this.edit.bind(this))}
          onCancel={() => this.props.toggleModal()}
        />
      );
    }
    return (
      <ModalForm
        title="Add component"
        navigation={this.props.navigation}
        form={form}
        // disabled={this.state.errors.size > 0}
        onSave={handleSubmit(this.create.bind(this))}
        onCancel={() => this.props.toggleModal()}
      />
    );
  }
}

function mapState(state, ownProps) {
  const data = ownProps.data || {};
  Object.keys(data).map(v => {
    // Convert "numMotors" etc fields to strings; Input field doesn't accept numbers
    if (typeof data[v] === 'number') {
      data[v] = data[v].toString();
    }

    return v;
  });

  const { componentReducer } = state;
  return {
    component: componentReducer,
    initialValues: {
      ...data
    }
  };
}

function mapDispatch(dispatch) {
  return {
    ...bindActionCreators({ ...componentActions }, dispatch),
    dispatch
  };
}

const AddComponentClassContainerForm = reduxForm({
  form: 'createComponentForm'
})(AddComponentClassForm);

export default connect(
  mapState,
  mapDispatch
)(AddComponentClassContainerForm);
