import * as React from 'react';
import Autosuggest from 'react-autosuggest';
import { connect } from 'react-redux';
import { Button } from 'dl-web-components';
import { reduxForm } from 'redux-form';
import { sortBy } from 'lodash';
import * as productActions from 'redux/modules/product';
import * as errorActions from 'redux/modules/error';
import * as componentActions from 'redux/modules/component';
import colors from 'theme/variables/platform';


class AddComponentForm extends React.PureComponent {
  constructor(props) {
    const { item, value, active, amount } = props;
    super(props);

    console.log(item, value);
    this.state = {
      query: (item && (item.name || item.designation)) || value,
      valid: false,
      active: !!active,
      amount: amount || 1,
      disabled: false,
      confirmDialogVisible: false,
      callback: null,
      suggestions: [],
    };
  }

  componentDidMount() {
    const { products, type, fetchProducts } = this.props;

    if (!products.loading && (!products.products.length || products.loadedType !== type)) {
      fetchProducts(type);
    }
  }

  getSuggestions(value) {
    let { products: { products }, subtype } = this.props;

    products = sortBy(products, ['name', 'designation']);
    if (subtype) {
      // Motor / trailer bogie
      products = products.filter(v => v.type === subtype);
    }

    console.log(value, products);
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : products.filter(p => (
      (p.name || p.designation).toLowerCase().slice(0, inputLength) === inputValue
    ));
  }

  handleButtonClick(state) {
    this.setState({ ...state });

    if (window.confirm(state.confirmMessage)) {
      state.callback();
    }
  }

  delete() {
    const { item, fleetId, type, removeComponent } = this.props;
    this.setState({ disabled: true });
    console.log('DeletingComponent item', item);


    if (type === 'bogie') {
      removeComponent(item.bogieClass.id, fleetId, type);
    } else {
      removeComponent(item, fleetId, type);
    }
  }

  switchComponentClass() {
    const { valid, selected, amount } = this.state;
    const { unknown, fleetId, type, item, switchComponentClass } = this.props;

    if (!valid) {
      return;
    }

    this.setState({ disabled: true });

    const selectedComponent = selected;

    if (unknown) {
      selectedComponent.type = 'UNKNOWN';
    }

    switchComponentClass(type, item, selectedComponent, fleetId, amount);
  }

  addComponent() {
    const { valid, selected } = this.state;
    const { unknown, fleetId, type, addComponent } = this.props;
    if (!valid) {
      return;
    }

    this.setState({ disabled: true });

    const selectedComponent = selected;

    if (unknown) {
      selectedComponent.type = 'UNKNOWN';
    }

    addComponent(type, selectedComponent, fleetId);
  }

  render() {
    const { active, query, disabled, valid, suggestions } = this.state;
    const { type, subtype, item, value } = this.props;

    let products = sortBy(this.props.products.products, ['name', 'designation']);
    if (subtype) {
      // Motor / trailer bogie
      products = products.filter(v => v.type === subtype);
    }

    if (!products.length) {
      return <React.Fragment />;
    }
    return (
      <div>
        {!active && (
          <p onClick={() => this.setState({ active: true })} style={{ color: '#007AFF' }}>
            Add {type}
          </p>
        )}
        {active && (
          <div>
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={s => {
                this.setState({
                  suggestions: this.getSuggestions(s.value)
                });
              }}
              onSuggestionsClearRequested={e => {
                this.setState({
                  suggestions: []
                });
              }}
              getSuggestionValue={e => e.name || e.designation}
              renderSuggestion={e => {
                return <div>{e.name || e.designation}</div>;
              }}
              inputProps={{
                placeholder: 'Name',
                value: query,
                onChange: (event, { newValue }) => {
                  this.setState({
                    query: newValue,
                  });
                }
              }}
              onSuggestionSelected={(event, { suggestion }) => {
                this.setState({
                  selected: suggestion,
                  valid: true,
                });
              }}
            />
            <div
              style={{
                flex: 1,
                paddingTop: 12,
                flexDirection: 'row',
              }}
            >
              {item.id && (
                <Button
                  small
                  disabled={disabled || !valid}
                  onClick={() => {
                    this.handleButtonClick({
                      confirmDialogVisible: true,
                      confirmMessage: 'Are you sure you wish to change this data?',
                      actionMessage: 'Saving, please wait...',
                      label: 'Overwrite',
                      callback: this.switchComponentClass.bind(this),
                    });
                  }}
                >
                  <span style={{ color: !disabled || valid ? 'white' : 'lightgray' }}>Change</span>
                </Button>
              )}
              {!item.id && (
                <Button
                  disabled={disabled || !valid}
                  onClick={() => {
                    this.handleButtonClick({
                      confirmDialogVisible: true,
                      confirmMessage: 'Are you sure you wish to add this data?',
                      actionMessage: 'Saving, please wait...',
                      label: 'Add',
                      callback: this.addComponent.bind(this),
                    });
                  }}
                >
                  <span style={{ color: !disabled || valid ? 'white' : 'lightgray' }}>Add</span>
                </Button>
              )}
              {item.id && value !== 'Unknown' && (
                <Button
                  small
                  style={{
                    marginLeft: 32,
                    backgroundColor: colors.btnDangerBg,
                  }}
                  onClick={() => {
                    this.handleButtonClick({
                      confirmDialogVisible: true,
                      confirmMessage: 'Are you sure you wish to delete this data?',
                      actionMessage: 'Deleting, please wait...',
                      label: 'Delete',
                      callback: this.delete.bind(this),
                    });
                  }}
                  disabled={disabled}
                >
                  <span style={{ color: !disabled ? 'white' : 'lightgray' }}>Delete</span>
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapState(state) {
  return {
    err: state.errorReducer,
    products: state.productReducer,
    initialValues: {
      amount: '1',
    },
  };
}

function mapDispatch(dispatch) {
  return {
    fetchProducts: type => dispatch(productActions.fetchProducts(type)),
    switchComponentClass: (type, components, component, fleetId, amount) =>
      dispatch(componentActions.switchComponentClass(type, components, component, fleetId, amount)),
    addComponent: (type, component, fleetId) => dispatch(componentActions.addComponent(type, component, fleetId)),
    removeComponent: (components, fleetId, type) =>
      dispatch(componentActions.removeComponent(components, fleetId, type)),
    clearError: () => dispatch(errorActions.clearError()),
  };
}

const AddComponentFormContainer = reduxForm({
  form: 'createComponentForm',
})(AddComponentForm);

export default connect(
  mapState,
  mapDispatch,
)(AddComponentFormContainer);
