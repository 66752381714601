import * as constants from './paths';
import * as errorActions from './error';

const SAVE_SETTINGS = 'railway/userSettings/SAVE_SETTINGS';
const SAVE_SETTINGS_SUCCESS = 'railway/userSettings/SAVE_SETTINGS_SUCCESS';
const SAVE_SETTINGS_FAIL = 'railway/userSettings/SAVE_SETTINGS_FAIL';
const LOAD_SETTINGS = 'railway/userSettings/LOAD_SETTINGS';
const LOAD_SETTINGS_SUCCESS = 'railway/userSettings/LOAD_SETTINGS_SUCCESS';
const LOAD_SETTINGS_FAIL = 'railway/userSettings/LOAD_SETTINGS_FAIL';

const initialState = {
  distanceUnit: 'km',
  monthsBeforeMaintenanceDueMessage: 12
};

export default function user(state = initialState, action = {}) {
  switch (action.type) {
    case SAVE_SETTINGS_SUCCESS:
    case LOAD_SETTINGS_SUCCESS:
      return {
        ...state,
        distanceUnit: action.result.distanceUnit,
        monthsBeforeMaintenanceDueMessage: action.result.monthsBeforeMaintenanceDueMessage
      };
    default:
      return state;
  }
}

export function saveSettings(data) {
  return {
    types: [SAVE_SETTINGS, SAVE_SETTINGS_SUCCESS, SAVE_SETTINGS_FAIL],
    promise: (client, dispatch) => {
      return client
        .put(`${constants.MUTATE_PATH}/settings`, { data })
        .then(() => Promise.resolve(data))
        .catch(e => {
          dispatch(errorActions.errorMessage('Error saving user settings.'));
          return Promise.reject(new Error(e));
        });
    }
  };
}

export function loadSettings() {
  return {
    types: [LOAD_SETTINGS, LOAD_SETTINGS_SUCCESS, LOAD_SETTINGS_FAIL],
    promise: (client, dispatch) => {
      return client
        .get(`${constants.RAILWAY_PATH}/settings`)
        .then(values => Promise.resolve(values || initialState))
        .catch(e => {
          dispatch(errorActions.errorMessage('Error loading user settings.'));
          return Promise.reject(new Error(e));
        });
    }
  };
}
