
export default class UserSession {
  constructor({ IdToken, RefreshToken, AccessToken, ClockDrift } = {}) {
    if (AccessToken == null || IdToken == null) {
      throw new Error('Id token and Access Token must be present.');
    }

    this.idToken = IdToken;
    this.refreshToken = RefreshToken;
    this.accessToken = AccessToken;
    this.clockDrift = ClockDrift === undefined ? this.calculateClockDrift() : ClockDrift;
  }

  getIdToken() {
    return this.idToken;
  }

  getRefreshToken() {
    return this.refreshToken;
  }

  getAccessToken() {
    return this.accessToken;
  }

  getClockDrift() {
    return this.clockDrift;
  }

  calculateClockDrift() {
    const now = Math.floor(new Date() / 1000);
    const iat = Math.min(this.accessToken.getIssuedAt(), this.idToken.getIssuedAt());

    return now - iat;
  }

  isValid() {
    const now = Math.floor(new Date() / 1000);
    const adjusted = now - this.clockDrift;

    return adjusted < this.accessToken.getExpiration() && adjusted < this.idToken.getExpiration();
  }
}
