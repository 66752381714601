import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { TextInput } from 'dl-web-components';

export default class SearchBar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.clearAndFocusSearch = this.clearAndFocusSearch.bind(this);
    this.onChangeTextDelayed = debounce(this.setSearch.bind(this), 1000);
  }

  setSearch(value) {
    console.log(value);
    this.props.setSearch(value);
  }

  clearAndFocusSearch() {
    // this.textInput._root.clear(); // eslint-disable-line no-underscore-dangle
    this.props.setSearch('');
    // this.textInput._root.focus(); // eslint-disable-line no-underscore-dangle
  }

  render() {
    const { autoFocus } = this.props;
    return (
      <div>
        {/* <Icon active name="search" /> */}
        <TextInput
          // ref={c => {
          //   this.textInput = c;
          // }}
          placeholder="Search"
          onChange={e => this.onChangeTextDelayed(e.target.value)}
          autoFocus={autoFocus}
        />
        {/* <Icon
          name="close"
          onClick={() => {
            setSearch('');
            this.textInput._root.clear(); // eslint-disable-line no-underscore-dangle
          }}
        /> */}
      </div>
    );
  }
}

SearchBar.propTypes = {
  setSearch: PropTypes.func,
  autoFocus: PropTypes.bool
};

SearchBar.defaultProps = {
  autoFocus: false
};
