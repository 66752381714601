import React, { PureComponent } from 'react';
import { Button, Modal } from 'dl-web-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { sortBy } from 'lodash';
import moment from 'moment';
import ProjectedDate from 'components/ProjectedDate';
import UserSettingsForm from 'container/UserSettingsForm';
import FavoriteIcon, { IconSize } from 'components/FavoriteIcon';
import ContentContainer from 'components/ContentContainer';
import Content from 'container/Content';
import Menu from 'container/Menu';

const StyledContent = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  background-color: white;
`;

const StyledList = styled.ul`
  background-color: rgb(72, 90, 100);
  margin: 10px;
  padding: .5rem;
  max-height: 400px;
  overflow: auto;
  a {
    color: white;
  }
  li {
    padding: .5rem;
  }
`;

class HomePage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { settingsOpen: false };
  }

  render() {
    const { settingsOpen } = this.state;
    if (!this.props.operators || !this.props.fleets || !this.props.maintenances) {
      return <p>Loading home</p>;
    }

    const loading = this.props.favorites.loading || this.props.maintenances.loading;

    const favoriteOperators = this.props.favorites.operators.map(val => {
      return val.operator;
    });

    const favoriteFleets = this.props.favorites.fleets.map(val => {
      return val.fleet;
    });

    const lastUpdatedFleets = sortBy(this.props.favorites.fleets, [f => f.fleet.updatedAt])
      .reverse()
      .slice(0, 5);

    const maintenances = Array.isArray(this.props.maintenances.maintenances)
      ? this.props.maintenances.maintenances
      : [];

    const sortedMaintenances = sortBy(maintenances, maintenance => new Date(maintenance.maintenanceDate));

    return (
      <Content>
        <Menu />

        <h2>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Button icon="cog" variant="plain" onClick={() => this.setState({ settingsOpen: !settingsOpen })} />
            {settingsOpen && (
              <Modal style={{ maxHeight: '90%' }} toggleModal={() => this.setState({ settingsOpen: !settingsOpen })}>
                <UserSettingsForm toggleModal={() => this.setState({ settingsOpen: !settingsOpen })} />
              </Modal>
            )}

            {/*  <Button icon="info" variant="plain" onClick={() => this.setState({ privacyOpen: !privacyOpen })} />
            {privacyOpen && (
              <Modal style={{ maxHeight: '90%' }} toggleModal={() => this.setState({ privacyOpen: !privacyOpen })}>
                <PrivacyPolicy />
              </Modal>
            )} */}
          </div>
        </h2>

        <StyledContent>
          <ContentContainer>
            <div>Favorite operators</div>
            <div>
              <StyledList>
                {!favoriteOperators.length &&
                  !loading &&
                  (
                    <Link to="operators">
                      <div>
                        <p>No favorites</p>
                        <p>
                          Mark your favorites with{' '}
                          <FavoriteIcon
                            favorite={true}
                            iconSize={IconSize.Small}
                          />
                        </p>
                      </div>
                    </Link>
                  )}
                {favoriteOperators.map((item, i) => (
                  <Link
                    key={i}
                    to={`/operators/${item.id}`}
                  >
                    <div>
                      <b>{item.name}</b>
                      <p>
                        {item.fleetCount} fleets, {item.trainCount} vehicles
                      </p>
                    </div>
                  </Link>
                ))}
              </StyledList>
            </div>
          </ContentContainer>

          <ContentContainer>
            <div>Favorite fleets</div>
            <div>
              <StyledList>
                {!favoriteFleets.length &&
                  !loading &&
                  (
                    <Link to="OperatorsPage">
                      <div>
                        <p>No favorites</p>
                        <p>
                          Mark your favorites with{' '}
                          <FavoriteIcon
                            favorite={true}
                            iconSize={IconSize.Small}
                          />
                        </p>
                      </div>
                    </Link>
                  )}

                {favoriteFleets.map((fleet, i) => (
                  <Link
                    key={i}
                    to={`/operators/${fleet.operatorId}/fleets/${fleet.id}`}
                  >
                    <div>
                      <b>
                        {fleet.name} <p>({fleet.operator.name})</p>
                      </b>
                      <p>
                        {fleet.trainCount} vehicles, {fleet.trainClass.family}
                      </p>
                    </div>
                  </Link>
                ))}
              </StyledList>
            </div>
          </ContentContainer>
        </StyledContent>

        <StyledContent>
          <ContentContainer>
            <div>Upcoming maintenances</div>
            <div>
              <StyledList>
                {!sortedMaintenances.length &&
                  !loading &&
                  (
                    <Link to="/operators">
                      <div>
                        <p>No upcoming maintenances</p>
                        <p>(for favorite fleets)</p>
                      </div>
                    </Link>
                  )}
                {Array.isArray(sortedMaintenances) &&
                  sortedMaintenances.map(({ fleetId: id, fleetName, maintenanceDate, operatorId }, i) => (
                    <Link
                      key={id + i}
                      to={`/operators/${operatorId}/fleets/${id}`}
                    >
                      <div>
                        <b>{fleetName}</b>
                        <p>
                          <ProjectedDate style={{ color: 'white' }} date={new Date(maintenanceDate)} />
                        </p>
                      </div>
                    </Link>
                  ))}
              </StyledList>
            </div>
          </ContentContainer>

          <ContentContainer>
            <div>Recently updated favorites</div>
            <div>
              <StyledList>
                {lastUpdatedFleets.map((item, i) => (
                  <Link
                    key={i}
                    to={`/operators/${item.fleet.operatorId}/fleets/${item.fleet.id}`}
                  >
                    <div>
                      <b>{item.fleet.name}</b>
                      <p>Updated at {moment(item.fleet.updatedAt).format('D.M.Y')}</p>
                    </div>
                  </Link>
                ))}
              </StyledList>
            </div>
          </ContentContainer>
        </StyledContent>
      </Content>
    );
  }
}

export default HomePage;
