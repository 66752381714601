import * as React from 'react';
import { connect } from 'react-redux';
import ComponentPage from 'stories/screens/ComponentPage';
import * as componentActions from 'redux/modules/component';
import * as oemActions from 'redux/modules/oems';
import * as updatedActions from 'redux/modules/updated';
import { PropTypes } from 'prop-types';

class ComponentPageContainer extends React.PureComponent {
  componentDidMount() {
    if (this.props.authenticated && !this.props.componentReducer.loading) {
      this.props.fetchComponentUsage(this.props.match.params.componentId, this.props.match.params.type);
      const oemId = this.props.match.params.id; // depending on where you come: first one is from oempage, second one is from searchpage
      this.props.fetchOem(oemId);
      this.props.fetchUpdatedForComponent(this.props.match.params.id);
    }
  }

  render() {
    return (
      <ComponentPage
        type={this.props.match.params.type}
        component={this.props.componentReducer.component}
        loading={this.props.componentReducer.loading}
        operators={this.props.componentReducer.operators}
        fleets={this.props.componentReducer.fleets}
        oem={this.props.oem}
        updated={this.props.updated}
      />
    );
  }
}

function bindAction(dispatch) {
  return {
    fetchComponentUsage: (component, type) => dispatch(componentActions.fetchComponent(component, type)),
    fetchOem: oem => dispatch(oemActions.fetchOem(oem)),
    fetchUpdatedForComponent: componentId => dispatch(updatedActions.fetchUpdatedForComponent(componentId)),
  };
}

const mapStateToProps = state => {
  return {
    componentReducer: state.componentReducer,
    authenticated: state.authReducer.authenticated,
    oem: state.oemsReducer.oem,
    updated: state.updatedReducer.updated,
  };
};

ComponentPageContainer.propTypes = {
  navigation: PropTypes.any,
  fetchComponentUsage: PropTypes.func,
  componentReducer: PropTypes.object,
  fetchOem: PropTypes.func,
  oem: PropTypes.any,
  authenticated: PropTypes.any,
  fetchUpdatedForComponent: PropTypes.func,
  updated: PropTypes.array,
};

export default connect(mapStateToProps, bindAction)(ComponentPageContainer);
