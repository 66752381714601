import * as maintenanceActions from 'redux/modules/maintenance';
import * as errorActions from './error';
import * as constants from './paths';

const LOAD = 'railway/favorite/LOAD';
const LOAD_SUCCESS = 'railway/favorite/LOAD_SUCCESS';
const LOAD_FAIL = 'railway/favorite/LOAD_FAIL';

const FAVORITE = 'railway/operator/FAVORITE';
const FAVORITE_SUCCESS = 'railway/operator/FAVORITE_SUCCESS';
const FAVORITE_FAIL = 'railway/operator/FAVORITE_FAIL';

const initialState = {
  loaded: false,
  loading: false,
  operators: [],
  fleets: []
};

export default function favorite(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case `${LOAD_SUCCESS}_OPERATORS`:
      return {
        ...state,
        loading: false,
        loaded: true,
        operators: action.result
      };
    case `${LOAD_SUCCESS}_FLEETS`:
      return {
        ...state,
        loading: false,
        loaded: true,
        fleets: action.result
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    default:
      return state;
  }
}

export function fetchFavorites(type) {
  return {
    types: [LOAD, `${LOAD_SUCCESS}_${type.toUpperCase()}`, LOAD_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject(new Error('Not authenticated'));
      }

      return client.get(`${constants.RAILWAY_PATH}/favorites/${type}`).catch(e => {
        dispatch(errorActions.errorMessage('Error fetching favorites.'));
        return Promise.reject(new Error(e));
      });
    }
  };
}

export function toggleFavorite(targetId, type, favorite = false) {
  return {
    types: [FAVORITE, FAVORITE_SUCCESS, FAVORITE_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject();
      }

      const method = favorite ? 'del' : 'put';

      let path;
      if (method === 'put') {
        path = `${constants.MUTATE_PATH}/favorites/${type}/${targetId}`;
      } else {
        path = `${constants.MUTATE_PATH}/favorite/${targetId}`;
      }

      return client[method](path)
        .then(() => {
          dispatch(fetchFavorites(type));
          dispatch(maintenanceActions.fetchProjectedMaintenances());
        })
        .catch(e => {
          dispatch(errorActions.errorMessage('Error toggling favorite.'));
          return Promise.reject(new Error(e));
        });
    }
  };
}
