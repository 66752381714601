import superagent from 'superagent';
import { getSessionToken } from 'redux/modules/auth';

const { REACT_APP_API_ENDPOINT } = process.env;

const methods = ['get', 'post', 'put', 'patch', 'del'];

function formatUrl(path, local = false) {
  let adjustedPath = path[0] !== '/' ? `/${path}` : path;

  if (!local && path.indexOf('http') !== 0) {
    adjustedPath = `${REACT_APP_API_ENDPOINT}${adjustedPath}`;
    return adjustedPath;
  }

  return path;
}

export default class ApiClient {
  constructor() {
    methods.forEach(method => // eslint-disable-line
      this[method] = async (path, { params, data, local, isAccessToken = true } = {}) => {
        const token = await getSessionToken(isAccessToken);
        return new Promise((resolve, reject) => {
          const request = superagent[method](formatUrl(path, local));

          if (params) {
            request.query(params);
          }

          if (data) {
            request.send(data);
          }

          if (token) {
            request.set('Authorization', `${token}`);
          }

          request.end((err, { body } = {}) => err ? reject(body || err) : resolve(body)); // eslint-disable-line
        });
      });
  }
  /*
   * There's a V8 bug where, when using Babel, exporting classes with only
   * constructors sometimes fails. Until it's patched, this is a solution to
   * "ApiClient is not defined" from issue #14.
   * https://github.com/erikras/react-redux-universal-hot-example/issues/14
   *
   * Relevant Babel bug (but they claim it's V8): https://phabricator.babeljs.io/T2455
   *
   * Remove it at your own risk.
   */
  empty() { }
}
