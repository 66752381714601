import React from 'react';
import styled from 'styled-components';

const DateText = styled.p`
  color: #007AFF;
`;

export interface Props {
  date: Date;
}

export default ({ date, ...rest }) => (
  <DateText {...rest}>{`${date.toLocaleString('en-us', { month: 'long' })} ${date.getFullYear()}`}</DateText>
);
