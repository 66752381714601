import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Loader } from 'dl-web-components';
import * as authActions from 'redux/modules/auth';
import * as redirectActions from 'redux/modules/redirect';

function mapState(state) {
  const { authReducer } = state;

  return {
    auth: authReducer
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({ ...authActions, ...redirectActions }, dispatch)
  };
}

class LoginView extends Component {
  constructor(props) {
    super(props);

    this.state = { loading: false };
  }

  componentWillMount() {
    if (this.props.location.hash.indexOf('#token=') === 0) {
      const hashObj = this.props.location.hash.replace('#', '').split('&').reduce((prev, item) => Object.assign({ [item.split('=')[0]]: item.split('=')[1] }, prev), {});

      this.props.actions.setTokens(hashObj);
      this.props.actions.getLoginStatus();

      this.setState({ loading: true });
    }
  }

  render() {
    if (this.props.auth.authenticated) {
      this.props.actions.redirectTo('/dashboard');
      return <React.Fragment />;
    }

    if (this.props.auth.loading || this.state.loading) {
      return <Loader />;
    }

    this.props.actions.redirectTo(`${process.env.REACT_APP_SSO_URL}/?app=${process.env.REACT_APP_SSO_APPKEY}`);

    return (
      <React.Fragment />
    );
  }
}

LoginView.propTypes = {
  actions: PropTypes.object,
  auth: PropTypes.object,
  location: PropTypes.object,
};

export default connect(mapState, mapDispatch)(LoginView);
