
export const colors = {
  white: 'rgba(255,255,255,1)',
  black: 'rgba(68,68,70,1)',
  gray: 'rgba(101,92,102,1)',
  darkestGray: 'rgba(74,89,107,1)', // SKF Clay
  darkGray: 'rgba(95,95,100,1)', // SKF Stone
  lightGray: 'rgba(132,141,151,1)', // SKF Gray
  lighterGray: 'rgba(220,224,225,1)', // SKF Steel
  lightestGray: 'rgba(235,240,240,1)', // SKF Cloud
  modalOverlay: 'rgba(220,224,225,0.8)', // SKF Steel 80% opacity
  // darkGray: 'rgba(125,134,139,1)',
  // lightGray: 'rgba(189,189,189,1)',
  // lighterGray: 'rgba(236,244,244,1)',
  // lightestGray: 'rgba(246,246,246,1)',
  blue: 'rgba(15,88,214,1)', // SKF Blue
  lightBlue: 'rgba(1,1,1,1)',
  // red: 'rgba(214,39,48,1)',
  red: 'rgba(235,32,42,1)', // SKF Red
  // green: 'rgba(127,185,65,1)',
  green: 'rgba(136,192,8,1)', // SKF Green
  // yellow: 'rgba(249,210,41,1)'
  yellow: 'rgba(255,214,21,1)', // SKF Yellow
  orange: 'rgba(255,128,4,1)', // SKF Orange
  purple: 'rgba(120,30,148,1)' // SKF Purple
};

export const sizes = {
  font: '1rem',
  headerFont: '2rem',
  baseline: '1rem'
};

export const notificationOpts = {
  position: 'tc',
  autoDismiss: 10
};

export const errorNotificationOpts = {
  position: 'tc',
  autoDismiss: 10
};

export const localStorageKeys = {
  userLocale: 'userLocale'
};

export const languages = {
  'de-DE': 'Deutsch',
  'en-US': 'English',
  'fi-FI': 'Suomi'
};

export const defaultLanguages = {
  de: 'de-DE',
  en: 'en-US',
  fi: 'fi-FI'
};

export const defaultLanguage = 'en-US';
