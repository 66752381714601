import React, { PureComponent } from 'react';
import { Button } from 'dl-web-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TCell from 'components/TCell';
import TRow from 'components/TRow';
import AddComponentForm from 'container/AddComponentForm';

const RootElement = styled.div`
  margin-left: 0;
`;

const Left = styled.div``;
const Right = styled.div``;

const unknownBogie = {
  designation: 'Unknown',
  description: '-',
  oem: {
    name: 'Unknown'
  },
  unknown: true
};

export default class TrainBogie extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { editing: false };
  }

  render() {
    const { type, bogie, fleet } = this.props;

    const bogieClass = bogie.bogieClass || unknownBogie;
    console.log('BOGIE', bogieClass, type);

    if (bogie.amountPerVehicle === 0) {
      bogie.amountPerVehicle = '-';
    }

    return (
      <RootElement style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          {!this.state.editing ? (
            <Left>
              {bogieClass.unknown || bogieClass.designation === 'Unknown' || !bogieClass.designation ? (
                <span style={{ fontSize: 22, paddingRight: '1rem' }}>{bogieClass.designation || 'Unknown'}</span>
              )
                :
                (
                  <Link
                    style={{ color: '#007AFF', cursor: 'pointer', fontSize: 22, paddingRight: '1rem' }}
                    to={`/oems/unknown/components/bogieClass/${bogieClass.id}`}
                  >
                    {bogieClass.designation}
                  </Link>
                )}
            </Left>
          )
            :
            (
              <AddComponentForm
                unknown={bogieClass.unknown}
                value={bogieClass.designation}
                type="bogie"
                subtype={type}
                item={bogie}
                fleetId={fleet.id}
                active={true}
              />
            )}
          <Right>
            <Button
              size="sm"
              onClick={() => this.setState({ editing: !this.state.editing })}
            >
              {/* <Icon active name="settings" style={{ color: '#007AFF', cursor: 'pointer', fontSize: 20 }} /> */}
              {this.state.editing ? 'Cancel' : 'Edit'}
              {'\n'}
            </Button>
          </Right>
        </div>

        <div style={{ width: '100%' }}>
          <TRow>
            <TCell multiline>
              <div>
                <p>
                  OEM
                </p>
              </div>
              {bogieClass.unknown || bogieClass.designation === 'Unknown' || !bogieClass.designation ? (
                <p>{bogieClass.designation || 'Unknown'}</p>
              )
                :
                (
                  <Link
                    style={{ color: '#007AFF', cursor: 'pointer' }}
                    to={bogieClass.oem ? `/oems/${bogieClass.oem.id}` : '#'}
                  // onClick={() => go(navigation, 'OemBogiesPage', { oem: bogieClass.oem })}
                  >
                    {bogieClass.oem ? `${bogieClass.oem.name}` : '–'}
                  </Link>
                )}
            </TCell>
            <TCell multiline>
              <div>
                <p note>
                  Amount / Vehicle
                </p>
              </div>
              <p>{bogie.amountPerVehicle}</p>
            </TCell>
          </TRow>

          <TRow>
            <TCell multiline>
              <div>
                <p note>
                  Description
                </p>
              </div>
              <p>{bogieClass.description || '-'}</p>
            </TCell>
          </TRow>
        </div>
      </RootElement>
    );
  }
}

TrainBogie.propTypes = {
  type: PropTypes.string,
  bogie: PropTypes.object,
  fleet: PropTypes.object
};
