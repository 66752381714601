import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Content from 'container/Content';
import Box from 'components/Box';
import * as redirectActions from 'redux/modules/redirect';
import * as authActions from 'redux/modules/auth';

import { colors } from '../../constants';

const Navi = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0;
  justify-content: flex-end;
  font-family: skf-chevin,Arial,sans-serif;
  text-transform: uppercase;


  @media (max-width: 784px) {
    flex-direction: column;
    li {
      padding-top: .5rem;
      padding-bottom: .5rem;
    }
  }

  li {
    text-align: center;
    padding-left: 2rem;
  }
`;

const Blue = styled.span`
  color: ${colors.blue};
`;

const LightGray = styled.span`
  color: ${colors.lightGray};
`;

const RootElement = styled(Content)`
  ${'' /* > div:first-child {
    padding-top: 1.5rem;
  } */}
  > div > img {
    width: 4rem;
    float: left;
    margin-top: -0.5rem;
    margin-right: 1rem;
  }
  > div > a {
    float: right;
  }
`;

const LinkButton = styled.a`
  background: none;
  border: 0;
  cursor: pointer;
  font-weight: bold;
`;

function mapState(state) {
  const { auth } = state;
  return {
    auth,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({ ...redirectActions, ...authActions }, dispatch)
  };
}


class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdown: false,
    };
  }


  showDropdown = () => {
    this.setState({ dropdown: true })
  }

  hideDropdown = () => {
    this.setState({ dropdown: false })
  }

  signOut() {
    const { actions: { logout, redirectTo } } = this.props;
    logout();
    redirectTo(`${process.env.REACT_APP_SSO_URL}/logout?app=${process.env.REACT_APP_SSO_APPKEY}`);
  }

  highlightIfActive(r, comp) {
    // const route = this.context.router.route.location.pathname;
    const route = false;
    return r === route ? <Blue>{comp}</Blue> : <LightGray>{comp}</LightGray>;
  }

  render() {
    return (
      <RootElement>
        <Box cols={3}>
          <Navi>
            <li>
              <Link className="brand" to="/dashboard">
                {this.highlightIfActive(
                  '/dashboard',
                  'Dashboard'
                )}
              </Link>
            </li>
            <li>
              <Link className="brand" to="/operators">
                {this.highlightIfActive(
                  '/operators',
                  'Operators'
                )}
              </Link>
            </li>
            <li>
              <Link className="brand" to="/oems">
                {this.highlightIfActive(
                  '/oems',
                  'OEMs'
                )}
              </Link>
            </li>
            <li>
              <Link className="brand" to="/products">
                {this.highlightIfActive(
                  '/products',
                  'Products'
                )}
              </Link>
            </li>
            <li>
              <Link className="brand" to="/search">
                {this.highlightIfActive(
                  '/search',
                  'Search'
                )}
              </Link>
            </li>
            <li>
              <LinkButton type="button" className="brand" onClick={this.signOut.bind(this)}>
                Logout
              </LinkButton>
            </li>
          </Navi>
        </Box>
      </RootElement>
    );
  }
}

Menu.propTypes = {
  actions: PropTypes.object,
};

Menu.contextTypes = {
  router: PropTypes.object,
  location: PropTypes.object
};

export default connect(mapState, mapDispatch)(Menu);
