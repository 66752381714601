import React from 'react';
import S3Uploader from 'react-s3-uploader';
// import { DocumentPicker } from 'expo';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSignedDocumentUploadURL, resetUploaded } from 'redux/modules/document';

/**
 * A wrapper component for the S3Uploader used in the WebUI for uploading files to S3.
 */
class DocumentUploaderContainer extends React.Component {
  UNSAFE_componentWillReceiveProps(props) {
    if (props.uploaded) {
      props.resetUploaded();
      props.uploadComplete();
    }
  }

  render() {
    const { fleetId, getURL, uploadDocument, uploading } = this.props;

    if (uploading) {
      return null;
    }

    return (
      <S3Uploader
        getSignedUrl={(file, callback) => {
          console.log(file);
          getURL(fleetId, file);
          uploadDocument(callback);
        }}
        onSignedUrl={console.log}
        onProgress={console.log}
        onError={console.log}
        onFinish={console.log}
        contentDisposition="auto"
        uploadRequestHeaders={{}}
        accept="*"
      />
    );
  }
}

const mapStateToProps = state => {
  const { uploading, uploaded } = state.documentReducer;
  return { uploading, uploaded };
};

const mapDispatchToProps = dispatch => ({
  getURL: (fleetId, callback) => dispatch(getSignedDocumentUploadURL(fleetId, callback)),
  resetUploaded: () => dispatch(resetUploaded()),
});

DocumentUploaderContainer.propTypes = {
  fleetId: PropTypes.string.isRequired,
  getURL: PropTypes.func,
  resetUploaded: PropTypes.func,
  uploadDocument: PropTypes.func.isRequired,
  uploadComplete: PropTypes.func.isRequired,
  uploaded: PropTypes.bool,
  uploading: PropTypes.bool
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentUploaderContainer);
