
export default class JwtToken {
  constructor(token) {
    this.jwtToken = token || '';
    this.payload = this.decodePayload();
  }

  getJwtToken() {
    return this.jwtToken;
  }

  getExpiration() {
    return this.payload.exp;
  }

  getIssuedAt() {
    return this.payload.iat;
  }

  decodePayload() {
    const payload = this.jwtToken.split('.')[1];
    try {
      return JSON.parse(atob(payload).toString('utf8'));
    }
    catch (err) {
      return {};
    }
  }
}
