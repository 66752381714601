import { User } from 'helpers/skf-sso-identity';
import Notifications from 'react-notification-system-redux';
import { notificationOpts, errorNotificationOpts } from '../../constants';

const LOGIN_STATUS = 'catalog/auth/LOGIN_STATUS';
const LOGIN_STATUS_SUCCESS = 'catalog/auth/LOGIN_STATUS_SUCCESS';
const LOGIN_STATUS_FAIL = 'catalog/auth/LOGIN_STATUS_FAIL';

const INVITE = 'railway/auth/INVITE';
const INVITE_SUCCESS = 'railway/auth/INVITE_SUCCESS';
const INVITE_FAIL = 'railway/auth/INVITE_FAIL';

const LOGOUT = 'datalake/auth/LOGOUT';

const SET_TOKEN = 'datalake/auth/SET_TOKEN';

const userSession = new User();

const initialState = {
  loaded: false,
  authenticated: false,
  loading: true,
};

export default function auth(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN_STATUS:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case LOGIN_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        authenticated: true,
        error: false,
        ...action.result
      };
    case LOGIN_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        authenticated: false,
        error: true,
      };
    case LOGOUT:
      userSession.clearCachedTokens();
      return {
        ...state,
        authenticated: false,
        loaded: false,
      };

    case SET_TOKEN:
      console.log(action);
      userSession.setTokens(action.payload);
      return {
        ...state,
      };

    default:
      return state;
  }
}

export function getLoginStatus() {
  return {
    types: [LOGIN_STATUS, LOGIN_STATUS_SUCCESS, LOGIN_STATUS_FAIL],
    promise: client => {
      return client.get(process.env.REACT_APP_SSO_SERVICE_URL, { isAccessToken: true });
    }
  };
}

export function logout() {
  return { type: LOGOUT };
}

export function setTokens(tokens) {
  return {
    type: SET_TOKEN,
    payload: tokens,
  };
}

export function invite(params) {
  return {
    types: [INVITE, INVITE_SUCCESS, INVITE_FAIL],
    promise: (client, dispatch) => {
      return client
        .post('/customer/invite', { data: params })
        .then(() => {
          dispatch(
            Notifications.success({
              title: 'Invite sent',
              ...notificationOpts
            })
          );
        })
        .catch(e => {
          console.log(e);
          dispatch(
            Notifications.error({
              title: e.message,
              ...errorNotificationOpts
            })
          );
        });
    }
  };
}


export async function getSessionToken(isAccessToken) {
  if (!userSession.signInUserSession || !userSession.signInUserSession.idToken) {
    return '';
  }

  if (!userSession.signInUserSession.isValid()) {
    const tokens = await userSession.refreshSession();
    return tokens[isAccessToken ? 'AccessToken' : 'IdToken'];
  }

  return userSession.signInUserSession[isAccessToken ? 'accessToken' : 'idToken'].jwtToken;
}
