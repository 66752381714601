import React, { PureComponent } from 'react';
import {
  Icon,
  Loader as Spinner,
  DropdownSelect
} from 'dl-web-components';
import { Link } from 'react-router-dom';
import { orderBy, camelCase, round } from 'lodash';
import styled from 'styled-components';
import SidebarListItem from 'components/SidebarListItem';
import Sidebar from 'components/Sidebar';
import SearchBar from 'components/SearchBar';
import { exists } from 'helpers/validation';
import { kmToMiles, milesToKm, speedUnit, step } from 'helpers/units';
import FavoriteIcon from 'components/FavoriteIcon';
import Menu from 'container/Menu';

import styles from './styles';

const { REACT_APP_IMAGE_DOMAIN } = process.env;

const Item = styled.div``;
const Label = styled.div``;
const Content = styled.div``;
const List = styled.ul``;
const ListItem = styled(Link)``;
const Body = styled.div``;
const Left = styled.div`
  float:left;
`;
const Right = styled.div``;
const Container = styled.div``;
const Header = styled.div``;
const Title = styled.h2``;

const StyledContent = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
`;

const Heading = styled.p`
  font-size: 20;
  font-weight: bold;
  padding: 15px;
  color: #485a64;
`;

const ListText = styled.p`
  padding-bottom: 8px;
`;

const ContentContainer = styled.div`
  flex: 1;
`;

const SpeedPicker = styled.div`
  > div {
    flex: 3;
  }
`;

class OperatorPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSearch: true,
      showFilters: true,
      favorite: props.favorite,
      sortByNumberOfAllKnownBearings: false,
      trailerBogieBearings: false,
      motorBogieBearings: false,
      gearboxBearings: false,
      motorBearings: false,
      speedSliderValue: Number.POSITIVE_INFINITY,
      vehicleTypeFilter: 'Any',
      vehicleTypes: []
    };
    this.fleetFilter = this.getNewFleetFilter(this.state);

    this.renderCheckBoxWithLabel = this.renderCheckBoxWithLabel.bind(this);
  }

  getNewFleetFilter(state) {
    return item =>
      item.fleetBearings.trailerBogieBearings >= Number(state.trailerBogieBearings) &&
      item.fleetBearings.motorBogieBearings >= Number(state.motorBogieBearings) &&
      item.fleetBearings.gearboxBearings >= Number(state.gearboxBearings) &&
      item.fleetBearings.motorBearings >= Number(state.motorBearings) &&
      item.trainClass.maxSpeed <= milesToKm[this.props.distanceUnit](state.speedSliderValue) &&
      (state.vehicleTypeFilter === 'Any' || item.trainClass.vehicleType.name === state.vehicleTypeFilter);
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { fleets } = props;
    const nextState = fleets.reduce(
      (previous, current) => ({
        maxSpeed: Math.max(previous.maxSpeed, current.trainClass.maxSpeed),
        minSpeed: Math.min(previous.minSpeed, current.trainClass.maxSpeed),
        vehicleTypes:
          previous.vehicleTypes.indexOf(current.trainClass.vehicleType.name) < 0
            ? [...previous.vehicleTypes, current.trainClass.vehicleType.name]
            : previous.vehicleTypes
      }),
      { maxSpeed: Number.NEGATIVE_INFINITY, minSpeed: Number.POSITIVE_INFINITY, vehicleTypes: [] }
    );
    this.setState({ ...nextState, speedSliderValue: round(kmToMiles[props.distanceUnit](nextState.maxSpeed), -1) });
  }

  handleValueChange(key, value) {
    console.log(key, value);
    const nextState = { ...this.state, [key]: value };
    this.fleetFilter = this.getNewFleetFilter(nextState);
    this.setState(nextState);
  }

  renderPicker() {
    return (
      <Item inlineLabel>
        <Label>Filter by vehicle type</Label>
        <DropdownSelect
          name="country"
          placeholder="Any"
          mode="dropdown"
          selectedValue={this.state.vehicleTypeFilter}
          onChange={e => this.handleValueChange('vehicleTypeFilter', e.target.value)}
          values={this.renderSelectItems()}
        />
      </Item>
    );
  }

  renderSelectItems() {
    return ['Any', ...this.state.vehicleTypes].map(item => (
      { label: item, value: item }

      // <Picker.Item key={`selectItem${index}`} label={item} value={item} />
    ));
  }

  renderCheckBoxWithLabel(label, index) {
    const key = camelCase(label);
    return (
      <div
        key={index}
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, marginRight: 20 }}
      >
        <p>
          <label>
            <input type="checkbox" value="true" onChange={e => this.handleValueChange(key, e.target.checked)} />
            {label}
          </label>
        </p>

      </div>
    );
  }

  renderCheckboxes() {
    const labels = ['Trailer wheelset bearings', 'Motor wheelset bearings', 'Gearbox bearings', 'Motor bearings'];
    return (
      <div>
        {this.renderCheckBoxWithLabel('Sort by number of all known bearings', 0)}
        <div
          style={{
            margin: 20,
            marginLeft: 0,
            borderBottomColor: 'lightgrey',
            borderBottomWidth: 1
          }}
        />
        <p style={{ fontWeight: 'bold' }}>Show only fleets with data about:</p>
        {labels.map((item, index) => this.renderCheckBoxWithLabel(item, index))}
      </div>
    );
  }

  renderSpeedPicker() {
    const { distanceUnit } = this.props;
    console.log(this.state);
    const options = [];
    const minimumValue = round(kmToMiles[distanceUnit](this.state.minSpeed), -1);
    const maximumValue = round(kmToMiles[distanceUnit](this.state.maxSpeed), -1);
    for (let value = minimumValue; value <= maximumValue; value += step[distanceUnit]) {
      options.push({
        value, label: `${value} ${speedUnit[distanceUnit]}`
      });
    }

    return (
      <SpeedPicker style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, marginRight: 20 }}>
        <p style={{ flex: 2, width: 300 }}>Maximum speed</p>
        <DropdownSelect
          values={options}
          onChange={e => this.handleValueChange('speedSliderValue', e.target.value)}
          placeholder="Select max speed"
          style={{ width: '200px' }}
        />
      </SpeedPicker>
    );
  }


  render() {
    const param = this.props.match.params;
    const operatorId = param.id;
    const { loaded, onSearch } = this.props;
    const sortingKey = this.state.sortByNumberOfAllKnownBearings ? ['fleetBearings.total', 'name'] : ['name'];
    const sortingOrder = this.state.sortByNumberOfAllKnownBearings ? ['desc', 'asc'] : ['asc'];
    const displayedFleets = orderBy(this.props.fleets.filter(this.fleetFilter), sortingKey, sortingOrder);

    const searchForm = (
      <SearchBar
        setSearch={val => onSearch(val)}
      />
    );

    const ViewContent =
      !loaded || !param || !this.props.fleets ? (
        <Content>
          <Spinner style={{ width: '100%', height: 200, justifyContent: 'flex-end', alignItems: 'center' }} />
        </Content>
      )
        :
        (
          <ContentContainer >
            <div style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <div>
                <Heading>Fleets ({this.props.fleets.length})</Heading>
              </div>
            </div>
            <div>{searchForm}</div>
            <div>
              {this.renderPicker()}
              {this.renderCheckboxes()}
              {this.renderSpeedPicker()}
            </div>
            <List>
              <ListItem />
              {displayedFleets.map((item, i) => (
                <ListItem
                  key={i}
                  to={`/operators/${operatorId}/fleets/${item.id}`}
                >
                  <Left style={{ paddingRight: 60 }}>
                    {item.imageIdentifier && (
                      <img
                        style={{ width: 100, height: 72, marginLeft: 0 }}
                        src={`${REACT_APP_IMAGE_DOMAIN}/${item.imageIdentifier}.jpg`}
                        alt=""
                      />
                    )}
                    {!item.imageIdentifier && (
                      <img
                        style={{ width: 100, height: 72, marginLeft: 0 }}
                        src="https://via.placeholder.com/100x72?text=-"
                        alt=""
                      />
                    )}
                  </Left>
                  <Body style={{ flex: 10 }}>
                    <ListText>{item.name}</ListText>
                    <ListText>
                      {item.trainCount}
                      {' '}
                      vehicles
                    </ListText>
                  </Body>
                  <Right>
                    <Icon name="arrow-forward" style={{ paddingTop: 15 }} />
                  </Right>
                </ListItem>
              ))}
            </List>
          </ContentContainer>
        );

    const isFavorite = exists(this.state.favorite) && !!this.state.favorite;

    return (
      <Container style={styles.container}>
        <Menu />
        <Header>
          <Body style={{ flex: 3, alignItems: 'center' }}>
            <Title>
              {this.props.operator && this.props.operator.name}
              <FavoriteIcon
                favorite={isFavorite}
                onClick={() => {
                  this.setState({ favorite: !this.props.favorite });
                  this.props.toggleFavorite(param.id, this.props.favorite);
                }}
              />
            </Title>
          </Body>

        </Header>

        <StyledContent>
          <Sidebar>
            <div style={{ height: '100%' }}>
              <List>
                <SidebarListItem onClick={() => this.props.switchPage('overview')}>
                  <p style={{ cursor: 'pointer' }}>Overview</p>
                </SidebarListItem>
                <SidebarListItem>
                  <p style={{ color: '#007AFF', cursor: 'pointer' }}>Fleets</p>
                </SidebarListItem>
              </List>

              <List style={{ marginBottom: 0 }}>
                <SidebarListItem itemDivider>
                  <p>Favorite fleets</p>
                </SidebarListItem>
                {this.props.favoriteFleets.map(item => (
                  <SidebarListItem
                    key={item.id}
                    to={`/operators/${operatorId}/fleets/${item.fleet.id}`}
                  >
                    <p>
                      {item.fleet.name}
                    </p>
                  </SidebarListItem>
                ))}
                {this.props.favoriteFleets.length === 0 && (
                  <SidebarListItem>
                    <p>No favorites</p>
                  </SidebarListItem>
                )}
              </List>
            </div>
          </Sidebar>
          {ViewContent}
        </StyledContent>
      </Container>
    );
  }
}

export default OperatorPage;
