import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route, Switch,
  Redirect,
} from 'react-router-dom';
import Notifications from 'react-notification-system-redux';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LoginView from 'container/LoginContainer';
import DashboardView from 'container/HomePageContainer';
import OperatorsView from 'container/OperatorsPageContainer';
import OperatorView from 'container/OperatorPageContainer';
import FleetView from 'container/FleetOverviewPageContainer';
import SearchView from 'container/SearchPageContainer';
import OemsView from 'container/OemsListingPageContainer';
import OemView from 'container/OemBogiesPageContainer';
import ProductsView from 'container/ProductsPageContainer';
import ProductView from 'container/ProductPageContainer';
import ComponentView from 'container/ComponentPageContainer';
import VehicleView from 'container/VehiclePageContainer';

// import Header from 'container/Header';
import Footer from 'container/Footer';

import { Loader, Header } from 'dl-web-components';
import * as redirectActions from 'redux/modules/redirect';
import * as authActions from 'redux/modules/auth';
import GoogleAnalytics from './GoogleAnalytics';
import {
  colors,
} from './constants';

const AppContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex: 1 1 0%;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  ${'' /* overflow-y: auto; */}

  > div {
    display: block;
  }

  @media (max-width: 784px) {
    padding: 1rem;
  }
`;

const notificationStyles = {
  Containers: {
    DefaultStyle: {
      width: '100%',
      margin: 0,
      left: 0,
      padding: '0',
    },
  },

  NotificationItem: {
    DefaultStyle: { // Applied to every notification, regardless of the notification level
      margin: 0,
    },
    success: {
      borderTop: '0',
      backgroundColor: colors.green,
    },
    error: {
      borderTop: '0',
      backgroundColor: colors.red,
    },
  },

  Title: {
    DefaultStyle: {
      color: 'white'
    },
  },

  Dismiss: {
    DefaultStyle: {
      backgroundColor: 'transparent',
      color: 'white',
      lineHeight: '32px',
      fontSize: '32px',
      fontWeight: 'normal',
      right: '10px',
    }
  }
};

const PrivateRoute = ({ component: ViewComponent, ...rest }) => {
  const { authenticated, loading } = rest;
  const render = props => {
    const { location } = props;
    // Authenticated
    if (authenticated) {
      return <ViewComponent {...props} />;
    }
    // Anonymous
    if (loading) {
      return (<Loader />);
    }

    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: location }
        }}
      />
    );
  };

  return <Route {...rest} render={render} />;
};

function mapState(state) {
  const {
    redirectReducer: { redirectTo },
    authReducer: { authenticated, loading },
    notifications
  } = state;

  return {
    redirectTo,
    authenticated,
    notifications,
    loading,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({ ...redirectActions, ...authActions }, dispatch)
  };
}

class App extends Component {
  componentDidMount() {
    this.props.actions.getLoginStatus();
  }

  redirect(redirectTo) {
    if (redirectTo.indexOf('http') === 0) {
      window.location = redirectTo;
      return <Loader />;
    }

    this.props.actions.completeRedirect();
    return (
      <Router>
        <Redirect to={redirectTo} />
      </Router>
    );
  }

  render() {
    if (this.props.redirectTo) {
      return this.redirect(this.props.redirectTo);
    }

    const { notifications, loading, authenticated } = this.props;

    return (
      <div style={{ display: 'flex' }}>
        <Notifications notifications={notifications} style={notificationStyles} />
        <Router>
          <AppContainer>
            <Header />
            <ContentWrapper>
              <Switch>
                <PrivateRoute
                  exact
                  authenticated={authenticated}
                  path="/dashboard"
                  component={DashboardView}
                  loading={loading}
                />
                <PrivateRoute
                  exact
                  authenticated={authenticated}
                  path="/operators"
                  component={OperatorsView}
                  loading={loading}
                />
                <PrivateRoute
                  exact
                  authenticated={authenticated}
                  path="/operators/:operatorId/fleets/:fleetId"
                  component={FleetView}
                  loading={loading}
                />
                <PrivateRoute
                  exact
                  authenticated={authenticated}
                  path="/operators/:id"
                  component={OperatorView}
                  loading={loading}
                />
                <PrivateRoute
                  exact
                  authenticated={authenticated}
                  path="/search"
                  component={SearchView}
                  loading={loading}
                />
                <PrivateRoute
                  exact
                  authenticated={authenticated}
                  path="/oems"
                  component={OemsView}
                  loading={loading}
                />
                <PrivateRoute
                  exact
                  authenticated={authenticated}
                  path="/oems/:id"
                  component={OemView}
                  loading={loading}
                />
                <PrivateRoute
                  exact
                  authenticated={authenticated}
                  path="/oems/:id/vehicles/:vehicleId/:vehicleFamily"
                  component={VehicleView}
                  loading={loading}
                />
                <PrivateRoute
                  exact
                  authenticated={authenticated}
                  path="/oems/:id/components/:type/:componentId"
                  component={ComponentView}
                  loading={loading}
                />
                <PrivateRoute
                  exact
                  authenticated={authenticated}
                  path="/products"
                  component={ProductsView}
                  loading={loading}
                />
                <PrivateRoute
                  exact
                  authenticated={authenticated}
                  path="/products/:id"
                  component={ProductView}
                  loading={loading}
                />

                <Route exact path="/" component={LoginView} />
              </Switch>
            </ContentWrapper>
            <Footer />
            <GoogleAnalytics />
          </AppContainer>
        </Router>
      </div>
    );
  }
}

App.propTypes = {
  actions: PropTypes.object,
  notifications: PropTypes.array,
  redirectTo: PropTypes.string,
  authenticated: PropTypes.bool,
};

App.defaultProps = {
  redirectTo: null
};

PrivateRoute.propTypes = {
  component: PropTypes.object,
  location: PropTypes.object
};

export default connect(mapState, mapDispatch)(App);
