import React, { PureComponent } from 'react';
import { Loader as Spinner, DropdownSelect } from 'dl-web-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SidebarListItem from 'components/SidebarListItem';
import Heading from 'components/Heading';
import Sidebar from 'components/Sidebar';
import SearchBar from 'components/SearchBar';
import Menu from 'container/Menu';
import FlatList from 'components/FlatList';

const ListItem = styled(Link)``;
const Label = styled.span``;
const Content = styled.div``;
const Container = styled.div``;
const List = styled.div``;

const StyledContent = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
`;

const ContentContainer = styled.div`
  flex: 1;
`;
class OperatorsPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { countries: [], country: 'Any' };
    this.renderItem.bind(this);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      countries: Array.from(props.operators.reduce((rest, current) => rest.add(current.country), new Set())).sort()
    });
  }

  handleChange({ target: { value } }) {
    this.setState({ country: value });
  }

  renderItem(item) {
    return (
      <ListItem
        key={item.id}
        to={`/operators/${item.id}`}
      >
        <p>
          {item.name}
        </p>
        <p >
          {item.fleetCount} fleets
        </p>
      </ListItem>
    );
  }

  renderSelectItems() {
    const values = [...this.state.countries].map((item) => (
      { label: item, value: item }
    ));
    return values;
  }

  render() {
    const { favoriteOperators, favoriteFleets, loaded, onSearch } = this.props;
    const operators = this.props.operators.filter(item => this.state.country === 'Any' || item.country === this.state.country);

    const ViewContent = !loaded ? (
      <Content>
        <Spinner style={{ width: '100%', height: 200, justifyContent: 'flex-end', alignItems: 'center' }} />
      </Content>
    )
      :
      (
        <ContentContainer>
          <div style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>
              <Heading>Operators ({operators.length})</Heading>
            </div>
          </div>

          <div>
            <SearchBar
              ref={instance => {
                this.searchRef = instance;
              }}
              setSearch={val => onSearch(val)}
            />
          </div>

          <form>
            <p>
              <Label>Filter by country</Label>
              <DropdownSelect
                id="country"
                placeholder="Any"
                mode="dropdown"
                selectedValue={this.state.country}
                onChange={country => this.handleChange(country)}
                values={this.renderSelectItems()}
              />
            </p>
          </form>

          <p>Operator name</p>

          {operators.length > 0 ? (
            <FlatList
              data={operators}
              renderItem={item => this.renderItem(item)}
            />
          )
            :
            (
              <Heading>No results</Heading>
            )}
        </ContentContainer>
      );

    return (
      <Container style={{ width: '1400px', margin: 'auto', marginTop: 0, maxWidth: '97%' }}>
        <Menu />
        <h1>Operators</h1>
        <StyledContent>
          <Sidebar>
            <div style={{ height: '100%' }}>
              <List>
                <SidebarListItem itemDivider>
                  <p>Favorite customers</p>
                </SidebarListItem>
                {favoriteOperators.map(item => (
                  <SidebarListItem
                    key={item.id}
                    to={`/operators/${item.operator.id}`}
                  >
                    <p >
                      {item.operator.name}
                    </p>
                  </SidebarListItem>
                ))}
                {favoriteOperators.length === 0 && (
                  <SidebarListItem>
                    <p>No favorites</p>
                  </SidebarListItem>
                )}
              </List>

              <List style={{ marginBottom: 0 }}>
                <SidebarListItem itemDivider>
                  <p>Favorite fleets</p>
                </SidebarListItem>
                {favoriteFleets.map(item => (
                  <SidebarListItem
                    key={item.id}
                    to={`/operators/${item.fleet.operatorId}/fleets/${item.fleet.id}`}
                  >
                    <p >
                      {item.fleet.name}
                    </p>
                  </SidebarListItem>
                ))}
                {favoriteFleets.length === 0 && (
                  <SidebarListItem>
                    <p>No favorites</p>
                  </SidebarListItem>
                )}
              </List>
            </div>
          </Sidebar>
          {ViewContent}
        </StyledContent>
      </Container>
    );
  }
}

export default OperatorsPage;
