import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledListItemLink = styled(Link)`
  cursor: pointer;
  p {
    padding-top: ${props => props.itemDivider ? '1rem' : '.5rem'};
    font-weight: ${props => props.itemDivider ? 'bold' : 'normal'};
    margin-left: 0;
    padding-left: 10px;
    color: white;
  }
`;

const StyledListItem = styled.div`
  cursor: pointer;
  padding-top: ${props => props.itemDivider ? '1rem' : '.5rem'};
  font-weight: ${props => props.itemDivider ? 'bold' : 'normal'};
  margin-left: 0;
  padding-left: 10px;
  color: white;
`;

export default props => {
  return props.to ?
    <StyledListItemLink {...props}>{props.children}</StyledListItemLink> :
    <StyledListItem {...props}>{props.children}</StyledListItem>
};
