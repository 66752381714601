import * as constants from './paths';
import * as errorActions from './error';

const LOAD_OEMS = 'railway/oem/LOAD';
const LOAD_OEMS_SUCCESS = 'railway/oem/LOAD_SUCCESS';
const LOAD_OEMS_FAIL = 'railway/oem/LOAD_FAIL';

const LOAD_OEM_BOGIES = 'railway/oem/LOAD_OEM_BOGIES';
const LOAD_OEM_BOGIES_SUCCESS = 'railway/oem/LOAD_OEM_BOGIES_SUCCESS';
const LOAD_OEM_BOGIES_FAIL = 'railway/oem/LOAD_OEM_BOGIES_FAIL';

const LOAD_OEM_VEHICLES = 'railway/oem/LOAD_OEM_VEHICLES';
const LOAD_OEM_VEHICLES_SUCCESS = 'railway/oem/LOAD_OEM_VEHICLES_SUCCESS';
const LOAD_OEM_VEHICLES_FAIL = 'railway/oem/LOAD_OEM_VEHICLES_FAIL';

const LOAD_FAMILY = 'railway/oem/FAMILY';
const LOAD_FAMILY_SUCCESS = 'railway/oem/FAMILY_SUCCESS';
const LOAD_FAMILY_FAIL = 'railway/oem/FAMILY_FAIL';

const initialState = {
  loaded: false,
  loading: false,
  oems: [],
  oem: { bogieClasses: [] },
  vehicles: [],
  family: []
};

export default function oem(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_OEMS:
      return {
        ...state,
        loading: true
      };
    case LOAD_OEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        oems: action.result || []
      };
    case LOAD_OEMS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };

    case LOAD_OEM_BOGIES:
      return {
        ...state,
        loading: true,
        oem: []
      };
    case LOAD_OEM_BOGIES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        oem: action.result || []
      };
    case LOAD_OEM_BOGIES_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case LOAD_OEM_VEHICLES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        vehicles: action.result || []
      };

    case LOAD_FAMILY:
      return {
        ...state,
        loading: true,
        loaded: false,
        family: []
      };
    case LOAD_FAMILY_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        family: action.result || []
      };
    default:
      return state;
  }
}

export function fetchOems() {
  return {
    types: [LOAD_OEMS, LOAD_OEMS_SUCCESS, LOAD_OEMS_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject(new Error('Not authenticated'));
      }

      return client.get(`${constants.RAILWAY_PATH}/oems`).catch(e => {
        dispatch(errorActions.errorMessage('Error fetching OEMs.'));
        return Promise.reject(new Error(e));
      });
    }
  };
}

export function fetchOem(oemId) {
  console.log(`Get OEM ${oemId}`);
  return {
    types: [LOAD_OEM_BOGIES, LOAD_OEM_BOGIES_SUCCESS, LOAD_OEM_BOGIES_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject(new Error('Not authenticated'));
      }

      return client.get(`${constants.RAILWAY_PATH}/oems/${oemId}`).catch(e => {
        dispatch(errorActions.errorMessage('Error fetching OEM.'));
        return Promise.reject(new Error(e));
      });
    }
  };
}

export function fetchOemVehicles(oemId) {
  console.log(`Get OEM vehicles ${oemId}`);
  return {
    types: [LOAD_OEM_VEHICLES, LOAD_OEM_VEHICLES_SUCCESS, LOAD_OEM_VEHICLES_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject(new Error('Not authenticated'));
      }

      return client.get(`${constants.RAILWAY_PATH}/families/${oemId}`).catch(e => {
        dispatch(errorActions.errorMessage('Error fetching OEM vehicles.'));
        return Promise.reject(new Error(e));
      });
    }
  };
}

export function fetchClassesByFamily(family, oemId) {
  return {
    types: [LOAD_FAMILY, LOAD_FAMILY_SUCCESS, LOAD_FAMILY_FAIL],
    promise: (client, dispatch, getState) => {
      if (!getState().authReducer.authenticated) {
        return Promise.reject();
      }
      if (family === 'Unknown vehicle family') {
        family = null;
      }
      return client.get(`${constants.RAILWAY_PATH}/train-classes/?family=${family}&oemId=${oemId}`).catch(e => {
        dispatch(errorActions.errorMessage('Error fetching OEM vehicles by vehicle family.'));
        return Promise.reject(new Error(e));
      });
    }
  };
}
