import * as React from 'react';
import { connect } from 'react-redux';
import { sortBy } from 'lodash';
import OemsListingPage from 'stories/screens/OemsListingPage';
import SearchBar from 'components/SearchBar';
import * as oemsActions from 'redux/modules/oems';

class OemsListingPageContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { oemsSearch: '' };
    this.searchRef = {};
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    if (!this.props.oemsReducer.loading && (!this.props.oemsReducer.loaded || !this.props.oemsReducer.oems.length)) {
      this.props.fetchOems();
    }
  }

  render() {
    const searchForm = (
      <SearchBar
        ref={instance => {
          this.searchRef = instance;
        }}
        setSearch={val => this.setState({ oemsSearch: val })}
      />
    );

    let oems = this.props.oemsReducer.oems.filter(o => {
      return o.name.toUpperCase().indexOf(this.state.oemsSearch.toUpperCase()) !== -1;
    });

    oems = sortBy(oems, ['name']);

    return (
      <OemsListingPage
        oems={oems}
        loading={this.props.oemsReducer.loading}
        searchForm={searchForm}
        focusSearch={this.searchRef.clearAndFocusSearch}
      />
    );
  }
}

function bindAction(dispatch) {
  return {
    fetchOems: () => dispatch(oemsActions.fetchOems())
  };
}

const mapStateToProps = state => {
  return {
    oemsReducer: state.oemsReducer || {},
    authenticated: state.authReducer.authenticated,
  };
};

export default connect(
  mapStateToProps,
  bindAction
)(OemsListingPageContainer);
