import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'dl-web-components';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import TCell from 'components/TCell';
import TRow from 'components/TRow';
import AddBearingForm from 'container/AddBearingForm';
import AddComponentForm from 'container/AddComponentForm';
import * as productActions from 'redux/modules/product';
import * as errorActions from 'redux/modules/error';

const RootElement = styled.div`
  margin-left: 0;
`;

const Right = styled.div``;

// const unknownItem = {
//   designation: 'Unknown',
//   description: '-',
//   oem: {
//     name: 'Unknown'
//   },
//   unknown: true
// };

class TrainBogieItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      disabled: false,
      confirmDialogVisible: false,
      callback: null
    };
  }

  handleButtonClick(state) {
    this.setState({ ...state });
    if (window.confirm(state.confirmMessage)) {
      state.callback();
    }
  }

  removeBearing(item, fleetId, bearings, components) {
    if (this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
    const bearingIds = bearings.map(bearing => {
      return bearing.id;
    });
    console.log('bearingIds', bearingIds);
    this.props.removeBearing(bearingIds, fleetId, components);
  }

  renderBearingName(bearing, index, fleet, components) {
    const bearings = components.map(component => {
      return component.bearings[index];
    });
    if (this.state.editing) {
      return (
        <p key={index}>
          <Icon
            name="trash"
            style={{ color: this.state.disabled ? 'gray' : '#007AFF', fontSize: 28 }}
            onClick={() =>
              this.handleButtonClick({
                confirmDialogVisible: true,
                confirmMessage: 'Are you sure you wish to remove this bearing?',
                actionMessage: 'Removing bearing, please wait...',
                label: 'Remove',
                callback: () => this.removeBearing(bearing, this.props.fleet.id, bearings, components)
              })
            }
          />
          &nbsp;&nbsp;
          {bearing.bearingClass ? bearing.bearingClass.name : 'Unknown'}
        </p>
      );
    }
    return (
      <p>
        <Link
          key={index}
          style={bearing.bearingClassId ? { color: '#007AFF', cursor: 'pointer' } : {}}
          to={`/products/${bearing.bearingClassId}`}
        >
          {bearing.bearingClass ? bearing.bearingClass.name : 'Unknown'}
        </Link>
      </p>
    );
  }

  renderBearingPosition(item, index) {
    return (
      <p key={index}>
        {item.position}
      </p>
    );
  }

  render() {
    const { name, type, bogies, unknown, detailed, fleet } = this.props;
    const key = type === 'gearboxes' ? 'gearboxClass' : `${type.slice(0, -1)}Class`; // Remove 's' and extend Class (motors => motorClass)

    if (!bogies.length) {
      if (!detailed) {
        return false;
      }
      console.log(unknown);
      return (
        <RootElement style={{ flex: 1, flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
          <div style={{ flexDirection: 'row', width: '100%' }}>
            <p style={{ alignSelf: 'flex-start', fontWeight: 'bold', color: '#292929' }}>{name}</p>

            <p
              note
              style={{ color: '#007AFF', cursor: 'pointer', paddingLeft: 10 }}
              onClick={() => this.setState({ editing: !this.state.editing })}
            >
              {/* <Icon active name="settings" style={{ color: '#007AFF', cursor: 'pointer', fontSize: 20 }} /> */}
              {this.state.editing ? 'cancel' : 'add'}
              {'\n'}
            </p>
          </div>

          {this.state.editing && (
            <div>
              <AddComponentForm
                fleetId={fleet.id}
                item={unknown}
                type={key.slice(0, -5)}
                active={true}
              />
            </div>
          )}
        </RootElement>
      );
    }

    const byPositionAndName = (a, b) => {
      const positionMap = {
        'input shaft': 0,
        'intermediate shaft': 1,
        'output shaft': 2,
        de: 0,
        nde: 1,
        unknown: 99,
        other: 99
      };

      const items = [a, b].map(item => {
        return { position: positionMap[item.position.toLowerCase()], name: item.bearingClass.name.toLowerCase() };
      });

      if (items[0].position !== items[1].position) {
        return items[0].position - items[1].position;
      }

      if (items[0].name < items[1].name) {
        return -1;
      }
      if (items[0].name > items[1].name) {
        return 1;
      }
      return 0;
    };

    // const item = bogies[0][type][0][key] || unknownItem;
    console.log('bogies', bogies);
    /*
    const testItems
      [
        [
          {
            ...bogies[0][type][0],
            bearings: bogies[0][type][0].Bearings.sort(byPositionAndName),
          },
          {
            ...bogies[1][type][0],
            bearings: bogies[1][type][0].Bearings.sort(byPositionAndName),
          }
        ],
        [
          {
            ...bogies[0][type][1],
            bearings: bogies[0][type][1].Bearings.sort(byPositionAndName),
          },
          {
            ...bogies[1][type][1],
            bearings: bogies[1][type][1].Bearings.sort(byPositionAndName),
          }
        ],
      ];
      The below double for-loop will generate something like the above, so that we get all the bearings and component ids to the same place when
      we iterate over this array below. This way we can send them to the backend easily, when we change components or component's bearings
      the outer arrays items are one displayed gearbox/motor and
      the inner arrays items are all the gearboxes/motors in the database that are related to the displayed gearbox/motor
    */
    const sortedComponents = [];
    for (let outerIndex = 0; outerIndex < bogies[0][type].length; outerIndex++) {
      sortedComponents[outerIndex] = [];
      for (let innerIndex = 0; innerIndex < bogies.length; innerIndex++) {
        sortedComponents[outerIndex][innerIndex] = {
          ...bogies[innerIndex][type][outerIndex],
          bearings: bogies[innerIndex][type][outerIndex].Bearings.sort(byPositionAndName),
          Bearings: null
        };
      }
    }
    console.log('sortedComponents', sortedComponents);

    const items = bogies[0][type].map(i => {
      return {
        ...i,
        bearings: i.Bearings.sort(byPositionAndName)
      };
    });
    console.log('items', items);

    const valueMap = {
      gearboxes: 'numGearboxes',
      motors: 'numMotors',
      axles: 'numAxles',
      bearings: 'numBearings'
    };

    return (
      <RootElement style={{ flexDirection: 'column', alignItems: 'flex-start', marginLeft: 0 }}>
        <div style={{ flexDirection: 'row', width: '100%' }}>
          <p style={{ alignSelf: 'flex-start', fontWeight: 'bold', color: '#292929' }}>{name}</p>
          <Right>
            <p
              style={{ color: '#007AFF', cursor: 'pointer', paddingLeft: 10 }}
              onClick={() => {
                this.setState({ editing: !this.state.editing });
              }}
            >
              {/* <Icon active name="settings" style={{ color: '#007AFF', cursor: 'pointer', fontSize: 20 }} /> */}
              {this.state.editing ? 'Cancel' : 'Edit'}
              {'\n'}
            </p>
          </Right>
        </div>

        {sortedComponents.map((item, index) => {
          console.log('ITEM', item[0][key], key, item);
          return (
            <div key={index} style={{ width: '100%' }}>
              <TRow>
                <TCell multiline style={{ flexGrow: 2 }}>
                  <div>
                    <p>
                      Designation
                    </p>
                  </div>
                  {!this.state.editing ? (
                    <p>
                      {!item[0][key] || item[0][key].unknown ? ( // handle unknown some other way???
                        <p>Unknown</p>
                      )
                        :
                        (
                          <Link
                            to={`/oems/${item[0][key].oemId || 'unknown'}/components/${key}/${item[0][key].id}`}
                            style={{ color: '#007AFF', cursor: 'pointer' }}

                          // onClick={() => go(navigation, 'ComponentPage', { component: item[0][key], type: key })}
                          >
                            {item[0][key].designation}
                          </Link>
                        )}
                    </p>
                  )
                    :
                    (
                      <AddComponentForm
                        amount={bogies[0][type].length}
                        fleetId={fleet.id}
                        type={key.slice(0, -5)}
                        item={item}
                        value={item[0][key] ? item[0][key].designation : ''}
                        active={true}
                      />
                    )}
                </TCell>
                <TCell multiline>
                  <div>
                    <p note>
                      OEM
                    </p>
                  </div>
                  {item[0][key] && (item[0][key].oem && item[0][key].oem.name !== 'Unknown') ? (
                    <p
                      style={{ color: '#007AFF', cursor: 'pointer' }}
                    // onClick={() => go(navigation, 'OemBogiesPage', { oem: item[0][key].oem })}
                    >
                      {item[0][key].oem.name}
                    </p>
                  ) : (
                      <p>Unknown</p>
                    )}
                </TCell>
                <TCell multiline>
                  <div>
                    <p>
                      Amount / Bogie
                    </p>
                  </div>
                  <p>{bogies[0].bogieClass[valueMap[type]] || bogies[0][type].length}</p>
                </TCell>
              </TRow>

              <TRow>
                <TCell multiline>
                  <div>
                    <p>
                      Bearings
                    </p>
                  </div>
                  {!item[0].bearings.length ? (
                    <p>-</p>
                  ) : (
                      item[0].bearings.map((bearing, i) => {
                        return this.renderBearingName(bearing, i, fleet, item);
                      })
                    )}
                </TCell>
                <TCell multiline>
                  <div>
                    <p>
                      Bearing position
                    </p>
                  </div>
                  {!item[0].bearings.length ? (
                    <p>-</p>
                  ) : (
                      item[0].bearings.map((bearing, i) => {
                        return this.renderBearingPosition(bearing, i, fleet, item);
                      })
                    )}
                </TCell>
              </TRow>
              {this.state.editing && (
                <AddBearingForm fleet={fleet} component={item} type={key.slice(0, -5)} active={true} />
              )}
            </div>
          );
        })}
      </RootElement>
    );
  }
}

TrainBogieItem.propTypes = {
  removeBearing: PropTypes.func,
  name: PropTypes.any,
  type: PropTypes.string,
  bogies: PropTypes.array,
  unknown: PropTypes.any,
  detailed: PropTypes.any,
  err: PropTypes.object,
  clearError: PropTypes.func,
  fleet: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    err: state.errorReducer
  };
}

function mapDispatch(dispatch) {
  return {
    removeBearing: (bearingIds, fleetId, components) =>
      dispatch(productActions.removeBearingByIds(bearingIds, fleetId, components)),
    clearError: () => dispatch(errorActions.clearError())
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(TrainBogieItem);
