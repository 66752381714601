import * as React from 'react';
import { connect } from 'react-redux';
import { sortBy } from 'lodash';
import OperatorsPage from 'stories/screens/OperatorsPage';
import * as operatorActions from 'redux/modules/operator';
import * as favoriteActions from 'redux/modules/favorite';

class OperatorsContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { operatorSearch: '' };
  }

  componentDidMount() {
    const { authenticated, operatorReducer } = this.props;
    const { operators, loading, loaded } = operatorReducer;

    if (loading || loaded || operators.length || !authenticated) {
      return;
    }

    this.fetchData();
    this.fetchFavoritesData();
  }

  fetchData() {
    const { operatorReducer, fetchOperators } = this.props;
    const { loading, loaded } = operatorReducer;

    if (loading || loaded) {
      return;
    }

    fetchOperators();
  }

  fetchFavoritesData() {
    const { favoriteReducer, fetchFavorites } = this.props;
    const { operators, fleets } = favoriteReducer;

    if (favoriteReducer.loading || favoriteReducer.loaded) {
      return;
    }

    if (!operators.length) {
      fetchFavorites('operators');
    }

    if (!fleets.length) {
      fetchFavorites('fleets');
    }
  }

  handleSearch(val) {
    this.setState({ operatorSearch: val });
  }

  render() {
    const { operatorReducer, favoriteReducer } = this.props;
    const { operators, loaded } = operatorReducer;
    const { operatorSearch } = this.state;

    let filteredOperators = operators.filter(o => o.name.toUpperCase()
      .indexOf(operatorSearch.toUpperCase()) !== -1);

    filteredOperators = sortBy(filteredOperators, ['name']);

    return (
      <OperatorsPage
        operators={filteredOperators}
        loaded={loaded}
        favoriteOperators={favoriteReducer.operators}
        favoriteFleets={favoriteReducer.fleets}
        onSearch={this.handleSearch.bind(this)}
      />
    );
  }
}

function bindAction(dispatch) {
  return {
    fetchOperators: () => dispatch(operatorActions.fetchOperators()),
    fetchFavorites: type => dispatch(favoriteActions.fetchFavorites(type)),
  };
}

const mapStateToProps = state => {
  return {
    operatorReducer: state.operatorReducer,
    authenticated: state.authReducer.authenticated,
    favoriteReducer: state.favoriteReducer,
  };
};

export default connect(mapStateToProps, bindAction)(OperatorsContainer);
