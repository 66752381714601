import * as React from 'react';
import { connect } from 'react-redux';
import SearchPage from 'stories/screens/SearchPage';
import SearchBar from 'components/SearchBar';
import * as searchActions from 'redux/modules/search';

class SearchPageContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.searchRef = {};
  }

  render() {
    const searchForm = (
      <SearchBar
        setSearch={(val => this.props.search(val))}
      />
    );

    return (
      <SearchPage
        searchResult={this.props.result}
        loading={this.props.loading}
        searchForm={searchForm}
        focusSearch={this.searchRef.clearAndFocusSearch}
      />
    );
  }
}

function bindAction(dispatch) {
  return {
    search: term => dispatch(searchActions.searchContent(term)),
  };
}

const mapStateToProps = state => {
  return {
    result: state.searchReducer.result,
    loading: state.searchReducer.loading,
    authenticated: state.authReducer.authenticated,
  };
};

export default connect(mapStateToProps, bindAction)(SearchPageContainer);

