import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ListElement = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    background-color: #f3f6f6;
    padding: .5rem;
    margin-bottom: .25rem;

    p {
      line-height: 1rem;
    }
  }
`;

const FlatList = ({ data, renderItem }) => (
  <ListElement>
    {data.map((item, index) => (
      <li key={index}>
        {renderItem(item)}
      </li>
    ))}
  </ListElement>
);

FlatList.propTypes = {
  data: PropTypes.array,
  renderItem: PropTypes.func,
};

export default FlatList;
