import * as React from 'react';
import { Button, DropdownSelect } from 'dl-web-components';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import { sortBy } from 'lodash';
import { PropTypes } from 'prop-types';
import TRow from 'components/TRow';
import TCell from 'components/TCell';
import * as productActions from 'redux/modules/product';

class AddBearingForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      suggestions: [],
      query: '',
      valid: false,
      active: !!props.active,
      target: '',
      disabled: false,
    };
  }

  componentDidMount() {
    const { products, fetchBearings } = this.props;

    if (!products.loading) {
      fetchBearings();
    }
  }

  onSelect(suggestion) {
    console.log(suggestion); // the pressed suggestion
    this.setState({ query: suggestion.name, selected: suggestion, valid: true, isVisible: false });
  }

  getSuggestions(value = '') {
    const { products: { bearings } } = this.props;
    console.log(bearings, value);
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : bearings.filter(p => (
      p.name.toLowerCase().slice(0, inputLength) === inputValue
    ));
  }

  nonExistingBearing() {
    return !this.state.selected;
  }

  formIsValid() {
    if (this.props.type === 'motor' || this.props.type === 'gearbox') {
      return !!this.state.position && this.state.valid;
    } else if (this.props.type === 'wheelset') {
      return !!this.state.position && !!this.state.target && !!this.state.amount && this.state.valid;
    }
    return false;
  }

  addBearing() {
    this.setState({ disabled: true, operation: 'Saving' });

    let componentIds;
    if (this.props.type !== 'wheelset') {
      componentIds = this.props.component.map(item => {
        return item.id;
      });
    }

    let product;
    let add;

    if (this.nonExistingBearing()) {
      product = this.state.query;
      add = this.props.addNewBearingToFleetComponent;
    } else {
      product = this.state.selected;
      add = this.props.addBearingToFleetComponent;
    }

    add(
      this.props.type,
      product,
      this.props.fleetId || this.props.fleet.id,
      this.state.position || 'Unknown',
      this.state.target || 'Unknown',
      this.state.amount,
      componentIds
    );
  }

  handleChange(val) {
    const matchingProduct = this.props.products.bearings.filter(item => item.name.toLowerCase() === val.toLowerCase());
    const selected = matchingProduct !== [] ? matchingProduct[0] : null;
    this.setState({
      query: val,
      valid: val.length > 0,
      selected,
      isVisible: selected && val.toLowerCase() === selected.name.toLowerCase()
    });
  }

  render() {
    const products = sortBy(this.props.products.bearings, ['name']);
    const { suggestions, query } = this.state;

    const bearingPositions = {
      gearbox: ['Input shaft', 'Intermediate shaft', 'Output shaft', 'Unknown'],
      motor: ['DE', 'NDE', 'Unknown'],
      wheelset: ['Inboard', 'Outboard', 'Unknown']
    };

    const labelStyle = { marginLeft: 15, marginTop: 7, flexGrow: 2 };
    if (!products.length) {
      return <React.Fragment />;
    }

    return (
      <div>
        {!this.state.active && (
          <p onClick={() => this.setState({ active: true })} style={{ color: '#007AFF', cursor: 'pointer' }}>
            Add bearing
          </p>
        )}
        {this.state.active && (
          <div style={{ marginBottom: 50 }}>
            <p>Add bearing</p>

            {(this.props.type === 'gearbox' || this.props.type === 'motor' || this.props.type === 'wheelset') && (
              <div style={{ flexDirection: 'row', zIndex: 100010, marginTop: 10 }}>
                <TCell style={labelStyle}>
                  <p note>Bearing position</p>
                </TCell>
                <TCell multiline style={{ flexGrow: 6 }}>
                  <DropdownSelect
                    values={bearingPositions[this.props.type].map(i => ({ label: i, value: i }))}
                    onChange={e => this.setState({ position: e.target.value })}
                  />
                </TCell>
              </div>
            )}
            {this.props.type === 'wheelset' && (
              <React.Fragment>
                <div style={{ flexDirection: 'row', zIndex: 100005 }}>
                  <TCell style={labelStyle}>
                    <p note>Bogie type</p>
                  </TCell>
                  <TCell multiline style={{ flexGrow: 6 }}>
                    <DropdownSelect
                      values={['Motor', 'Trailer', 'Both'].map(i => ({ label: i, value: i }))}
                      onChange={e => this.setState({ target: e.target.value.toUpperCase() })}
                    />
                  </TCell>
                </div>

                <div style={{ flexDirection: 'row', zIndex: 100000 }}>
                  <TCell style={labelStyle}>
                    <p>Bearings per axle</p>
                  </TCell>
                  <TCell multiline style={{ flexGrow: 6 }}>
                    <DropdownSelect
                      values={[2, 4].map(i => ({ label: i, value: i }))}
                      onChange={e => this.setState({ amount: e.target.value })}
                    />
                  </TCell>
                </div>
              </React.Fragment>
            )}

            <TRow>
              <TCell style={labelStyle}>
                <p>Bearing name</p>
              </TCell>
              <TCell multiline style={{ flexGrow: 4 }}>
                <p>
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={({ value }) => {
                      this.setState({
                        suggestions: this.getSuggestions(value)
                      });
                    }}
                    onSuggestionsClearRequested={e => {
                      this.setState({
                        suggestions: []
                      });
                    }}
                    getSuggestionValue={e => e.name}
                    renderSuggestion={e => {
                      return <div>{e.name}</div>;
                    }}
                    inputProps={{
                      placeholder: 'Designation',
                      value: query,
                      onChange: (event, { newValue }) => {
                        this.handleChange(newValue);
                      }
                    }}
                    onSuggestionSelected={(event, { suggestion }) => {
                      this.setState({
                        selected: suggestion,
                        valid: true,
                      });
                    }}

                  // onSelect={this.onSelect.bind(this)}
                  // suggestions={products}
                  // suggestionObjectTextProperty="name"
                  // value={this.state.query}
                  // placeholder="Bearing name"
                  // onChangeText={val => this.handleChange(val)}
                  // selectTextOnFocus={true}
                  // onFocus={() => this.props.fetchProducts()}
                  // inputStyle={{
                  //   borderWidth: 1,
                  //   borderColor: '#d6d7da',
                  //   padding: 5,
                  //   fontSize: 16,
                  //   width: 150
                  // }}
                  // style={{
                  //   flex: 1
                  // }}
                  />
                </p>
              </TCell>
              <TCell multiline style={{ flexGrow: 2 }}>
                <Button
                  size="sm"
                  style={{ marginTop: '1rem' }}
                  disabled={this.state.disabled || !this.formIsValid()}
                  onClick={() => this.addBearing()}
                >
                  <span>Add</span>
                </Button>
              </TCell>
            </TRow>
          </div>
        )}
      </div>

      // <div style={{ flex: 1, zIndex: 100 }}>
      //   <Autocomplete
      //     autoCapitalize="none"
      //     autoCorrect={false}
      //     data={products}
      //     defaultValue={this.state.query}
      //     onChangeText={text => this.setState({ query: text })}
      //     cellComponent={r => {
      //       return (
      //         <div style={{ flex: 1, zIndex: 10001, backgroundColor: 'green', padding: 5 }}>
      //           <TouchableOpacity onClick={() => console.log(r)}>
      //             <p>{r.item}</p>
      //           </TouchableOpacity>
      //         </div>
      //       );
      //     }}
      //   />
      // </div>
    );
  }
}

AddBearingForm.propTypes = {
  active: PropTypes.bool,
  addBearingToFleetComponent: PropTypes.func,
  addNewBearingToFleetComponent: PropTypes.func,
  type: PropTypes.string,
  bearings: PropTypes.object,
  fetchBearings: PropTypes.func,
  component: PropTypes.array
};

function mapState(state) {
  return {
    products: state.productReducer
  };
}

function mapDispatch(dispatch) {
  return {
    fetchBearings: () => dispatch(productActions.fetchBearings()),
    addBearingToFleetComponent: (type, bearing, fleetId, position, target, amount, component) =>
      dispatch(productActions.addBearingToFleetComponent(type, bearing, fleetId, position, target, amount, component)),
    addNewBearingToFleetComponent: (type, bearing, fleetId, position, target, amount, component) =>
      dispatch(productActions.addNewBearingToFleetComponent(type, bearing, fleetId, position, target, amount, component))
  };
}

export default connect(
  mapState,
  mapDispatch
)(AddBearingForm);
