import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as notifications } from 'react-notification-system-redux';

import authReducer from './auth';
import operatorReducer from './operator';
import fleetReducer from './fleet';
import productReducer from './product';
// import navigationReducer from './navigation';
import favoriteReducer from './favorite';
import trainReducer from './train';
import oemsReducer from './oems';
import maintenanceReducer from './maintenance';
import componentReducer from './component';
import searchReducer from './search';
import updatedReducer from './updated';
import userSettingsReducer from './userSettings';
import errorReducer from './error';
import imageReducer from './image';
import documentReducer from './document';
import redirectReducer from './redirect';

export default combineReducers({
  form: formReducer,
  authReducer,
  operatorReducer,
  fleetReducer,
  productReducer,
  // navigationReducer,
  favoriteReducer,
  trainReducer,
  oemsReducer,
  maintenanceReducer,
  componentReducer,
  searchReducer,
  updatedReducer,
  userSettingsReducer,
  errorReducer,
  imageReducer,
  documentReducer,
  redirectReducer,
  notifications,
});
