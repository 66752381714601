import { createStore as _createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import createMiddleware from './middleware/clientMiddleware';
import reducer from './modules/reducer';

export default function createStore(client) {
  // Sync dispatched route actions to the history
  const reduxRouterMiddleware = routerMiddleware(/* history */);
  const middleware = [createMiddleware(client), reduxRouterMiddleware, thunk];

  const finalCreateStore = compose(
    applyMiddleware(...middleware),
    applyMiddleware(logger),
  )(_createStore);

  const store = finalCreateStore(reducer);

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./modules/reducer', () => {
        store.replaceReducer(require('./modules/reducer')); // eslint-disable-line
      });
    }
  }

  return store;
}
