const styles = {
  container: {
    width: '1400px', margin: 'auto', marginTop: 0,  maxWidth: '97%',
  },
  row: {
    flex: 1,
    alignItems: 'center',
  },
  text: {
    fontSize: 20,
    marginBottom: 15,
    alignItems: 'center',
  },
  mt: {
    marginTop: 18,
  },
};
export default styles;
