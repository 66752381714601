import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Button, Modal, Loader as Spinner } from 'dl-web-components';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import SidebarListItem from 'components/SidebarListItem';
import Sidebar from 'components/Sidebar';
import Heading from 'components/Heading';
import Document from 'components/Document';
import EditFleetForm from 'container/EditFleetForm';
import { exists } from 'helpers/validation';
import FavoriteIcon from 'components/FavoriteIcon';
import Menu from 'container/Menu';

import styles from './styles';


const StyledContent = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
`;

class FleetDocumentsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      favorite: props.favorite,
      adding: false,
      modalOpen: false,
    };
  }

  render() {
    const { fleet, trains, train, loaded, documents, documentActions } = this.props;
    const { modalOpen } = this.state;
    const [fleetTrain] = trains || [{}];

    const isFavorite = exists(this.state.favorite) && !!this.state.favorite;

    const ViewContent =
      !fleet.operator || !train.id || !loaded || !fleetTrain ? (
        <div>
          <Spinner style={{ width: '100%', height: 200, justifyContent: 'flex-end', alignItems: 'center' }} />
        </div>
      )
        :
        (
          <div style={{ flex: 1 }}>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Heading>
                {fleet.name}
                <FavoriteIcon
                  favorite={isFavorite}
                  onClick={() => {
                    this.setState({ favorite: !this.props.favorite });
                    this.props.toggleFavorite(fleet.id, this.props.favorite);
                  }}
                />
              </Heading>
              <div style={{ padding: 20, flexDirection: 'row' }}>
              </div>
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Heading style={{ flex: 1 }}>Documents</Heading>
              <Button
                block
                style={{
                  flex: 0,
                  alignSelf: 'flex-end',
                  marginRight: 10,
                  marginBottom: 10,
                  minWidth: 130,
                }}
                onClick={() => this.setState({ adding: true })}
              >
                <p style={{ textAlign: 'center' }}>Upload new</p>
              </Button>
            </div>
            <div>
              {this.state.adding ? (
                <Document
                  newDocument
                  fleetId={fleet.id}
                  {...documentActions}
                  dismiss={() => this.setState.bind(this)({ adding: false })}
                />
              ) : null}
              {documents.length > 0 ? (
                documents.map(item => <Document key={item.id} fleetId={fleet.id} {...item} {...documentActions} />)
              )
                :
                (
                  <p>No documents</p>
                )}
            </div>
          </div>
        );

    return (
      <div style={styles.container}>
        <Menu />

        <div>
          {/* <Heading style={{ flex: 3, alignItems: 'center' }}>
            <Link to={`/operators/${fleet.operator.id}`}>
              {fleet.operator && fleet.operator.name}
            </Link>
          </Heading> */}

          <div>
            <Button icon="cog" variant="plain" onClick={() => this.setState({ modalOpen: !modalOpen })}>Edit</Button>
            {modalOpen && (
              <Modal style={{ maxHeight: '90%' }} toggleModal={() => this.setState({ modalOpen: !modalOpen })}>
                <EditFleetForm data={fleet} toggleModal={() => this.setState({ modalOpen: !modalOpen })} />
              </Modal>
            )}

            {/* <Modal button="Edit" linkColor="white">
              <EditFleetForm data={fleet} />
            </Modal> */}
          </div>
        </div>

        <StyledContent>
          <Sidebar>
            <ul>
              <SidebarListItem onClick={() => this.props.switchPage('')}>
                <p >Overview</p>
              </SidebarListItem>
              <SidebarListItem onClick={() => this.props.switchPage('maintenance')}>
                <p >Maintenance</p>
              </SidebarListItem>
              <SidebarListItem onClick={() => this.props.switchPage('documents')}>
                <p style={{ color: '#007AFF' }}>
                  Documents
                </p>
              </SidebarListItem>
            </ul>
          </Sidebar>
          {ViewContent}
        </StyledContent>
      </div>
    );
  }
}
FleetDocumentsPage.propTypes = {
  favorite: PropTypes.any,
  train: PropTypes.object,
  switchPage: PropTypes.func,
  fleet: PropTypes.object,
  trains: PropTypes.array,
  documents: PropTypes.array,
  loaded: PropTypes.bool,
  toggleFavorite: PropTypes.func,
  documentActions: PropTypes.object
};

export default FleetDocumentsPage;
