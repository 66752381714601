import * as React from 'react';
import { Button, DropdownSelect } from 'dl-web-components';
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { exists } from 'helpers/validation';
import ModalForm from 'stories/screens/ModalForm';
import * as maintenanceActions from 'redux/modules/maintenance';

class AddReminderForm extends React.PureComponent {
  constructor(props) {
    super(props);
    const { monthsBefore } = props;
    const maintenanceId = props.data.id;
    this.state = {
      reminderExists: exists(monthsBefore),
      monthsBefore: exists(monthsBefore) ? monthsBefore : 0,
      maintenanceId
    };
  }

  componentDidMount() {
    this.props.getReminder(this.state.maintenanceId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ monthsBefore: nextProps.monthsBefore });
  }

  save() {
    const { maintenanceId, monthsBefore, reminderExists } = this.state;
    const { addReminder, updateReminder } = this.props;
    const saveReminder = reminderExists ? updateReminder : addReminder;
    saveReminder(monthsBefore, maintenanceId);
    this.props.toggleModal();
  }

  delete() {
    this.props.deleteReminder(this.state.maintenanceId);
    this.props.toggleModal();
  }

  renderSelector() {
    const options = [];
    for (let i = 0; i <= 36; i++) {
      options.push({ label: i, value: i });
    }
    return (
      <DropdownSelect
        style={{ flex: 1 }}
        name="monthsBefore"
        placeholder="Select amount of months"
        mode="dropdown"
        selectedValue={exists(this.state.monthsBefore) ? this.state.monthsBefore : 0}
        onChange={e => this.setState({ monthsBefore: e.target.value })}
        values={options}
      />
    );


    // return (
    //   <Slider
    //     style={{ flex: 1 }}
    //     minimumValue={0}
    //     maximumValue={36}
    //     step={1}
    //     value={exists(this.props.monthsBefore) ? this.props.monthsBefore : 0}
    //     onChange={monthsBefore => this.setState({ monthsBefore })}
    //   />
    // );
  }

  render() {
    const { handleSubmit } = this.props;
    const form = (
      <div style={{ flex: 1 }}>
        <form style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div inlineLabel>
            <label>Reminder</label>
            {this.renderSelector()}
            <p style={{ textAlign: 'right', width: 150, marginRight: 10, fontSize: 15 }}>
              {this.state.monthsBefore > 0 ? `${this.state.monthsBefore} months` : 'No reminder'}
              {' before'}
            </p>
          </div>

          {this.state.reminderExists && (
            <Button
              style={{ alignSelf: 'center', margin: 15 }}
              variant="warning"
              onClick={() => this.delete()}
            >
              Delete reminder
            </Button>
          )}
        </form>
      </div>
    );

    return (
      <ModalForm
        nonScrolling
        title="Maintenance reminder"
        form={form}
        onSave={handleSubmit(this.save.bind(this))}
        onCancel={this.props.dismiss}
      />
    );
  }
}

AddReminderForm.propTypes = {
  monthsBefore: PropTypes.number,
  addReminder: PropTypes.func,
  updateReminder: PropTypes.func,
  deleteReminder: PropTypes.func,
  getReminder: PropTypes.func,
  dismiss: PropTypes.func,
  handleSubmit: PropTypes.func,
  data: PropTypes.object
};

const AddReminderFormContainer = reduxForm({
  form: 'AddReminderForm'
})(AddReminderForm);

function mapState(state) {
  const { monthsBefore } = state.maintenanceReducer;
  return {
    monthsBefore
  };
}

function mapDispatch(dispatch) {
  return {
    ...bindActionCreators({ ...maintenanceActions }, dispatch),
    dispatch
  };
}

export default connect(
  mapState,
  mapDispatch
)(AddReminderFormContainer);
