import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import HomePage from 'stories/screens/HomePage';
import * as fleetActions from 'redux/modules/fleet';
import * as favoriteActions from 'redux/modules/favorite';
import * as operatorActions from 'redux/modules/operator';
import * as maintenanceActions from 'redux/modules/maintenance';
import * as authActions from 'redux/modules/auth';
import * as userActions from 'redux/modules/userSettings';

class HomePageContainer extends PureComponent {
  componentDidMount() {
    console.log('Home mount');
    if (this.props.authenticated) {
      this.initialize();
    }
  }

  initialize() {
    this.props.fetchFavorites('operators');
    this.props.fetchFavorites('fleets');
    this.props.fetchMaintenances();
    this.props.loadSettings();
  }

  logout() {
    // Toast.show({
    //   text: 'Logged out.',
    //   position: 'top'
    // });
    this.props.logout();
  }

  render() {
    return (
      <HomePage
        navigation={this.props.navigation}
        operators={this.props.operators}
        fleets={this.props.fleets}
        favorites={this.props.favorites}
        maintenances={this.props.maintenances}
        logout={this.logout.bind(this)}
      />
    );
  }
}

function bindAction(dispatch) {
  return {
    fetchFleets: operatorId => dispatch(fleetActions.fetchFleets(operatorId)),
    fetchOperators: () => dispatch(operatorActions.fetchOperators()),
    fetchFavorites: type => dispatch(favoriteActions.fetchFavorites(type)),
    fetchMaintenances: () => dispatch(maintenanceActions.fetchProjectedMaintenances()),
    logout: () => dispatch(authActions.logout()),
    loadSettings: () => dispatch(userActions.loadSettings())
  };
}

const mapStateToProps = state => ({
  distanceUnit: state.userSettingsReducer.distanceUnit,
  fleets: state.fleetReducer.fleets,
  operators: state.operatorReducer.operators,
  favorites: state.favoriteReducer,
  authenticated: state.authReducer.authenticated,
  maintenances: state.maintenanceReducer
});
export default connect(
  mapStateToProps,
  bindAction
)(HomePageContainer);
