export const errors = {
  ERROR_FREE: 'railway/error/ERROR_FREE',
  UNKNOWN_ERROR: 'railway/error/UNKNOWN_ERROR'
};

const initialState = {
  errorCode: null,
  message: ''
};

export default function error(state = initialState, action = {}) {
  switch (action.type) {
    case errors.ERROR_FREE:
      return initialState;
    case errors.UNKNOWN_ERROR:
      return { ...state, errorCode: action.type, message: action.message };
    default:
      return state;
  }
}

export function errorMessage(message = 'unknown error', type = errors.UNKNOWN_ERROR) {
  return {
    type,
    message: `${message} \n\nPress OK to continue.`
  };
}

export function clearError() {
  return { type: errors.ERROR_FREE };
}
