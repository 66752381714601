const FETCH_DOCUMENTS = 'railway/document/FETCH_DOCUMENTS';
const DELETE_DOCUMENT = 'railway/document/DELETE_DOCUMENT';
const SAVE_DOCUMENT = 'railway/document/SAVE_DOCUMENT';
const ADD_DOCUMENT = 'railway/document/ADD_DOCUMENT';
const GET_SIGNED_UPLOAD_URL = 'railway/document/GET_SIGNED_UPLOAD_URL';
const RESET_UPLOAD = 'railway/document/RESET_UPLOAD';

const empty = { url: '', filename: '' };
const initialState = {
  documents: [],
  loading: false,
  loaded: false,
  uploading: false,
  uploaded: false,
  signedUrl: empty
};

export default function document(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_DOCUMENT:
      return {
        ...state,
        uploading: true,
        uploaded: false
      };
    case `${ADD_DOCUMENT}_SUCCESS`:
      return {
        ...state,
        uploading: false,
        uploaded: true,
        signedUrl: empty
      };
    case `${ADD_DOCUMENT}_FAIL`:
      return {
        ...state,
        uploading: false,
        uploaded: false,
        signedUrl: empty
      };

    case FETCH_DOCUMENTS:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case `${FETCH_DOCUMENTS}_SUCCESS`:
      return {
        ...state,
        loading: false,
        loaded: true,
        documents: action.result
      };
    case `${FETCH_DOCUMENTS}_FAIL`:
      return initialState;

    case DELETE_DOCUMENT:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case `${DELETE_DOCUMENT}_SUCCESS`:
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case `${DELETE_DOCUMENT}_FAIL`:
      return {
        ...state,
        loading: false,
        loaded: false
      };

    case SAVE_DOCUMENT:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case `${SAVE_DOCUMENT}_SUCCESS`:
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case `${SAVE_DOCUMENT}_FAIL`:
      return {
        ...state,
        loading: false,
        loaded: false
      };

    case GET_SIGNED_UPLOAD_URL:
      return {
        ...state,
        signedUrl: empty
      };
    case `${GET_SIGNED_UPLOAD_URL}_SUCCESS`:
      return {
        ...state,
        signedUrl: action.result
      };
    case `${GET_SIGNED_UPLOAD_URL}_FAIL`:
      return {
        ...state,
        signedUrl: empty
      };

    case RESET_UPLOAD:
      return {
        ...state,
        uploaded: false,
        uploading: false
      };

    default:
      return state;
  }
}

/**
 * Fetch all the documents associated with a fleet.
 *
 * @param {String} fleetId
 */
export function fetchDocuments(fleetId) {
  console.log(`Fetch documents for ${fleetId}`);
  return {
    types: [FETCH_DOCUMENTS, `${FETCH_DOCUMENTS}_SUCCESS`, `${FETCH_DOCUMENTS}_FAIL`],
    promise: client => {
      return client
        .get(`/documents/for-fleet/${fleetId}`)
        .catch(res => console.warn('Error fetching documents:\n', res));
    }
  };
}

/**
 * Delete a document with a specific id.
 *
 * @param {String} documentId
 * @param {String} fleetId
 */
export function deleteDocument(documentId, fleetId) {
  console.log(`Delete document ${documentId}`);
  return {
    types: [DELETE_DOCUMENT, `${DELETE_DOCUMENT}_SUCCESS`, `${DELETE_DOCUMENT}_FAIL`],
    promise: (client, dispatch) => {
      return client
        .del(`documents/${documentId}`)
        .then(() => dispatch(fetchDocuments(fleetId)))
        .catch(res => console.log('ERROR deleting document:\n', res));
    }
  };
}

/**
 * Save new data to an existing document.
 *
 * @param {String} fleetId
 * @param {String} documentId
 * @param {Object} data
 */
export function saveDocument(fleetId, documentId, data) {
  console.log(`Saving data to document ${documentId}`, data);
  return {
    types: [SAVE_DOCUMENT, `${SAVE_DOCUMENT}_SUCCESS`, `${SAVE_DOCUMENT}_FAIL`],
    promise: (client, dispatch) => {
      return client
        .patch(`documents/${documentId}`, { data })
        .then(() => dispatch(fetchDocuments(fleetId)))
        .catch(res => console.log('ERROR saving document:\n', res));
    }
  };
}

/**
 * Add new document with given data and upload the document to S3.
 *
 * @param {String} fleetId
 * @param {Object} data { title, description }
 * @param {Function} uploadCallback
 * @param {Bool} webui
 */
export function addDocument(fleetId, data, uploadCallback, webui) {
  console.log('Adding new document with data', data);
  return {
    types: [ADD_DOCUMENT, `${ADD_DOCUMENT}_SUCCESS`, `${ADD_DOCUMENT}_FAIL`],
    promise: (client, dispatch, getState) => {
      const { signedUrl, key } = getState().documentReducer.signedUrl;
      return client
        .put(`documents/for-fleet/${fleetId}`, { data: { ...data, filename: key } })
        .then(() => {
          if (webui) {
            uploadCallback({ signedUrl });
          } else {
            dispatch(uploadCallback());
          }
          return dispatch(fetchDocuments(fleetId));
        })
        .catch(res => console.warn('ERROR adding document:\n', res));
    }
  };
}

/**
 * Get a signed S3 upload URL and an S3 key for the document.
 *
 * @param {String} fleetId The fleet the document belongs to
 * @param {Function} callback The function which uploads the file to the received URL
 */
export function getSignedDocumentUploadURL(fleetId, file) {
  return {
    types: [GET_SIGNED_UPLOAD_URL, `${GET_SIGNED_UPLOAD_URL}_SUCCESS`, `${GET_SIGNED_UPLOAD_URL}_FAIL`],
    promise: client => {
      return client
        .get(`documents/for-upload/${fleetId}/?filename=${file.name}&contenttype=${encodeURIComponent(file.type)}`)
        .catch(res => console.warn('ERROR getting signed upload URL for document:\n', res));
    }
  };
}

/**
 * Reset upload status after succesful upload.
 *
 */
export function resetUploaded() {
  return {
    types: [RESET_UPLOAD, '', ''],
    promise: () => Promise.resolve()
  };
}

// /**
//  * Upload a file from iOS.
//  *
//  * @param {String} uri The URI of the uploaded file
//  */
// export function iOSUpload(uri) {
//   return {
//     types: [UPLOAD_IOS, `${UPLOAD_IOS}_SUCCESS`, `${UPLOAD_IOS}_FAIL`],
//     promise: (client, dispatch, getState) => {
//       console.log('iOS upload');
//       const { signedUrl } = getState().documentReducer.signedUrl;
//       return fetch(uri, {
//         mode: 'cors'
//       })
//         .then(response => response.blob())
//         .then(body =>
//           fetch(signedUrl, {
//             method: 'PUT',
//             headers: { 'Content-Type': 'application/octet-stream' },
//             body
//           }))
//         .then(response => {
//           console.log(response);

//           if (response.status !== 200) {
//             console.warn(response);
//             return Promise.reject(new Error(response));
//           }
//           return Promise.resolve();
//         })
//         .catch(e => console.log(e));
//     }
//   };
// }
