import React, { PureComponent } from 'react';
import { Icon } from 'dl-web-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import TCell from 'components/TCell';
import TRow from 'components/TRow';
import AddBearingForm from 'container/AddBearingForm';
import * as productActions from 'redux/modules/product';
import * as errorActions from 'redux/modules/error';

const TrainBogieWheelsetItem = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
`;

const Left = styled.div``;
const Right = styled.div``;


class TrainBogieItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      disabled: false,
      confirmDialogVisible: false,
      callback: null
    };
  }

  handleButtonClick(state) {
    this.setState({ ...state });
    if (window.confirm(state.confirmMessage)) {
      state.callback();
    }
  }

  removeBearing(items, bogieClass) {
    if (this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
    console.log(
      items.bearings,
      this.props.fleet.id,
      'axles',
      items.position,
      bogieClass.toUpperCase()
    );
    this.props.removeBearing(
      items.bearings,
      this.props.fleet.id,
      'axles',
      items.position,
      bogieClass.toUpperCase()
    );
  }

  renderBearingName(bearing, position) {
    if (this.state.editing) {
      return (
        <div key={bearing.classId}>
          <p style={{ lineHeight: 50 }}>
            <Icon
              name="trash"
              style={{ color: this.state.disabled ? 'gray' : '#007AFF', fontSize: 28 }}
              onClick={() =>
                this.handleButtonClick({
                  confirmDialogVisible: true,
                  confirmMessage: 'Are you sure you wish to remove this bearing?',
                  actionMessage: 'Removing bearing, please wait...',
                  label: 'Remove',
                  callback: () => this.removeBearing(bearing, position)
                })
              }
            />
            &nbsp;&nbsp;
            {bearing.name || 'Unknown'}
          </p>
        </div>
      );
    }

    return (
      <p>
        {bearing.amount} x {}
        <Link
          style={bearing.classId ? { color: '#007AFF', cursor: 'pointer' } : {}}
          to={`/products/${bearing.classId}`}
        >
          {bearing.name}
        </Link>
      </p>
    );
  }

  renderBearingInformation(bearings) {
    if (!bearings) {
      return null;
    }
    const note = text => (
      <p note style={{ alignSelf: 'flex-start' }}>
        {text}
      </p>
    );
    return (
      <TRow>
        <TCell multiline>
          {note('Bearing position')}
          <p>{bearings.position}</p>
        </TCell>
        <TCell multiline>
          {note('Bearings / bogie')}
          <p>{bearings.perAxle * bearings.numAxles}</p>
        </TCell>
        <TCell multiline>
          {note('Bearings / axle')}
          <p>{bearings.perAxle}</p>
        </TCell>
      </TRow>
    );
  }

  render() {
    const { type, bogies } = this.props;

    if (type !== 'axles') {
      return <p>Unsupported type: {type}</p>;
    }

    console.log('bogies', bogies);
    const bearingNames = [];
    const bearingsGrouped = bogies.reduce((grouped, bogie) => {
      const bogieType = bogie.bogieClass ? bogie.bogieClass.type : null;
      const bearings = bogie[type];

      for (let i = 0; i < bearings.length; i++) {
        const bearing = bearings[i].Bearings;
        for (let j = 0; j < bearing.length; j++) {
          const bearingPosition = bearing[j].position;
          const bearingName = bearing[j].bearingClass ? bearing[j].bearingClass.name : 'Unknown';
          const bearingClassId = bearing[j].bearingClass ? bearing[j].bearingClass.id : null;

          if (!grouped[bogieType]) {
            grouped[bogieType] = [];
            bearingNames[bogieType] = [];
          }
          if (!grouped[bogieType][bearingName]) {
            bearingNames[bogieType].push(bearingName);
            grouped[bogieType][bearingName] = {
              name: bearingName,
              classId: bearingClassId,
              position: bearingPosition,
              numAxles: bogie.bogieClass ? bogie.bogieClass.numAxles : null,
              numBogies: bogies.filter(b => b.bogieClass.type === bogieType).length,
              perAxle: bearing.filter(b => {
                return b.bearingClass
                  ? b.bearingClass.id === bearing[j].bearingClass.id
                  : bearing[j].bearingClass === null;
              }).length,
              amount: 0,
              bearings: []
            };
          }
          grouped[bogieType][bearingName].amount++;
          grouped[bogieType][bearingName].bearings.push(bearing[j].id);
        }
      }

      return grouped;
    }, {});
    console.log('bearingsGrouped', bearingsGrouped);
    console.log('bearingNames', bearingNames);

    return (
      <TrainBogieWheelsetItem
        style={{
          marginLeft: 0
        }}
      >
        <div style={{ width: '100%' }}>
          <div style={{ flexDirection: 'row' }}>
            <Left>
              <p style={{ alignSelf: 'flex-start', fontWeight: 'bold', color: '#292929' }}>
                Wheelset
                {'\n'}
              </p>
            </Left>
            <Right>
              <p
                style={{ color: '#007AFF', cursor: 'pointer', paddingLeft: 10 }}
                onClick={() => this.setState({ editing: !this.state.editing })}
              >
                {/* <Icon active name="settings" style={{ color: '#007AFF', cursor: 'pointer', fontSize: 20 }} /> */}
                {this.state.editing ? 'Cancel' : 'Edit'}
                {'\n'}
              </p>
            </Right>
          </div>

          {!bearingNames[this.props.bogieType.toUpperCase()] ? (
            <React.Fragment>
              <TRow>
                <TCell multiline>
                  <React.Fragment>
                    <p>No wheelset bearings</p>
                  </React.Fragment>
                </TCell>
              </TRow>
            </React.Fragment>
          ) : (
              bearingNames[this.props.bogieType.toUpperCase()].map(name => {
                console.log('bearingsGrouped', bearingsGrouped);
                const groupedItem = bearingsGrouped[this.props.bogieType.toUpperCase()][name];
                return (
                  <React.Fragment key={name}>
                    <TRow>
                      <TCell multiline>
                        {!!groupedItem && (
                          <React.Fragment>
                            <p note style={{ alignSelf: 'flex-start' }}>
                              Bearings
                            <p note style={{ fontWeight: 'bold' }}>
                                {' '}
                                ({this.props.bogieType.toLowerCase()})
                            </p>
                            </p>
                            {this.renderBearingName(groupedItem, this.props.bogieType)}
                          </React.Fragment>
                        )}
                      </TCell>
                    </TRow>
                    {this.renderBearingInformation(groupedItem)}
                  </React.Fragment>
                );
              })
            )}

          {this.state.editing && (
            <AddBearingForm
              components={bogies}
              fleet={this.props.fleet}
              type="wheelset"
              bogieType={this.props.bogieType.toUpperCase()}
            />
          )}
        </div>
      </TrainBogieWheelsetItem>
    );
  }
}

TrainBogieItem.propTypes = {
  bogieType: PropTypes.string,
  removeBearing: PropTypes.func,
  type: PropTypes.string,
  bogies: PropTypes.array,
  err: PropTypes.object,
  clearError: PropTypes.func
};

function mapStateToProps(state) {
  return { err: state.errorReducer };
}

function mapDispatch(dispatch) {
  return {
    removeBearing: (item, fleetId, type, position, bogieClass) =>
      dispatch(productActions.removeBearing(item, fleetId, type, position, bogieClass)),
    clearError: () => dispatch(errorActions.clearError())
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(TrainBogieItem);
