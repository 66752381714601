import React, { PureComponent } from 'react';
import { Loader as Spinner } from 'dl-web-components';
import styled from 'styled-components';
import SidebarListItem from 'components/SidebarListItem';
import Sidebar from 'components/Sidebar';
import MaintenanceSmallBox from 'components/MaintenanceSmallBox';
import TCell from 'components/TCell';
import TRow from 'components/TRow';
import { exists } from 'helpers/validation';
import FavoriteIcon from 'components/FavoriteIcon';
import Menu from 'container/Menu';

import styles from './styles';

const Content = styled.div``;
const Container = styled.div``;
const Header = styled.div``;
const Body = styled.div``;
const Right = styled.div``;
const List = styled.div``;
const Title = styled.h2``;

const StyledContent = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
`;

const ContentContainer = styled.div`
  flex: 1;
`;

const cutValue = 3;

class OperatorOverviewPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      favorite: props.favorite,
      scheduledCut: cutValue,
      predictedCut: cutValue
    };
  }

  render() {
    const { loaded, fleets, scheduledMaintenances, predictedMaintenances, operator } = this.props;
    console.log(operator);

    const isFavorite = exists(this.state.favorite) && !!this.state.favorite;

    const ViewContent =
      !loaded || !operator || !fleets ? (
        <Content>
          <Spinner style={{ width: '100%', height: 200, justifyContent: 'flex-end', alignItems: 'center' }} />
        </Content>
      ) :
        (
          <ContentContainer>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <div style={{ flexDirection: 'row' }}>
                <FavoriteIcon
                  favorite={isFavorite}
                  onClick={() => {
                    this.setState({ favorite: !this.props.favorite });
                    this.props.toggleFavorite(operator.id, this.props.favorite);
                  }}
                />
              </div>
            </div>
            <div>
              <p style={{ fontSize: 20, marginBottom: 10 }}>Maintenances</p>
              <TRow style={{ marginBottom: 10 }}>
                <TCell>
                  <p>Scheduled</p>
                </TCell>
                <TCell>
                  {scheduledMaintenances.length > cutValue && (
                    <p
                      onClick={() => {
                        this.setState({ scheduledCut: this.state.scheduledCut !== Infinity ? Infinity : cutValue });
                      }}
                    >
                      {this.state.scheduledCut !== Infinity
                        ? `Show all (${scheduledMaintenances.length})`
                        : 'Hide excess'}
                    </p>
                  )}
                </TCell>
              </TRow>
              <List>
                {scheduledMaintenances.slice(0, this.state.scheduledCut).map(item => (
                  <MaintenanceSmallBox key={item.fleet.id} maintenance={item} predicted={false} />
                ))}
                {!scheduledMaintenances.length && (
                  <p note>
                    No scheduled maintenances
                    {'\n'}
                  </p>
                )}
              </List>
            </div>
            <div>
              <TRow style={{ marginBottom: 10 }}>
                <TCell>
                  <p>Estimated</p>
                </TCell>
                <TCell>
                  {predictedMaintenances.length > cutValue && (
                    <p
                      onClick={() => {
                        this.setState({ predictedCut: this.state.predictedCut !== Infinity ? Infinity : cutValue });
                      }}
                    >
                      {this.state.predictedCut !== Infinity
                        ? `Show all (${predictedMaintenances.length})`
                        : 'Hide excess'}
                    </p>
                  )}
                </TCell>
              </TRow>
              <List>
                {predictedMaintenances.slice(0, this.state.predictedCut).map(item => (
                  <MaintenanceSmallBox key={item.fleet.id} maintenance={item} predicted={true} />
                ))}
                {!predictedMaintenances.length && (
                  <p note>
                    No estimated maintenances
                    {'\n'}
                  </p>
                )}
              </List>
            </div>
          </ContentContainer>
        );

    return (
      <Container style={styles.container}>
        <Menu />
        <Header>


          <Body style={{ flex: 3, alignItems: 'center' }}>
            <Title>{operator && operator.name}</Title>
          </Body>

          <Right />
        </Header>

        <StyledContent>
          <Sidebar>
            <div style={{ height: '100%' }}>
              <List>
                <SidebarListItem>
                  <p style={{ color: '#007AFF' }}>Overview</p>
                </SidebarListItem>
                <SidebarListItem onClick={() => this.props.switchPage('fleets')}>
                  <p>Fleets</p>
                </SidebarListItem>
              </List>

              <List style={{ marginBottom: 0 }}>
                <SidebarListItem itemDivider>
                  <p>Favorite fleets</p>
                </SidebarListItem>
                {this.props.favoriteFleets.map(item => (
                  // TODO: check operatorId coming from backend
                  <SidebarListItem key={item.id} to={`/operators/${item.fleet.operatorId}/fleets/${item.fleet.id}`} >
                    <p >
                      {item.fleet.name}
                    </p>
                  </SidebarListItem>
                ))}
                {this.props.favoriteFleets.length === 0 && (
                  <SidebarListItem>
                    <p>No favorites</p>
                  </SidebarListItem>
                )}
              </List>
            </div>
          </Sidebar>
          {ViewContent}
        </StyledContent>
      </Container>
    );
  }
}

export default OperatorOverviewPage;
