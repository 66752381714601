import * as React from 'react';
import styled from 'styled-components';
import { Button, Loader as Spinner } from 'dl-web-components';

const Container = styled.div``;
const Footer = styled.div``;
const Title = styled.h2``;

class ModalForm extends React.PureComponent {
  render() {
    return (
      <Container>
        <Title>{this.props.title}</Title>
        <div style={{ flex: 1 }}>{this.props.form}</div>
        <Footer>
          <div style={{ width: '100%', padding: 5 }}>
            {this.props.loading ? (
              <div>
                <Spinner style={{ width: '100%', height: 20, alignItems: 'center' }} />
              </div>
            )
              :
              (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Button
                    style={{
                      flex: 1,
                      backgroundColor: 'rgb(134,141,150)',
                      justifyContent: 'center',
                      marginLeft: 20,
                      marginRight: 10
                    }}
                    onClick={() => this.props.onCancel()}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ flex: 1, justifyContent: 'center', marginLeft: 10, marginRight: 20 }}
                    disabled={this.props.disabled}
                    onClick={() => this.props.onSave()}
                  >
                    Save
                  </Button>
                </div>
              )}
          </div>
        </Footer>
      </Container>
    );
  }
}

export default ModalForm;
